import type { BaseSyntheticEvent } from 'react'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

// Components
import { ScrollRoot, ScrollViewport } from '@/components/base/scroll-area'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/base/form'
import { Button } from '@/components/base/button'
import { Icon } from '@/components/common/icons/Icon'
import { Timestamp } from '@/components/Timestamp'
import { TextareaResizable } from '@/components/TextareaResizable'
import { LoadingWrapper } from '@/components/LoadingWrapper'

// Stores
import { useChatStore, useChatStoreActions } from '@/stores/chat'
import { useMainStore } from '@/stores/main'

// Helpers
import type { ChatMessage, LoadingState } from '@/helpers/types'
import {
  LOADING_STATE,
  MIN_LOADING_INTERVAL_MS,
  TEST_IDS,
  TIMESTAMP_MULTIPLIER,
} from '@/helpers/constants'
import { cn, debounceWithCancel, promisesWithMinDelay } from '@/helpers/utils'

// Hooks
import { useIsVisible } from '@/hooks/useIsVisible'
import { useRoomStore } from '@/stores/room'
import { useLastScrollPosition } from '@/hooks/useLastScrollPosition'
import { useScrollToBottom } from '@/hooks/useScrollToBottom'

const TIMER_DEBOUNCE_MS = 100 as const
const VISIBILITY_THRESHOLD = 1 as const // 1 or 100% of the element must be visible

export const PanelRoomChat = () => {
  const [getChatMessagesStatus, setGetChatMessagesStatus] =
    useState<LoadingState>(LOADING_STATE.IDLE)
  const [paginationStatus, setPaginationStatus] = useState<LoadingState>(
    LOADING_STATE.IDLE,
  )

  const addressId = useRoomStore(state => state.addressId)
  const addressIdChatIdMap = useChatStore(state => state.addressIdChatIdMap)
  const chatHasNextPage = useChatStore(state => state.chatHasNextPage)
  const chatMessageMap = useChatStore(state => state.chatMessageMap)

  // Lazy loader observer
  const lazyLoaderObserverRef = useRef<HTMLLIElement | null>(null)
  const scrollViewportRef = useRef<HTMLDivElement>(null)
  const isLazyLoaderVisible = useIsVisible(lazyLoaderObserverRef, {
    root: scrollViewportRef.current,
    threshold: VISIBILITY_THRESHOLD,
  })

  const { getChatMessages, getSortedChatMessages, getNextChatPage } =
    useChatStoreActions()

  // FIXME: this should never happen, but we should handle it properly
  if (!addressId) {
    throw new Error('Address ID is required')
  }

  // TODO: might be better suited as getter on the chat store?
  const hasFetchedInitialMessages = addressIdChatIdMap.has(addressId)

  const sortedMessages = getSortedChatMessages({
    addressId,
    chatMessageMap,
  })

  useEffect(() => {
    // load the next page of messages when the lazy loader is visible
    if (!isLazyLoaderVisible || !chatHasNextPage) {
      return
    }

    const debouncedGetNextChatPage = debounceWithCancel(() => {
      setPaginationStatus(LOADING_STATE.LOADING)
      // this creates a minimum loading interval to avoid flickering of loading state
      promisesWithMinDelay(
        [getNextChatPage({ addressId: addressId })],
        MIN_LOADING_INTERVAL_MS,
      )
        .then(() => setPaginationStatus(LOADING_STATE.IDLE))
        .catch(error => {
          console.error('Error fetching next chat page:', error)
          setPaginationStatus(LOADING_STATE.ERROR)
        })
    }, TIMER_DEBOUNCE_MS)

    const cancelGetChatMessages = debouncedGetNextChatPage()

    return () => {
      // TODO: ideally, cancel the fetch if the component is unmounted
      cancelGetChatMessages()
    }
  }, [chatHasNextPage, getNextChatPage, isLazyLoaderVisible, addressId])

  useEffect(() => {
    // `getChatMessages` has already been called for this address
    if (hasFetchedInitialMessages) {
      return
    }

    const debouncedGetChatMessages = debounceWithCancel(() => {
      // fetch initial chat messages if they are not already loaded
      setGetChatMessagesStatus(LOADING_STATE.LOADING)
      getChatMessages({ addressId: addressId })
        .then(() => {
          setGetChatMessagesStatus(LOADING_STATE.IDLE)
        })
        .catch(error => {
          setGetChatMessagesStatus(LOADING_STATE.ERROR)
          console.error('Error fetching chat messages:', error)
        })
    }, TIMER_DEBOUNCE_MS)

    const cancelGetChatMessages = debouncedGetChatMessages()

    return () => {
      cancelGetChatMessages()
    }
  }, [addressId, getChatMessages, hasFetchedInitialMessages])

  return (
    <PanelChatView
      addressId={addressId}
      lazyLoaderObserverRef={lazyLoaderObserverRef}
      loading={getChatMessagesStatus === LOADING_STATE.LOADING}
      paginationStatus={paginationStatus}
      scrollViewportRef={scrollViewportRef}
      sortedMessages={sortedMessages}
    />
  )
}

interface PanelChatViewProps {
  addressId: string
  lazyLoaderObserverRef: React.RefObject<HTMLLIElement>
  loading: boolean
  paginationStatus: LoadingState
  scrollViewportRef: React.RefObject<HTMLDivElement>
  sortedMessages: ChatMessage[]
}

// For textarea resizing and new line handling
const MAX_MESSAGE_ROWS = 10

type FormSchema = z.infer<typeof formSchema>
const formSchema = z.object({
  newMessage: z.string(),
})

const PanelChatView = ({
  addressId,
  lazyLoaderObserverRef,
  loading,
  paginationStatus,
  scrollViewportRef,
  sortedMessages,
}: PanelChatViewProps) => {
  const [messages, setMessages] = useState<ChatMessage[]>(sortedMessages)

  const currentUserId = useMainStore(state => state.memberId)

  const scrollViewportElement = scrollViewportRef.current
  const { forceScrollToBottom, setForceScrollToBottom } = useScrollToBottom({
    scrollContainerElement: scrollViewportElement,
  })

  useLastScrollPosition({
    scrollContainerElement: scrollViewportElement,
    shouldRun: !forceScrollToBottom,
    updateDependency: messages.length,
  })

  const { sendChatMessage } = useChatStoreActions()

  // sync messages with sorted messages when they change
  useEffect(() => {
    setMessages(sortedMessages)
  }, [sortedMessages])

  // Form handling
  const formRef = useRef<HTMLFormElement>(null)
  const form = useForm<FormSchema>({
    defaultValues: {
      newMessage: '',
    },
    resolver: zodResolver(formSchema),
  })

  const handleSendMessage = (data: { newMessage: string }) => {
    const trimmedInput = data.newMessage.trim()
    if (!trimmedInput) {
      // TODO: show an error message?
      return
    }

    // Add the submitted message to the list to display it immediately
    const newMessages = [
      ...messages,
      {
        id: Date.now().toString(),
        text: trimmedInput,
        ts: 0,
        userId: currentUserId,
      },
    ]
    setMessages(newMessages)

    // scroll to the bottom of the chat window
    setForceScrollToBottom(true)

    // FIXME should form only clear if the message was sent successfully?
    sendChatMessage({
      addressId: addressId,
      metadata: {
        subtype: 'chat',
      },
      text: trimmedInput,
    })
      .then(result => console.log('Sent chat message:', result))
      // FIXME: if the message fails to send, should we remove it from the list, show error, etc?
      .catch(error => console.error('Error sending chat message:', error))

    // clear the form input
    form.reset()
  }

  const handleSubmitForm = (event?: BaseSyntheticEvent) => {
    void form.handleSubmit(handleSendMessage)(event)
  }

  const handleTextAreaChange = (value: string) => {
    form.setValue('newMessage', value)
  }

  return (
    <div
      className={cn(
        'flex h-full w-full grow flex-col justify-between overflow-y-hidden',
      )}
      data-testid={TEST_IDS.ROOM_CHAT_PANEL}
    >
      <LoadingWrapper
        classesParent="flex min-h-20 grow flex-col justify-end"
        loading={loading}
        loadingLabel="Loading..."
      />
      <ScrollRoot
        className={cn(
          'm-0 ml-3 mr-0 grow overflow-x-hidden p-2 pr-5 text-sm text-foreground',
        )}
      >
        <ScrollViewport ref={scrollViewportRef}>
          <div className="w-full">
            {/* TODO: so end of pagination (end of history) indicator? */}
            {/* pagination indicator  */}
            <LoadingWrapper
              className="aspect-auto"
              classesParent="min-h-min"
              loading={paginationStatus === LOADING_STATE.LOADING}
              loadingLabel={
                <p className="pr-6 text-xs">Loading older messages...</p>
              }
              orientation="horizontal"
              size="xs"
            />
            <ul aria-live="polite">
              {messages.map((message, index) => (
                <PanelChatMessage
                  key={message.id}
                  testId={index === 0 ? TEST_IDS.ROOM_CHAT_SENTINEL : null}
                  ref={index === 0 ? lazyLoaderObserverRef : null}
                  id={message.id}
                  text={message.text}
                  ts={message.ts}
                  userId={message.userId}
                />
              ))}
            </ul>
          </div>
        </ScrollViewport>
      </ScrollRoot>
      <Form {...form}>
        <form
          aria-label="Chat message form"
          className="max-h-1/2 mb-1 mt-0 w-full shrink pr-3"
          onSubmit={handleSubmitForm}
          ref={formRef}
        >
          <FormField
            control={form.control}
            name="newMessage"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel className="sr-only">New Message</FormLabel>
                <div className="relative flex w-full items-center overflow-x-visible">
                  <FormControl>
                    <TextareaResizable
                      aria-multiline="true"
                      className="w-full py-2 pl-4 pr-10"
                      maxRows={MAX_MESSAGE_ROWS}
                      placeholder="Message"
                      rows={1}
                      tabIndex={-1}
                      onEnter={handleSubmitForm}
                      onValueChange={handleTextAreaChange}
                      {...field}
                    />
                  </FormControl>
                  <Button
                    className="-ml-11 h-8 rounded-[1.75rem] p-1.5 text-input transition-colors hover:text-primary group-focus-within/search:text-primary"
                    // TODO: disabled state when form is submitting or invalid? (prevent spamming)
                    type="submit"
                    variant="icon"
                  >
                    <Icon tag="send" size="lg" variant="foreground" />
                    <span className="sr-only">Send</span>
                  </Button>
                </div>
                <FormDescription className="sr-only">
                  Enter a chat message and hit the Enter key to send it.
                </FormDescription>
                {/* NOTE: if error messages are needed use <FormMessage  /> */}
              </FormItem>
            )}
          />
        </form>
      </Form>
    </div>
  )
}

interface PanelChatMessageProps {
  id: ChatMessage['id']
  testId?: string | null
  text: ChatMessage['text']
  ts: ChatMessage['ts']
  userId: ChatMessage['userId']
}

const PanelChatMessage = forwardRef<HTMLLIElement, PanelChatMessageProps>(
  ({ id, text, ts, userId, testId }, ref) => {
    const userMetaDataMap = useChatStore(state => state.userMetaDataMap)
    const currentUserId = useMainStore(state => state.memberId)

    if (userId === currentUserId) {
      // Current user
      const currentUser = userMetaDataMap.get(currentUserId)
      return (
        <li
          key={id}
          className="flex w-full max-w-full items-center justify-end justify-items-end space-x-4 pb-4 pl-2"
          data-testid={testId}
          ref={ref}
        >
          <div className="w-full overflow-x-hidden">
            <p className="inline whitespace-pre-line break-words">{text}</p>
          </div>
          <div className="h-10 w-10 shrink-0 text-primary-light">
            {/* TODO: use the user metadata or just hardcode the bg color? */}
            <div
              className={cn(
                'flex h-[38px] w-[38px] items-center justify-center rounded-full border border-deep-sea',
                currentUser?.color,
              )}
            >
              {currentUser?.initial}
            </div>
          </div>
        </li>
      )
    } else {
      // Other participants in the chat
      const user = userMetaDataMap.get(userId)
      return (
        <li
          key={id}
          className="flex w-full max-w-full items-start justify-start space-x-4 pb-4"
          data-testid={testId}
          ref={ref}
        >
          <div className="h-10 w-10 shrink-0 text-primary-light">
            <div
              className={cn(
                'flex h-10 w-10 items-center justify-center rounded-full text-background',
                user?.color,
              )}
            >
              {user?.initial}
            </div>
          </div>
          <div className="w-full overflow-x-hidden">
            <div className="flex w-full items-end space-x-2 text-xs text-muted">
              <div className="truncate">{user?.name}</div>
              <div className="shrink-0 whitespace-nowrap">
                [
                <Timestamp timestamp={ts * TIMESTAMP_MULTIPLIER} />]
              </div>
            </div>
            <div className="whitespace-pre-line break-words">{text}</div>
          </div>
        </li>
      )
    }
  },
)
PanelChatMessage.displayName = 'PanelChatMessage'
