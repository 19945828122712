import { Suspense } from 'react'
import { PageTitle } from '@/components/PageTitle'
import { TanStackRouterDevtools } from '@/components/TanStackRouterDevTools.lazy'
import { Outlet, createRootRouteWithContext } from '@tanstack/react-router'

export const Route = createRootRouteWithContext<{
  title: string
}>()({
  component: () => (
    <>
      <PageTitle />
      <Outlet />
      <Suspense>
        <TanStackRouterDevtools />
      </Suspense>
    </>
  ),
  // wrapInSuspense is required for root routes
  wrapInSuspense: true,
})
