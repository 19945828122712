import { isString, typeSafeFilterObject } from '@/helpers/utils'
import { create, type StateCreator } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

const isMediaConstraint = (obj: unknown): obj is MediaTrackConstraints =>
  !!obj && !!(obj as { deviceId: string }).deviceId
interface Actions {
  actions: {
    getPreferredMicrophoneDeviceForDialing: (
      dialingTo?: string,
    ) => MediaTrackConstraints | boolean | undefined
    getPreferredMicrophoneDeviceId: () => string | undefined
    getPreferredSpeakerDeviceId: () => string | undefined
    getPreferredVideoDeviceForDialing: (
      dialingTo?: string,
    ) => MediaTrackConstraints | boolean | undefined
    getPreferredVideoDeviceId: () => string | undefined
    setPreferredMicrophoneDevice: (idConstraints: MediaTrackConstraints) => void
    setPreferredSpeakerDevice: (idConstraints: MediaTrackConstraints) => void
    setPreferredVideoDevice: (idConstraints: MediaTrackConstraints) => void
  }
}

interface State {
  microphoneConstraints: MediaTrackConstraints | boolean
  speakerConstraints: MediaTrackConstraints | boolean
  videoConstraints: MediaTrackConstraints | boolean
}

type Store = Actions & State

const initialState: State = {
  microphoneConstraints: true,
  speakerConstraints: false,
  videoConstraints: true,
}

const stateCreatorFn: StateCreator<Store> = (set, get) => ({
  ...initialState,
  actions: {
    // the unused param exist more for symmetry with the getPreferredVideoDeviceForDialing,
    // and the chance the could be useful in the future
    getPreferredMicrophoneDeviceForDialing: (_dialingTo?: string) =>
      get().microphoneConstraints,
    getPreferredMicrophoneDeviceId: () => {
      const { microphoneConstraints } = get()
      return isMediaConstraint(microphoneConstraints) &&
        isString(microphoneConstraints.deviceId)
        ? microphoneConstraints.deviceId
        : undefined
    },
    getPreferredSpeakerDeviceId: () => {
      const { speakerConstraints } = get()
      return isMediaConstraint(speakerConstraints) &&
        isString(speakerConstraints.deviceId)
        ? speakerConstraints.deviceId
        : undefined
    },
    getPreferredVideoDeviceForDialing: (dialingTo?: string) =>
      !dialingTo?.includes('channel=audio')
        ? get().videoConstraints
        : undefined,
    getPreferredVideoDeviceId: () => {
      const { videoConstraints } = get()
      return isMediaConstraint(videoConstraints) &&
        isString(videoConstraints.deviceId)
        ? videoConstraints.deviceId
        : undefined
    },
    setPreferredMicrophoneDevice: (idConstraints: MediaTrackConstraints) => {
      set({ microphoneConstraints: idConstraints })
    },
    setPreferredSpeakerDevice: (idConstraints: MediaTrackConstraints) => {
      set({ speakerConstraints: idConstraints })
    },
    setPreferredVideoDevice: (idConstraints: MediaTrackConstraints) => {
      set({ videoConstraints: idConstraints })
    },
  },
})

const partialize = (state: State) => {
  type KeysToPersist = (typeof persistKeys)[number]
  const persistKeys = [
    'videoConstraints',
    'microphoneConstraints',
    'speakerConstraints',
  ] as const

  return typeSafeFilterObject({ ...state }, key =>
    persistKeys.includes(key as KeysToPersist),
  ) as Store
}

export const useUserSettingsStore = create<Store>()(
  persist<Store>(stateCreatorFn, {
    name: 'sw_puc_user_settings',
    partialize: state => partialize(state),
    storage: createJSONStorage(() => localStorage),
  }),
)

export const useUserSettingsStoreActions = () =>
  useUserSettingsStore.getState().actions
// Expose the store to be used from the console
window.__userSettingsStore = useUserSettingsStore
