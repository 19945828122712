import { useCallback, useState } from 'react'

export type ClearValue = () => void
type CopiedValue = string
export type CopyToClipboard = (text: string) => Promise<boolean>

interface UseClipboardReturn {
  clearValue: ClearValue
  copiedValue: CopiedValue
  copyToClipboard: CopyToClipboard
}

/**
 * copyToClipboard hook with progressive enhancement using the Clipboard API
 * - Fallback for older browsers - https://web.dev/patterns/clipboard/copy-text
 */
export const useClipboard = (): UseClipboardReturn => {
  const [copiedValue, setCopiedValue] = useState<CopiedValue>('')

  const clearValue = useCallback(() => {
    setCopiedValue('')
  }, [])

  const copyToClipboard = useCallback(
    async (text: string): Promise<boolean> => {
      let textArea: HTMLTextAreaElement | undefined = undefined
      try {
        // Use the Clipboard API if available
        if (navigator.clipboard?.writeText !== undefined) {
          await navigator.clipboard.writeText(text)
          setCopiedValue(text)
          return true
        } else {
          textArea = document.createElement('textarea')
          textArea.value = text
          textArea.style.opacity = '0'
          document.body.appendChild(textArea)
          textArea.focus()
          textArea.select()
          document.execCommand('copy')
          document.body.removeChild(textArea)
          setCopiedValue(text)
          return true
        }
      } catch (err) {
        console.error('Failed to copy text: ', err)
        clearValue()
        return false
      } finally {
        textArea?.remove()
      }
    },
    [clearValue],
  )

  return {
    clearValue,
    copiedValue,
    copyToClipboard,
  }
}
