import type { RefObject } from 'react'
import { useEffect } from 'react'

export const useClickOutside = <T extends Element>(
  ref: RefObject<T>,
  callback: (event: PointerEvent) => void,
) => {
  useEffect(() => {
    if (!ref?.current) {
      return
    }

    const root = ref.current?.getRootNode() as HTMLElement

    const handleClickOutside = (event: PointerEvent) => {
      const target = event.target
      if (target instanceof Node && !ref.current?.contains(target)) {
        callback(event)
      }
    }

    if (root) {
      root.addEventListener('pointerdown', handleClickOutside)
    }

    return () => {
      if (root) {
        root.removeEventListener('pointerdown', handleClickOutside)
      }
    }
  }, [ref, callback])
}
