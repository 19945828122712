// Store for conversations (Recent/History)
import { deattachSdkSessionState, typeSafeFilterObject } from '@/helpers/utils'
import { useMainStore } from '@/stores/main'
import type {
  CallFabricRoomSession,
  Fabric,
  VideoLayoutChangedEventParams,
  Video,
  VideoRoomSubscribedEventParams,
} from '@signalwire/js'
import { create, type StateCreator } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

interface EventsCallBacks {
  // FIXME export SDK type
  onDestroy?: (roomSession: Video.RoomSession) => void
  onJoined?: (event: VideoRoomSubscribedEventParams) => void
  onLayoutChanged?: (event: VideoLayoutChangedEventParams) => void
  onStart?: <TEvent>(event: TEvent) => void
}

interface Actions {
  actions: {
    _startSession: (
      roomSession: CallFabricRoomSession,
      incoming: boolean,
      eventsCallback?: EventsCallBacks,
    ) => Promise<void>
    join: (
      params: Fabric.DialParams,
      eventsCallback?: EventsCallBacks,
    ) => Promise<CallFabricRoomSession | undefined>
    leave: () => Promise<void>
    startSession: (
      roomSession: CallFabricRoomSession,
      eventsCallback?: EventsCallBacks,
    ) => Promise<void>
  }
}

interface State {
  attachedTo: string | undefined
  roomSession: CallFabricRoomSession | undefined
}

type Store = Actions & State

const initialState: State = {
  attachedTo: undefined,
  roomSession: undefined,
}

const stateCreatorFn: StateCreator<Store> = (set, get) => ({
  ...initialState,
  actions: {
    _startSession: async (
      roomSession: CallFabricRoomSession,
      incoming: boolean,
      eventsCallback?: EventsCallBacks,
    ) => {
      roomSession?.on('room.joined', event => {
        //@ts-expect-error FIXME we need to expose this on the SDK? 
        if (!incoming) set({ attachedTo: roomSession.options.destinationNumber as string })
        eventsCallback?.onJoined?.(event)
      })

      roomSession?.on('room.started', event => {
        eventsCallback?.onStart?.(event)
      })

      roomSession?.on('destroy', event => {
        eventsCallback?.onDestroy?.(event)
      })

      roomSession?.on('layout.changed', event => {
        eventsCallback?.onLayoutChanged?.(event)
      })

      if (!incoming) await roomSession?.start()
      set({ roomSession })

      return
    },
    join: async (
      params: Fabric.DialParams,
      eventsCallback?: EventsCallBacks,
    ) => {
      const { client } = useMainStore.getState()
      const { attachedTo } = get()
      const { _startSession } = get().actions
      try {
        const roomSession = await (!attachedTo
          ? client?.dial(params)
          : client?.reattach(params))

        if (roomSession) {
          await _startSession(roomSession, false, eventsCallback)
        }

        return roomSession
      } catch (e) {
        console.error(e)
        set({ attachedTo: undefined })
      }
      return
    },
    leave: async () => {
      const { roomSession } = get()
      set({ attachedTo: undefined })
      await roomSession?.hangup()
      deattachSdkSessionState()
      set({ roomSession: undefined })
    },
    startSession: async (
      roomSession: CallFabricRoomSession,
      eventsCallback?: EventsCallBacks,
    ) => {
      const { _startSession } = get().actions
      await _startSession(roomSession, true, eventsCallback)
    },
  },
})

const partialize = (state: State) => {
  type KeysToPersist = (typeof persistKeys)[number]
  const persistKeys = ['attachedTo'] as const

  return typeSafeFilterObject({ ...state }, key =>
    persistKeys.includes(key as KeysToPersist),
  ) as Store
}

export const useRoomSessionStore = create<Store>()(
  persist<Store>(stateCreatorFn, {

    merge: (persistedState, currentState) => {
      const mergedState = { ...currentState, ...(persistedState as State) }
      if (mergedState.attachedTo) {
        // if user in another route we need to deattach from the previous session
        if (!window.location.pathname.startsWith(`/room${mergedState.attachedTo}`)) {
          mergedState.attachedTo = undefined
          // workarround until the SDK have a deattach
          deattachSdkSessionState()
        }
      }
      return mergedState
    },
    name: 'sw_puc_attached',
    partialize: state => partialize(state),
    storage: createJSONStorage(() => sessionStorage),
  }),
)
export const useRoomSessionStoreActions = () =>
  useRoomSessionStore.getState().actions

export type CallStoreType = typeof useRoomSessionStore

// Expose the store to be used from the console
window.__roomSessionStore = useRoomSessionStore
