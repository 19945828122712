import { useCapabilitiesStore } from '@/stores/capabilities'
import { FEATURES, useFeaturesStoreActions } from '@/stores/features'
import { useRoomStore } from '@/stores/room'

/**
 * returns whether the corresponding button should be visible and enabled
 * - button `visible` if one of the `capabilities` is enabled and the FF is enabled
 * - button `enabled` if the current state matches the `capability` state
 * i.e. (currently muted and can unmute) or (currently unmute and can mute)
 */
export const useLayoutButtonState = () => {
  const isAudioOnly = useRoomStore(state => state.isAudioOnly)

  const hasPositionCapability = useCapabilitiesStore(
    state => state.hasPositionCapability,
  )
  const hasSetLayoutCapability = useCapabilitiesStore(
    state => state.hasSetLayoutCapability,
  )

  const { getFeatureFlagIsDisabled } = useFeaturesStoreActions()

  // button visible if one of the capabilities is enabled and FF is enabled
  const isRoomLayoutButtonVisible =
    !isAudioOnly &&
    !getFeatureFlagIsDisabled(FEATURES.ROOM_LAYOUT) &&
    (hasPositionCapability || hasSetLayoutCapability)

  const canSetPosition = hasPositionCapability
  const canSetLayout = hasSetLayoutCapability

  return {
    canSetLayout,
    canSetPosition,
    isRoomLayoutButtonVisible,
  }
}
