import { useState, useEffect } from 'react'

export const POLLING_INTERVAL = 10000 // 10 seconds

interface UsePollingParams<T> {
  initialData?: T
  interval?: number
  pollingFn: () => Promise<T>
  shouldPoll?: boolean
}

/**
 * A generic hook to poll a function at a given interval.
 *
 * @param params - Configuration object for the polling hook.
 * @param params.initialData - Optional initial data to be used before the polling function returns its first result.
 * @param params.interval - Optional interval (in milliseconds) at which the polling function should be executed. If not specified, a default `POLLING_INTERVAL` value is used.
 * @param params.pollingFn - Required function to be called at each interval. This function should return a promise that resolves to the data of type T.
 * @param params.shouldPoll - Optional boolean flag indicating whether polling should be started or not. Defaults to true.
 *
 * @returns - The latest data from the poll function, along with any error.
 */
export const usePolling = <T>(props: UsePollingParams<T>) => {
  const {
    initialData = null,
    interval = POLLING_INTERVAL,
    pollingFn,
    shouldPoll = true,
  } = props
  const [data, setData] = useState<T | null>(initialData)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    // This prevents memory leaks if the component unmounts before the promise resolves.
    let isMounted = true
    let intervalId = null

    const polling = async () => {
      try {
        const result = await pollingFn()
        if (isMounted) {
          setData(result)
        }
      } catch (err) {
        if (isMounted) {
          setError(err as Error)
        }
      }
    }

    if (shouldPoll) {
      intervalId = setInterval(polling, interval)
    } else {
      // Stop polling if the shouldPoll becomes false
      if (intervalId) {
        clearInterval(intervalId)
      }
    }

    return () => {
      isMounted = false

      // Clear interval when component unmounts
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [pollingFn, interval, shouldPoll])

  return { data, error }
}
