import { useEffect, useMemo, useState } from 'react'
import { useDevicesStore, useDevicesStoreActions } from '@/stores/devices'
import { Icon } from '@/components/common/icons/Icon'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/base/select'
import { useUserSettingsStoreActions } from '@/stores/userSettings'
import { WebRTC } from '@signalwire/js'
import { Feature } from './Feature'
import { FEATURES } from '@/stores/features'
import { useUiStore } from '@/stores/ui'
import { Label } from '@/components/base/label'

export const UserSettingsDevices = () => {
  const rootElementRef =
    useUiStore(state => state.rootElementRef) ?? document.body // the default fallback is document.body

  const cameraIdDefault = useDevicesStore(state => state.cameraIdDefault)
  const cameraList = useDevicesStore(state => state.cameraList)
  const microphoneIdDefault = useDevicesStore(
    state => state.microphoneIdDefault,
  )
  const microphoneList = useDevicesStore(state => state.microphoneList)

  const speakerIdDefault = useDevicesStore(state => state.speakerIdDefault)
  const speakerList = useDevicesStore(state => state.speakerList)
  const { refreshCameraList, refreshMicrophoneList, refreshSpeakerList } =
    useDevicesStoreActions()

  const {
    getPreferredVideoDeviceId,
    getPreferredMicrophoneDeviceId,
    getPreferredSpeakerDeviceId,
    setPreferredVideoDevice,
    setPreferredMicrophoneDevice,
    setPreferredSpeakerDevice,
  } = useUserSettingsStoreActions()
  const cameraId = getPreferredVideoDeviceId()
  const microphoneId = getPreferredMicrophoneDeviceId()
  const speakerId = getPreferredSpeakerDeviceId()
  const [isCameraPermissionGranted, setCameraPermissionGranted] =
    useState(false)
  const [isMicrophonePermissionGranted, setMicrophonePermissionGranted] =
    useState(false)
  const { checkCameraPermissions, checkMicrophonePermissions } = WebRTC

  useEffect(() => {
    refreshCameraList()
      ?.then(() => console.log('camera list refreshed'))
      .catch(console.error)
    refreshMicrophoneList()
      ?.then(() => console.log('microphone list refreshed'))
      .catch(console.error)
    refreshSpeakerList()
      ?.then(() => console.log('speaker list refreshed'))
      .catch(console.error)
  }, [refreshCameraList, refreshMicrophoneList, refreshSpeakerList])

  useEffect(() => {
    checkCameraPermissions()
      .then(isGranted => setCameraPermissionGranted(!!isGranted))
      .catch(console.error)
    checkMicrophonePermissions()
      .then(isGranted => setMicrophonePermissionGranted(!!isGranted))
      .catch(console.error)
  }, [checkCameraPermissions, checkMicrophonePermissions])

  const cameraItems = useMemo(
    () =>
      cameraList.map(camera => ({
        disabled: false,
        label: camera.label,
        value: camera.deviceId,
      })),
    [cameraList],
  )
  const microphoneItems = useMemo(
    () =>
      microphoneList.map(microphone => ({
        disabled: false,
        label: microphone.label,
        value: microphone.deviceId,
      })),
    [microphoneList],
  )
  const speakerItems = useMemo(
    () =>
      speakerList.map(speaker => ({
        disabled: false,
        label: speaker.label,
        value: speaker.deviceId,
      })),
    [speakerList],
  )

  return (
    <Feature name={FEATURES.PROFILE_SETTINGS}>
      {isCameraPermissionGranted ? (
        <li>
          <Label htmlFor="camera">Camera</Label>
          <Select
            defaultValue={cameraId || cameraIdDefault}
            onValueChange={deviceId => setPreferredVideoDevice({ deviceId })}
          >
            <SelectTrigger id="camera">
              <Icon
                className="mr-1 shrink-0"
                size={'lg'}
                tag={'videocam'}
                variant={'foreground'}
              />
              <SelectValue placeholder={'Select camera'} />
            </SelectTrigger>
            <SelectContent container={rootElementRef}>
              {cameraItems.map(item => (
                <SelectItem key={item.value} value={item.value}>
                  {item.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </li>
      ) : (
        <li>needs camera permissions</li>
      )}

      {isMicrophonePermissionGranted ? (
        <li className="mt-2">
          <Label htmlFor="microphone">Microphone</Label>
          <Select
            defaultValue={microphoneId || microphoneIdDefault}
            onValueChange={deviceId =>
              setPreferredMicrophoneDevice({ deviceId })
            }
          >
            <SelectTrigger id="microphone">
              <Icon
                className="mr-1 shrink-0"
                size={'lg'}
                tag={'mic'}
                variant={'foreground'}
              />
              <SelectValue placeholder={'Select microphone'} />
            </SelectTrigger>
            <SelectContent container={rootElementRef}>
              {microphoneItems.map(item => (
                <SelectItem key={item.value} value={item.value}>
                  {item.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </li>
      ) : (
        <p>needs camera permissions</p>
      )}

      {WebRTC.supportsMediaOutput() && (
        <li className="mt-2">
          <Label htmlFor="speaker">Speaker</Label>
          <Select
            defaultValue={speakerId || speakerIdDefault}
            onValueChange={deviceId => setPreferredSpeakerDevice({ deviceId })}
          >
            <SelectTrigger id="speaker">
              <Icon
                className="mr-1 shrink-0"
                size={'lg'}
                tag={'headphones'}
                variant={'foreground'}
              />
              <SelectValue placeholder={'Select speakers'} />
            </SelectTrigger>
            <SelectContent container={rootElementRef}>
              {speakerItems.map(item => (
                <SelectItem key={item.value} value={item.value}>
                  {item.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </li>
      )}
    </Feature>
  )
}
