import { Timestamp } from '@/components/Timestamp'
import {
  UnorderedList,
  UnorderedListBottom,
  UnorderedListItem,
} from '@/components/UnorderedList'
import { Button } from '@/components/base/button'
import { ScrollArea } from '@/components/base/scroll-area'
import { Skeleton } from '@/components/base/skeleton'
import { Icon } from '@/components/common/icons/Icon'
import type { LoadingState, Resource } from '@/helpers/types'
import { LOADING_STATE, TIMESTAMP_MULTIPLIER } from '@/helpers/constants'
import { useConversationsStoreActions } from '@/stores/conversations'
import { useEffect, useState } from 'react'
import { Feature } from './Feature'
import { FEATURES } from '@/stores/features'

type RecentContactsProps = Pick<Resource, 'resourceId'>
export const RecentContacts = ({ resourceId }: RecentContactsProps) => {
  const [status, setStatus] = useState<LoadingState>(LOADING_STATE.IDLE)
  // fetch the recents
  const { getMessageByResourceId, fetchMessagesByResourceId } =
    useConversationsStoreActions()

  const messages = getMessageByResourceId(resourceId)

  // TODO: should messages be prefetched in the parent component?
  // need to add a check to prevent multiple fetches if the user navigates away and back
  useEffect(() => {
    setStatus(LOADING_STATE.LOADING)

    fetchMessagesByResourceId(resourceId)
      .then(() => setStatus(LOADING_STATE.IDLE))
      .catch(error => {
        setStatus(LOADING_STATE.ERROR)
        console.error('Unable to fetch recents', error)
      })
  }, [fetchMessagesByResourceId, resourceId])

  return (
    <div className="flex h-full w-full flex-col overflow-y-hidden px-4">
      <h3 className="pb-5 text-center text-xl font-normal">Recents</h3>
      {/* TODO: show skeletons if loading? */}
      {status === LOADING_STATE.LOADING && !messages && (
        <Skeleton className="mb-2.5 h-full w-full bg-muted/20" />
      )}
      {status === LOADING_STATE.IDLE || messages?.length ? (
        messages?.length ? (
          <ScrollArea>
            <UnorderedList className="">
              {messages.map(message => (
                <UnorderedListItem
                  // TODO: disabled state?
                  aria-disabled
                  key={message.id}
                  className="flex bg-background px-4 py-5 transition-colors
                  first:border-t-0
                  hover:bg-neutral-light hover:text-primary-light
                  aria-disabled:hover:text-current"
                >
                  {/* TODO: icon for message type? */}
                  <Icon
                    tag="chat-bubble-outline"
                    size="xl"
                    variant="foreground"
                  />
                  <div className="flex flex-grow flex-col ">
                    <Timestamp
                      className="text-xs tracking-wider text-current"
                      timestamp={message.ts * TIMESTAMP_MULTIPLIER}
                    />
                    <p className="tracking-wider">{message.type}</p>
                  </div>
                  <Feature name={FEATURES.RECENT_CONTACTS_MESSAGE_OPTIONS}>
                    <Button disabled className="shrink" variant="icon">
                      <Icon
                        tag="keyboard-right-arrow"
                        size="xl"
                        variant="foreground"
                      />
                      <span className="sr-only">Show Resource</span>
                    </Button>
                  </Feature>
                </UnorderedListItem>
              ))}
              <UnorderedListBottom>End of List</UnorderedListBottom>
            </UnorderedList>
          </ScrollArea>
        ) : (
          <div className="mb-2.5 flex grow flex-col items-center justify-center bg-background">
            <p className="text-center text-sm font-normal text-secondary">
              None to Display
            </p>
          </div>
        )
      ) : null}
    </div>
  )
}
