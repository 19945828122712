// Stores
import { useAuthStore } from '@/stores/auth'
import { useMainStoreActions } from '@/stores/main'

import type { PropsWithChildren } from 'react'
import type React from 'react'
import { useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

// Components
import { Button } from '@/components/base/button'
import { Icon } from '@/components/common/icons/Icon'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/base/dialog'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/base/form'
import { Input } from '@/components/base/input'
import { useUiStore } from '@/stores/ui'

type DialogDeveloperCallProps = PropsWithChildren

export type FormSchema = z.infer<typeof formSchema>

const formSchema = z.object({
  address: z.string(),
})

export const DialogDeveloperCall = ({ children }: DialogDeveloperCallProps) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const rootElementRef =
    useUiStore(state => state.rootElementRef) ?? document.body // the default fallback is document.body

  const { accessToken } = useAuthStore()
  const { startApp } = useMainStoreActions()

  const formRef = useRef<HTMLFormElement>(null)
  const form = useForm<FormSchema>({
    defaultValues: {
      address: '',
    },
    resolver: zodResolver(formSchema),
  })

  const handleSubmit = async (data: { address: string }): Promise<void> => {
    const trimmedAddress = data.address.trim()
    if (!trimmedAddress) {
      console.error('Cannot join using an empty path')
      return
    }
    try {
      // NOTE: Reinitialize app with debug
      if (accessToken) {
        await startApp(accessToken, true)
        const router = useAuthStore.getState().router
        void router?.navigate({
          to: `/room/${trimmedAddress}`,
        })
      }
    } catch (error) {
      console.error('Error: ', error)
    }
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    form.setValue('address', value)
  }

  const handleReset = (event: React.MouseEvent) => {
    event.preventDefault()
    form.reset()
  }

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent
        className="overflow-hidden sm:max-w-[557px]"
        container={rootElementRef}
      >
        <DialogHeader>
          <DialogTitle className="font-normal">Make a Call</DialogTitle>
          <div>Response will be in the browser developer console</div>
        </DialogHeader>
        {dialogOpen ? (
          <Form {...form}>
            <form
              className="w-full"
              onSubmit={form.handleSubmit(handleSubmit)}
              ref={formRef}
            >
              <FormField
                control={form.control}
                name="address"
                render={({ field }) => (
                  <FormItem className="group/search relative flex h-10 w-full flex-col justify-center space-y-0">
                    <FormLabel className="sr-only">Address</FormLabel>
                    <div className="pointer-events-none absolute left-1 flex flex-col items-center justify-center p-2.5 text-input transition-colors group-focus-within/search:text-primary">
                      <Icon size="xl" tag="call" variant="foreground" />
                    </div>
                    <FormControl className="peer">
                      <Input
                        className="px-11"
                        placeholder={
                          'Phone number, SIP address, or a Call Fabric resource'
                        }
                        type="text"
                        {...field}
                        onChange={handleOnChange}
                      />
                    </FormControl>
                    {field.value && (
                      <Button
                        className="absolute right-1.5 h-8 rounded-none px-1.5 text-input transition-colors hover:text-primary group-focus-within/search:text-primary"
                        type="reset"
                        onClick={handleReset}
                        variant="icon"
                      >
                        <Icon tag="close" size="lg" variant="foreground" />
                        <span className="sr-only">Clear Search</span>
                      </Button>
                    )}
                    <FormDescription className="sr-only">
                      Enter a phone number, a SIP address, or a Call Fabric
                      resource to intiate a call
                    </FormDescription>
                    {/* NOTE: if error messages needed use <FormMessage  /> */}
                  </FormItem>
                )}
              />

              <DialogFooter>
                <Button
                  className="m-auto max-w-[328px]"
                  variant={'gradient'}
                  type="submit"
                >
                  Call
                </Button>
              </DialogFooter>
            </form>
          </Form>
        ) : null}
      </DialogContent>
    </Dialog>
  )
}
