import { cn, intlDateTimeFormat } from '@/helpers/utils'
import type { ComponentPropsWithoutRef, PropsWithChildren } from 'react'

type TimestampProps = PropsWithChildren<ComponentPropsWithoutRef<'time'>> & {
  timestamp: number
}

const formatTimestamp = intlDateTimeFormat('en-US')

export const Timestamp = ({
  children,
  className,
  timestamp,
  ...props
}: TimestampProps) => {
  const classes = cn('text-2xs font-medium text-foreground', className)
  const formattedTimestamp = formatTimestamp(timestamp)
  const dateTime = new Date(timestamp).toISOString()
  return (
    <time className={classes} dateTime={dateTime} {...props}>
      {formattedTimestamp}
      {children}
    </time>
  )
}
