import {
  ButtonRoomControl,
  type ButtonRoomControlProps,
} from '@/components/ButtonRoomControl'
import { useRoomStoreActions } from '@/stores/room'

type OmittedProps = Omit<ButtonRoomControlProps, 'isActive' | 'onClick' | 'tag'>

type ButtonAddVideoProps = OmittedProps

const ButtonAddVideo = ({
  disabled = false,
  isVisible = true,
  ...props
}: ButtonAddVideoProps) => {
  const { addVideoHandler } = useRoomStoreActions()

  return (
    <ButtonRoomControl
      disabled={disabled}
      isActive={false}
      isVisible={isVisible}
      onClick={addVideoHandler}
      tag={disabled ? 'videocam-off' : 'videocam'}
      {...props}
    >
      Video
    </ButtonRoomControl>
  )
}

export { ButtonAddVideo }
