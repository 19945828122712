// TODO: needs srcset and sizes, lazy loading when outside of viewport, fallback strategy, alt text,
// TODO: may need to make zoomable

import { Skeleton } from '@/components/base/skeleton'
import { Image } from '@/components/common/Image'
import { cn } from '@/helpers/utils'
import { useImage } from '@/hooks/useImage'
import { useState, type ComponentPropsWithoutRef } from 'react'

type ImageProps = ComponentPropsWithoutRef<'img'> & {
  alt: string
  classNameSkeleton?: string
  fallbackSrc?: string
}

export const ImageWithSkeleton = ({
  alt,
  className,
  classNameSkeleton,
  loading = 'eager',
  fallbackSrc,
  src,
  ...props
}: ImageProps) => {
  const [loaded, setLoaded] = useState(false)
  const { imageSrc } = useImage({ fallbackSrc, src })

  const handleImageError = () => {
    console.log('Load image error', imageSrc)
  }

  const handleImageLoad = () => {
    setLoaded(true)
  }

  const classes = cn(
    `${loaded ? 'blur-0' : 'blur-sm'} h-auto w-full aspect-video transition-[filter] duration-300 ease-linear`,
    className,
  )

  const classesSkeleton = cn(
    'absolute inset-0 aspect-video h-auto mx-auto w-[99%] rounded-xl bg-muted/10',
    classNameSkeleton,
  )

  return (
    <div className="relative flex items-center justify-center">
      {!loaded ? <Skeleton className={classesSkeleton} /> : null}
      <Image
        alt={alt}
        className={classes}
        loading={loading}
        onLoad={handleImageLoad}
        onError={handleImageError}
        src={imageSrc}
        {...props}
      />
    </div>
  )
}
