import { useLayoutEffect, useRef } from 'react'

interface UseLastScrollPositionProps<TDepends> {
  scrollContainerElement: HTMLElement | null
  shouldRun: boolean
  updateDependency: TDepends
}

/**
 * @description - scroll to/preserve the last scroll position of the `scrollContainerElement`
 * based on the `shouldRun` or `updateDependency` changes
 * - useful for lazy loading more data and preserving the scroll position
 */
export const useLastScrollPosition = <T>({
  scrollContainerElement,
  shouldRun,
  updateDependency,
}: UseLastScrollPositionProps<T>) => {
  const previousScrollPosition = useRef(0)

  if (scrollContainerElement) {
    previousScrollPosition.current =
      scrollContainerElement?.scrollHeight - scrollContainerElement?.scrollTop
  }

  useLayoutEffect(() => {
    if (scrollContainerElement && shouldRun) {
      scrollContainerElement.scrollTo({
        behavior: 'auto',
        left: 0,
        top:
          scrollContainerElement.scrollHeight - previousScrollPosition.current,
      })

      // store the previous scroll position
      previousScrollPosition.current =
        scrollContainerElement.scrollHeight - scrollContainerElement.scrollTop
    }
  }, [scrollContainerElement, shouldRun, updateDependency])
}
