import { cn } from '@/helpers/utils'
import type { ComponentPropsWithoutRef, PropsWithChildren } from 'react'

//  Presentational Components for Unordered List

type ListProps = PropsWithChildren<ComponentPropsWithoutRef<'ul'>>

const UnorderedList = ({ children, ...props }: ListProps) => {
  return <ul {...props}>{children}</ul>
}
UnorderedList.displayName = 'UnorderedList'

type ItemProps = PropsWithChildren<
  ComponentPropsWithoutRef<'li'> & {
    ItemIcon?: React.ReactNode
  }
>

const UnorderedListItem = ({ children, className, ...props }: ItemProps) => {
  const classes = cn(
    'flex gap-x-4 pl-4 pr-6 items-center border-border border-t grow',
    className,
  )
  return (
    <li className={classes} {...props}>
      {children}
    </li>
  )
}

UnorderedListItem.display = 'UnorderedListItem'

const UnorderedListBottom = ({ children, className, ...props }: ItemProps) => {
  const classes = cn(
    'block text-center pl-4 pr-6 border-border border-t',
    className,
  )
  return (
    <li className={classes} {...props}>
      <p className="pb-1.5 pt-2 text-xs font-semibold text-secondary">
        {children}
      </p>
    </li>
  )
}
UnorderedListBottom.display = 'UnorderedListBottom'

export { UnorderedList, UnorderedListBottom, UnorderedListItem }
