import { RootView } from '@/components/views/RootView'
import { useAuthStore } from '@/stores/auth'
import { useMainStore } from '@/stores/main'
import { createFileRoute, redirect } from '@tanstack/react-router'

export const Route = createFileRoute('/_auth')({
  // Before loading, authenticate the user via our auth context
  // This will also happen during prefetching (e.g. hovering over links, etc)
  beforeLoad: async ({ location }) => {
    const { isAuthenticated } = useAuthStore.getState().actions
    const authenticated = await isAuthenticated()

    // If the user is not authenticated, redirect them to the login page
    if (!authenticated) {
      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw redirect({
        search: {
          // Use the current location to power a redirect after login
          // (Do not use `router.state.resolvedLocation` as it can
          // potentially lag behind the actual current location)
          redirect: location.href,
        },
        to: '/login',
      })
    } else {
      const { client, actions } = useMainStore.getState()
      // TODO: maybe subscribe to auth store accessToken if set/updated and then call startApp...
      // TODO: retry/fail if the SignalWire client fails to start? Disconnect?
      if (!client) {
        const { startApp } = actions
        await startApp(false).catch(console.error)
      }
    }
  },
  component: RootView,
})
