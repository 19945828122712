// Inspired by react-hot-toast library
import * as React from 'react'

import type { ToastActionElement, ToastProps } from '@/components/base/toast'
import {
  ERROR_TOAST_LIMIT,
  TOAST_REMOVE_DELAY_MS,
  TOAST_TYPE,
} from '@/helpers/constants'
import type { ToastType } from '@/helpers/types'

type ToasterToast = ToastProps & {
  action?: ToastActionElement
  description?: React.ReactNode
  dismissLabel?: string
  id: string
  onDismiss?: () => void
  titleLabel?: React.ReactNode
  toastId?: string | undefined
  toastType: ToastType
}

const _actionTypes = {
  ADD_TOAST: 'ADD_TOAST',
  DISMISS_TOAST: 'DISMISS_TOAST',
  REMOVE_TOAST: 'REMOVE_TOAST',
  UPDATE_TOAST: 'UPDATE_TOAST',
} as const

function genId() {
  // This is a simple way to generate a unique ID, toast i.e toast-abc123
  return `toast-${performance.now()}`
}

type ActionType = typeof _actionTypes

type Action =
  | {
      toast: Partial<ToasterToast>
      type: ActionType['UPDATE_TOAST']
    }
  | {
      toast: ToasterToast
      type: ActionType['ADD_TOAST']
    }
  | {
      toastId?: ToasterToast['id'] | undefined
      type: ActionType['DISMISS_TOAST']
    }
  | {
      toastId?: ToasterToast['id'] | undefined
      type: ActionType['REMOVE_TOAST']
    }

interface State {
  toasts: ToasterToast[]
}

const toastTimeouts = new Map<
  ToasterToast['id'],
  ReturnType<typeof setTimeout>
>()

const addToRemoveQueue = (toastId: string) => {
  if (toastTimeouts.has(toastId)) {
    return
  }

  const timeout = setTimeout(() => {
    toastTimeouts.delete(toastId)
    dispatch({
      toastId: toastId,
      type: 'REMOVE_TOAST',
    })
  }, TOAST_REMOVE_DELAY_MS)

  toastTimeouts.set(toastId, timeout)
}

const addNewToast = (
  newToast: ToasterToast,
  existingToasts: State['toasts'],
) => {
  // NOTE: incoming call toasts are prepended to the beginning of the list, never replaced
  // other toasts are prepended after incoming call toasts and can replace old toasts if at limit

  const incomingCallToasts = existingToasts.filter(
    toast => toast.toastType === TOAST_TYPE.INCOMING_CALL,
  )
  const rest = existingToasts.filter(
    toast => toast.toastType !== TOAST_TYPE.INCOMING_CALL,
  )

  // prepend new incoming toast to beginning of incoming calls
  if (newToast.toastType === TOAST_TYPE.INCOMING_CALL) {
    return [newToast, ...incomingCallToasts, ...rest]
  } else {
    // prepend new toast to the other toasts and replace old toasts if at limit
    const updatedRest = [newToast, ...rest].slice(0, ERROR_TOAST_LIMIT)
    return [...incomingCallToasts, ...updatedRest]
  }
}

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'ADD_TOAST':
      return {
        ...state,
        toasts: addNewToast(action.toast, state.toasts),
      }

    case 'UPDATE_TOAST':
      return {
        ...state,
        toasts: state.toasts.map(t =>
          t.id === action.toast.id ? { ...t, ...action.toast } : t,
        ),
      }

    case 'DISMISS_TOAST': {
      const { toastId } = action

      // ! Side effects ! - This could be extracted into a dismissToast() action,
      // but I'll keep it here for simplicity
      if (toastId) {
        addToRemoveQueue(toastId)
      } else {
        state.toasts.forEach(toast => {
          addToRemoveQueue(toast.id)
        })
      }

      return {
        ...state,
        toasts: state.toasts.map(t =>
          t.id === toastId || toastId === undefined
            ? {
                ...t,
                open: false,
              }
            : t,
        ),
      }
    }
    case 'REMOVE_TOAST':
      if (action.toastId === undefined) {
        return {
          ...state,
          toasts: [],
        }
      }
      return {
        ...state,
        toasts: state.toasts.filter(t => t.id !== action.toastId),
      }
  }
}

const listeners: ((state: State) => void)[] = []

let memoryState: State = { toasts: [] }

function dispatch(action: Action) {
  memoryState = reducer(memoryState, action)
  listeners.forEach(listener => {
    listener(memoryState)
  })
}

type Toast = Omit<ToasterToast, 'id'>

function toast({ ...props }: Toast) {
  const id = props.toastId || genId()

  const update = (props: ToasterToast) =>
    dispatch({
      toast: { ...props, id },
      type: 'UPDATE_TOAST',
    })
  const dismiss = () => dispatch({ toastId: id, type: 'DISMISS_TOAST' })

  dispatch({
    toast: {
      ...props,
      id,
      onOpenChange: open => {
        if (!open) dismiss()
      },
      open: true,
    },
    type: 'ADD_TOAST',
  })

  return {
    dismiss,
    id: id,
    update,
  }
}

function useToast() {
  const [state, setState] = React.useState<State>(memoryState)

  React.useEffect(() => {
    listeners.push(setState)
    return () => {
      // Clean up the listener when the useToast hook is unmounted
      const index = listeners.indexOf(setState)
      if (index > -1) {
        listeners.splice(index, 1)
      }
    }
  }, [state])

  return {
    ...state,
    dismiss: (toastId?: string) => dispatch({ toastId, type: 'DISMISS_TOAST' }),
    toast,
  }
}

export { useToast, toast }

// for testing purposes only
const __resetToastMemoryState = () => {
  memoryState = {
    toasts: [],
  }
}

export { __resetToastMemoryState, genId as __genToastId }
