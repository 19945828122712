import { forwardRef, type ComponentPropsWithRef } from 'react'

const styleMap = {
  currentColor: {
    negative: 'fill-current',
    primary: 'fill-current',
  },
  // TODO: update colors to match spec
  light: {
    negative: 'fill-negative-light', //'#F72B73',
    primary: 'fill-primary-light', // '#0451F6',
  },
} as const

export type SWIconStyle = keyof typeof styleMap

type SWIconProps = Pick<ComponentPropsWithRef<'svg'>, 'className'> & {
  type?: SWIconStyle
}

export const SWIcon = forwardRef<SVGSVGElement, SWIconProps>(function inner(
  { className = '', type = 'light', ...props },
  ref,
) {
  return (
    <svg className={className} ref={ref} viewBox="0 0 64 73" {...props}>
      <path
        d="M63.9998 46.5209C63.9998 48.596 63.4369 49.9545 62.3171 51.0736C55.6065 57.7826 48.8931 64.4815 42.177 71.1703C39.841 73.495 36.4374 73.6097 34.1234 71.4843C31.6868 69.2442 31.5903 65.569 33.9726 63.1356C36.9983 60.0482 40.0681 57.003 43.132 53.9537C46.8137 50.2907 50.5034 46.6369 54.2012 42.9926C55.9925 41.2315 58.3929 40.7666 60.5902 41.7669C62.7876 42.7672 63.9315 44.5686 63.9998 46.5209Z"
        className={styleMap[type].primary}
      />
      <path
        d="M0.000812669 25.3858C0.0329789 24.3372 0.589857 23.0088 1.68351 21.9159C8.37007 15.2378 15.058 8.56035 21.7472 1.88357C23.1384 0.492799 24.7789 -0.219695 26.739 0.0600696C29.061 0.402228 30.6834 1.69438 31.4393 3.92847C32.1952 6.16256 31.6725 8.18129 30.032 9.85183C26.8355 13.1003 23.5988 16.3166 20.3822 19.5329C16.868 23.0309 13.3444 26.5196 9.81152 29.9989C8.01423 31.762 5.61985 32.1947 3.41647 31.2307C1.27339 30.2988 -0.0373848 28.2438 0.000812669 25.3858Z"
        className={styleMap[type].primary}
      />
      <path
        d="M4.59424 32.9836C5.35618 32.9494 5.98342 32.9675 6.60463 32.8849C8.18055 32.688 9.64041 31.953 10.738 30.8038C12.0206 29.4936 13.3093 28.1873 14.6241 26.8489C14.8714 27.0683 15.0262 27.191 15.1609 27.3279C20.1078 32.2791 25.054 37.2324 29.9995 42.1876C31.6219 43.8179 32.1909 45.7843 31.5395 47.9983C30.9123 50.1257 29.4507 51.4883 27.2835 51.9774C25.4078 52.398 23.6186 52.0398 22.2435 50.6751C16.3671 44.8484 10.551 38.9673 4.59424 32.9836Z"
        className={styleMap[type].negative}
      />
      <path
        d="M59.4545 40.0944C56.4389 39.7865 54.2878 40.9519 52.4744 42.9585C51.4933 44.0454 50.4178 45.0477 49.3583 46.1164C49.1372 45.9152 48.9824 45.7964 48.8436 45.6555C43.8914 40.7003 38.9418 35.7464 33.9949 30.7938C32.3745 29.1635 31.8076 27.1931 32.4751 24.9791C33.1164 22.8537 34.5799 21.4951 36.7532 21.0181C38.6751 20.5955 40.4623 21.0181 41.8676 22.4109C47.6307 28.1229 53.3677 33.8571 59.0785 39.6134C59.163 39.6899 59.2273 39.7966 59.4545 40.0944Z"
        className={styleMap[type].negative}
      />
    </svg>
  )
})
