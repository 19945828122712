import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/base/alert-dialog'
import { Icon } from '@/components/common/icons/Icon'
import { useUiStore, useUiStoreActions } from '@/stores/ui'

const ErrorDialog = () => {
  const rootElementRef =
    useUiStore(state => state.rootElementRef) ?? document.body // the default fallback is document.body

  const displayErrorDialog = useUiStore(state => state.displayErrorDialog)
  const errorCancelLabel = useUiStore(state => state.errorCancelLabel)
  const errorConfirmLabel = useUiStore(state => state.errorConfirmLabel)
  const errorTitle = useUiStore(state => state.errorTitle)
  const errorDescription = useUiStore(state => state.errorDescription)
  const errorOnClose = useUiStore(state => state.errorOnClose)
  const errorOnConfirm = useUiStore(state => state.errorOnConfirm)
  const { hideErrorDialog, setErrorDialogRef, showErrorDialog } =
    useUiStoreActions()

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      hideErrorDialog()
    } else {
      showErrorDialog()
    }
  }

  return (
    <AlertDialog open={displayErrorDialog} onOpenChange={handleOpenChange}>
      <AlertDialogContent
        className="z-60 flex max-w-[343px] flex-col items-center justify-center gap-8 rounded-md px-4 py-8"
        container={rootElementRef}
        ref={setErrorDialogRef}
      >
        <AlertDialogHeader className="space-y-8">
          <div className="flex flex-col items-center justify-center gap-y-2">
            <Icon className="h-20 w-20 fill-muted" tag="videocam-off" />
            <AlertDialogTitle>{errorTitle}</AlertDialogTitle>
          </div>
          <AlertDialogDescription className="px-4">
            {errorDescription}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          {errorCancelLabel ? (
            <AlertDialogCancel onClick={errorOnClose}>Cancel</AlertDialogCancel>
          ) : null}
          <AlertDialogAction
            className="w-auto min-w-36"
            onClick={errorOnConfirm}
            variant="gradient"
          >
            {errorConfirmLabel}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
ErrorDialog.displayName = 'ErrorDialog'

export { ErrorDialog }
