// Store for auth and connection
import { SignalWire } from '@signalwire/js'
import type { Fabric } from '@signalwire/js'
import { create, type StateCreator } from 'zustand'
import { useAuthStore } from './auth'
import { ENV_VARS } from '@/helpers/constants'

interface Actions {
  actions: {
    endApp: () => boolean
    setHost: (host: string) => void
    startApp: (debug: boolean | null) => Promise<boolean>
  }
}

interface State {
  client: Fabric.SignalWireContract | null
  host: string
  memberId: string
  memberName: string
}

type Store = Actions & State

const initialState: State = {
  client: null,
  host: '',
  memberId: '',
  memberName: '',
}

const stateCreatorFn: StateCreator<Store> = (set, get) => ({
  ...initialState,
  actions: {
    endApp: () => {
      // TODO: Clean up on exit
      return true
    },
    resetApp: () => {
      set(initialState)
    },
    setHost: (host: string) => {
      set({ host: host.trim() })
    },
    startApp: async (debug = false) => {
      console.log('Starting App')

      const { accessToken } = useAuthStore.getState()
      const { host } = get()

      if (!accessToken || !host) {
        console.warn('Missing access token or host')
        return false
      }
      console.log('XXXX: Starting using host:', host)

      // TODO: Add try/catch?
      let clientParams: Fabric.SignalWireOptions = {
        host,
        token: accessToken,
      }

      if (ENV_VARS.IS_DEVELOPMENT ?? debug) {
        clientParams = {
          ...clientParams,
          debug: { logWsTraffic: true },
          logLevel: 'debug',
        }
      }

      const client = await SignalWire(clientParams)

      if (client) {
        set({ client })

        client
          ?.getSubscriberInfo()
          .then(profile => {
            set({ memberId: profile?.id })
          })
          .catch(error => {
            console.error('Unable to get member info', error)
          })
      }

      // TODO: Call a function in the ui store to set things up?
      return true
    },
  },
})

export const useMainStore = create<Store>()(stateCreatorFn)
export const useMainStoreActions = () => useMainStore.getState().actions

// Expose the store to be used from the console
window.__mainStore = useMainStore
