import type { ButtonProps } from '@/components/base/button'
import { Button } from '@/components/base/button'
import { Icon, type IconTagName } from '@/components/common/icons/Icon'
import { forwardRef } from 'react'

export interface ButtonPanelOptionProps extends ButtonProps {
  isActive?: boolean
  isVisible?: boolean
  tag: IconTagName
}

const ButtonPanelOption = forwardRef<HTMLButtonElement, ButtonPanelOptionProps>(
  (
    {
      children,
      disabled,
      isActive = undefined,
      tag,
      isVisible = true,
      ...props
    },
    ref,
  ) => {
    if (!isVisible) {
      return null
    }

    // aria pressed is undefined if disabled (meaning cannot be pressed)
    const isPressed = disabled ? undefined : isActive

    return (
      <Button
        aria-pressed={isPressed}
        disabled={disabled}
        className="justify-start gap-x-3 text-sm text-foreground"
        variant="link"
        ref={ref}
        {...props}
      >
        <Icon size="xl" tag={tag} />
        <span className="truncate">{children}</span>
      </Button>
    )
  },
)
ButtonPanelOption.displayName = 'ButtonPanelOption'
export { ButtonPanelOption }
