import { Contact } from '@/components/Contact'
import { RecentContacts } from '@/components/RecentContacts'
import { ResourceTypeIcon } from '@/components/ResourceTypeIcon'
import { Search } from '@/components/Search'
import { Timestamp } from '@/components/Timestamp'
import {
  UnorderedList,
  UnorderedListBottom,
  UnorderedListItem,
} from '@/components/UnorderedList'
import { Button } from '@/components/base/button'
import { ScrollArea } from '@/components/base/scroll-area'
import { Icon } from '@/components/common/icons/Icon'
import type {
  Conversation,
  ConversationWithResource,
  Resource,
} from '@/helpers/types'
import { useSimpleSearch } from '@/hooks/useSimpleSearch'
import {
  useConversationsStore,
  useConversationsStoreActions,
} from '@/stores/conversations'
import { useMainStore } from '@/stores/main'
import { useResourcesStore, useResourcesStoreActions } from '@/stores/resources'
import { useUiStoreActions } from '@/stores/ui'
import { useEffect, useMemo, useState } from 'react'
import { Feature } from '@/components/Feature'
import { FEATURES } from '@/stores/features'
import { RoomLink } from '@/components/RoomLink'

// TODO: we might only need the address `type` property
const mergeConversationsWithResources = (
  conversations: Conversation[],
  resources: Resource[],
): ConversationWithResource[] => {
  return conversations.reduce((acc, conversation) => {
    const resource = resources.find(resource => resource.id === conversation.id)
    if (!resource) {
      console.warn('Unable to find resource for conversation', conversation)
      return acc
    }
    const mergedItem = {
      ...conversation,
      ...resource,
    } satisfies ConversationWithResource
    return acc.concat(mergedItem)
  }, [] as ConversationWithResource[])
}

/**
 // TODO: There are only 2 ways to currently get a resource:
 * - from `client.address.getAddresses` and filtering by the `displayName` param
 * - fetching all resources and lookup by addressId (this is what we're doing here)
 * In the future, we should be able to fetch a resource by addressId directly
 */

export const RecentView = () => {
  const conversations = useConversationsStore(state => state.conversations)
  const resourcesByIdMap = useResourcesStore(state => state.resourcesByIdMap)
  const { getResourcesFromMap } = useResourcesStoreActions()

  const { fetchRecents } = useConversationsStoreActions()

  const { hideContact, showContact } = useUiStoreActions()

  const resources = getResourcesFromMap(resourcesByIdMap)

  // TODO: move this to the store?
  const conversationsWithResources = useMemo(
    () => mergeConversationsWithResources(conversations, resources),
    [conversations, resources],
  )

  const [contactId, setContactId] = useState<string>('')

  // TODO: Is this necessary? And if so, should it be in a hook?
  useEffect(() => {
    showContact()
    return () => {
      hideContact()
    }
  }, [hideContact, showContact])

  useEffect(() => {
    // subscribe to any new messages and update the recents
    const client = useMainStore.getState().client
    client?.conversation
      .subscribe(newMsg => {
        console.log('XXXX new message received', newMsg)
        // TODO: fetch and create/update only the specific conversation and resource
        fetchRecents().catch(err => {
          console.error('XXXX unable to fetch recents', err)
        })
      })
      .catch(err => {
        console.error('XXXX unable to subscribe to conversation', err)
      })
  }, [fetchRecents])

  const handleClick = (resourceId: string) => {
    setContactId(resourceId)
    showContact()
  }

  const resetContactId = () => {
    setContactId('')
  }

  const { handleSearch, handleSearchReset, searchResults } = useSimpleSearch({
    items: conversationsWithResources,
    key: 'displayName',
  })

  return (
    <div className="flex h-full">
      <section>
        <div className="flex flex-col gap-y-1 pb-3.5 pl-6 pr-5 pt-2">
          <div className="flex items-center justify-between">
            <h2 className="text-4xl font-semibold leading-normal">Recent</h2>
            <Feature name={FEATURES.RECENT_MORE_OPTIONS}>
              <Button
                className="bg-background p-0 text-foreground"
                disabled
                variant="icon"
              >
                {/* TODO: what does this 3 dots menu do? */}
                <span className="sr-only">More</span>
                <Icon tag="more-horiz" size="xl" variant="foreground" />
              </Button>
            </Feature>
          </div>
          <Search
            formDescription="Search for a recent item."
            onChange={handleSearch}
            onReset={handleSearchReset}
            onSubmit={handleSearch}
            placeholder="Search Recents"
          />
        </div>
        <div className="flex h-full overflow-y-hidden">
          <ScrollArea>
            <UnorderedList className="w-80">
              {searchResults.map(
                ({ displayName, id, lastMessageAt, type, channels, name }) => (
                  <UnorderedListItem
                    className={`relative min-h-10 before:absolute before:inset-0 before:w-2 before:transition-colors hover:bg-neutral-light hover:text-primary-light ${contactId === id ? 'before:bg-primary-light' : 'before:bg-transparent'}`}
                    key={id}
                  >
                    {/* TODO: resource type needed or no? */}
                    <ResourceTypeIcon
                      className="shrink-0"
                      resourceType={type}
                      size="xl"
                      variant="foreground"
                    />
                    <p className="line-clamp-2 flex-grow">
                      <RoomLink
                        address={
                          channels.video ?? channels.audio ?? `/public/${name}`
                        }
                      >
                        {displayName}
                      </RoomLink>
                    </p>
                    <Timestamp className="shrink-0" timestamp={lastMessageAt} />
                    <Button
                      className="shrink p-0"
                      onClick={() => handleClick(id)}
                      variant="icon"
                    >
                      <Icon
                        tag="keyboard-right-arrow"
                        size="lg"
                        variant="foreground"
                      />
                      <span className="sr-only">Show Resource</span>
                    </Button>
                  </UnorderedListItem>
                ),
              )}
              <UnorderedListBottom>End of List</UnorderedListBottom>
            </UnorderedList>
          </ScrollArea>
        </div>
      </section>
      <div className="flex basis-full flex-col items-center justify-center bg-neutral-light">
        {contactId ? (
          <>
            {/* TODO: use router to update the path or param based on selected contact? */}
            <Contact onClose={resetContactId} resourceId={contactId} />
            <RecentContacts resourceId={contactId} />
          </>
        ) : (
          <p className="text-center text-sm font-normal text-secondary">
            Select an entry
          </p>
        )}
      </div>
    </div>
  )
}
