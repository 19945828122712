import { Button } from '@/components/base/button'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/base/form'
import { Input } from '@/components/base/input'
import { Icon } from '@/components/common/icons/Icon'
import { useClickOutside } from '@/hooks/useClickOutside'
import { zodResolver } from '@hookform/resolvers/zod'
import type React from 'react'
import { forwardRef, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

export type SearchFormSchema = z.infer<typeof formSchema>

const formSchema = z.object({
  search: z.string(),
})

interface SearchProps {
  formDescription?: string
  label?: string
  onChange: (data: SearchFormSchema) => void
  onClickOutside?: (data: SearchFormSchema) => void
  onClose?: (data: SearchFormSchema) => void
  onEscapeKey?: (data: SearchFormSchema) => void
  onReset: () => void
  onSubmit: (data: SearchFormSchema) => void
  placeholder: string
  showClose?: boolean
}

export const Search = forwardRef<HTMLInputElement, SearchProps>(
  (
    {
      formDescription,
      label,
      onChange,
      onClickOutside,
      onClose,
      onEscapeKey,
      onReset,
      onSubmit,
      placeholder,
      showClose,
    },
    ref,
  ) => {
    const formRef = useRef<HTMLFormElement>(null)
    const form = useForm<SearchFormSchema>({
      defaultValues: {
        search: '',
      },
      resolver: zodResolver(formSchema),
    })

    const handleSubmit = (data: SearchFormSchema) => {
      // callback to parent component
      onSubmit(data)
    }

    const handleReset = (event: React.MouseEvent) => {
      event.preventDefault()
      // NOTE: or reset the entire form with form.reset()
      form.setValue('search', '')
      // callback to parent component
      onReset()
    }

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const searchValue = event.target.value
      form.setValue('search', searchValue)
      // callback to parent component
      onChange(form.getValues())
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
      if (event.key === 'Escape' || event.code === 'Escape') {
        // callback to parent component
        onEscapeKey?.(form.getValues())
      }
    }

    const handleOnClickOutside = () => {
      // callback to parent component
      onClickOutside?.(form.getValues())
    }

    const handleClose = () => {
      // callback to parent component
      onClose?.(form.getValues())
    }

    useClickOutside(formRef, handleOnClickOutside)

    return (
      <Form {...form}>
        <form
          className="w-full"
          onSubmit={form.handleSubmit(handleSubmit)}
          role="search"
          ref={formRef}
        >
          <FormField
            control={form.control}
            name="search"
            render={({ field }) => (
              <FormItem className="group/search relative flex h-10 w-full flex-col justify-center space-y-0">
                <FormLabel className="sr-only">
                  {label ?? placeholder}
                </FormLabel>
                <div
                  className="pointer-events-none absolute left-1 flex flex-col
                items-center justify-center p-2.5 text-input
                transition-colors group-focus-within/search:text-primary"
                >
                  <Icon size="xl" tag="search" />
                </div>
                <FormControl className="peer">
                  <Input
                    className="px-11"
                    placeholder={placeholder}
                    type="search"
                    {...field}
                    onChange={handleOnChange}
                    onKeyDown={handleKeyDown}
                    ref={ref}
                  />
                </FormControl>
                {field.value && (
                  <Button
                    className="absolute right-1.5 h-8 rounded-none px-1.5 text-input transition-colors hover:text-primary group-focus-within/search:text-primary"
                    type="reset"
                    onClick={handleReset}
                    variant="icon"
                  >
                    <Icon tag="close" size="lg" />
                    <span className="sr-only">Clear Search</span>
                  </Button>
                )}
                {showClose && (
                  <Button
                    className="absolute right-1.5 h-8 rounded-none px-1.5 text-input transition-colors hover:text-primary group-focus-within/search:text-primary"
                    type="button"
                    onClick={handleClose}
                    variant="icon"
                  >
                    <Icon tag="close" size="lg" />
                    <span className="sr-only">Close Search</span>
                  </Button>
                )}
                <FormDescription className="sr-only">
                  {formDescription}
                </FormDescription>
                {/* NOTE: if error messages needed use <FormMessage  /> */}
              </FormItem>
            )}
          />
        </form>
      </Form>
    )
  },
)

Search.displayName = 'Search'
