import { CircularProgressIndicator } from '@/components/CircularProgressIndicator'
import type { IconProps } from '@/components/common/icons/Icon'
import { cn } from '@/helpers/utils'
import type { ClassValue } from 'clsx'
import type { ComponentPropsWithoutRef, ReactNode } from 'react'
import { useEffect, useState } from 'react'

type LoadingWrapperProps = ComponentPropsWithoutRef<'section'> &
  Pick<IconProps, 'size'> & {
    classesParent?: ClassValue
    delayMs?: number
    loading?: boolean
    loadingLabel?: ReactNode
    orientation?: 'horizontal' | 'vertical'
  }

export const LoadingWrapper = ({
  children,
  className,
  classesParent,
  delayMs = 1000,
  loading,
  loadingLabel = 'Loading...',
  orientation = 'vertical',
  size = '3xl',
  ...props
}: LoadingWrapperProps) => {
  const initialLoadingState = loading ?? true
  const [loadingState, setLoadingState] = useState(initialLoadingState)

  // Update when loading prop changes
  useEffect(() => {
    if (loading !== undefined) {
      setLoadingState(loading)
    }
  }, [loading])

  // fallback to a simple loading state if loading prop is not provided
  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (!loadingState && loading === undefined) {
      timeout = setTimeout(() => {
        setLoadingState(true)
      }, delayMs)
    }
    return () => clearTimeout(timeout)
  }, [loading, loadingState, delayMs])

  // show children if not loading
  if (!loadingState) {
    return children
  }

  // show nothing if no children and not loading
  if (!children && !loadingState) {
    return null
  }

  // render text or react node
  const loadingLabelNode =
    typeof loadingLabel === 'string' ? (
      <h2 className="pb-6 text-center font-sans text-2xl text-secondary">
        {loadingLabel}
      </h2>
    ) : (
      loadingLabel
    )

  return (
    <div
      className={cn(
        'flex min-h-svh items-center justify-center overflow-hidden',
        classesParent,
      )}
    >
      <section
        className={cn(
          'relative flex aspect-video min-w-80 flex-col items-center justify-center gap-y-6 bg-background px-4 py-5',
          className,
        )}
        {...props}
      >
        {loadingLabelNode}
        <div
          className={cn(
            'absolute inset-0 flex items-center justify-center',
            orientation === 'vertical' && '-top-1/3 translate-y-1/3',
            orientation === 'horizontal' && '-left-1/3 translate-x-1/3',
          )}
        >
          <CircularProgressIndicator loading={loadingState} size={size}>
            {children}
          </CircularProgressIndicator>
        </div>
      </section>
    </div>
  )
}
