import { forwardRef, type ComponentPropsWithRef } from 'react'

type SWIconProps = Pick<
  ComponentPropsWithRef<'svg'>,
  'className' | 'height' | 'width'
>

export const SWLogoTypeIcon = forwardRef<SVGSVGElement, SWIconProps>(
  function inner({ width = 210, height = 45, ...props }, ref) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        fill="none"
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        {...props}
      >
        <path
          d="M165.524 31.2227H161.33C159.493 24.6452 157.662 18.0814 155.832 11.5175C155.791 11.5175 155.743 11.5175 155.702 11.5175C153.892 18.0745 152.089 24.6247 150.272 31.209H146.071C143.66 23.1084 141.249 14.9873 138.818 6.81835H142.806C144.61 12.945 146.413 19.0786 148.216 25.2189C148.25 25.2189 148.291 25.2189 148.325 25.2189C150.06 19.0922 151.802 12.9587 153.543 6.81152H158.004C159.759 12.9314 161.514 19.0513 163.27 25.1711C163.317 25.1711 163.358 25.1711 163.406 25.1711C165.203 19.0513 166.999 12.9382 168.802 6.80469H172.757C170.346 14.96 167.942 23.0742 165.53 31.2159L165.524 31.2227Z"
          fill="black"
        />
        <path
          d="M89.0117 16.6676V14.9805H92.6795C92.6795 15.2127 92.6795 15.4518 92.6795 15.6908C92.6795 20.793 92.6795 25.902 92.6795 31.0042C92.6795 36.0107 88.6497 39.8425 83.6363 39.6171C80.4671 39.4736 77.5369 37.8617 76.0275 35.3892C76.8266 34.6788 77.6462 33.9616 78.5 33.1967C79.5723 34.8701 81.0203 36.0039 83.0011 36.2907C84.1691 36.4615 85.3165 36.2907 86.3616 35.7034C88.5677 34.4603 89.3054 32.3975 88.9161 29.6654C84.4354 33.183 79.2581 31.2364 77.1135 28.4838C74.518 25.1575 74.6546 20.39 77.4823 17.255C80.0709 14.3863 85.139 13.3686 89.0117 16.6539V16.6676ZM89.0117 23.088C89.0117 20.2671 86.8124 18.0609 83.9915 18.0473C81.1296 18.0404 78.903 20.2398 78.903 23.0811C78.903 25.9088 81.1501 28.1423 83.9847 28.1355C86.7782 28.1355 89.0117 25.8883 89.0117 23.088Z"
          fill="black"
        />
        <path
          d="M64.8533 9.70108C63.979 10.5002 63.1321 11.2789 62.251 12.0848C62.0802 11.9004 61.93 11.7365 61.7797 11.5862C60.3112 10.1177 58.5558 9.59863 56.5614 10.1792C54.4031 10.8076 53.7542 13.4304 55.4003 14.974C56.015 15.5546 56.8073 15.978 57.5859 16.3469C58.9656 16.9957 60.4137 17.5012 61.7934 18.15C63.617 19.0107 65.1128 20.2401 65.7685 22.255C67.2029 26.681 64.4845 30.8747 59.7238 31.469C56.0355 31.9334 52.9073 30.8406 50.5918 27.808C50.2776 27.3982 50.0454 26.9405 49.738 26.4351C50.824 25.7726 51.8691 25.1305 52.9482 24.468C53.0848 24.7207 53.1941 24.912 53.3034 25.11C54.6558 27.5211 57.6133 28.6139 60.2019 27.6509C61.3972 27.2069 62.1758 26.3941 62.3603 25.0964C62.5447 23.8328 62.1007 22.7809 61.0694 22.0706C60.277 21.5242 59.396 21.1007 58.5217 20.6909C57.0464 20.001 55.5096 19.4341 54.0752 18.676C52.3745 17.7812 51.1997 16.3879 50.8923 14.4208C50.2845 10.507 52.4155 7.3993 56.3019 6.63432C59.4711 6.01277 62.2168 6.95534 64.4981 9.23663C64.6279 9.3664 64.7303 9.51666 64.8669 9.69425L64.8533 9.70108Z"
          fill="black"
        />
        <path
          d="M125.943 16.8454V14.9876H129.59V31.2162H125.97V29.2286C123.962 31.2435 121.653 31.8719 119.065 31.4553C117.091 31.1411 115.479 30.1302 114.222 28.5797C111.517 25.2466 111.715 20.3493 114.557 17.2006C116.886 14.6188 122.022 13.164 125.943 16.8523V16.8454ZM121.018 18.0476C118.183 18.0339 115.909 20.281 115.909 23.0883C115.909 25.8682 118.17 28.1221 120.957 28.1358C123.771 28.1494 126.025 25.9365 126.045 23.1497C126.066 20.2947 123.859 18.0612 121.018 18.0476Z"
          fill="black"
        />
        <path
          d="M209.339 23.7371H196.198C195.973 25.1919 197.291 27.1453 198.958 27.8898C201.28 28.928 203.568 28.2382 205.487 25.8749C206.471 26.2642 207.482 26.6672 208.492 27.0702C207.4 29.8433 203.5 32.589 198.445 31.2161C194.149 30.0482 191.601 25.6905 192.592 21.2509C193.616 16.6541 197.865 13.9221 202.687 14.7553C206.846 15.4725 209.831 19.4409 209.333 23.7371H209.339ZM196.505 20.9299H205.316C204.961 19.2018 203.206 17.8836 201.177 17.7948C199.067 17.6992 197.1 18.9969 196.505 20.9299Z"
          fill="black"
        />
        <path
          d="M99.619 14.9739V16.2102C99.817 16.0804 99.9536 16.0053 100.07 15.9165C102.01 14.4821 104.12 14.1406 106.313 15.1788C108.505 16.217 109.646 18.0543 109.687 20.4722C109.741 23.9215 109.707 27.3776 109.707 30.8268C109.707 30.9429 109.687 31.0659 109.673 31.2093H106.08C106.08 30.9976 106.08 30.7859 106.08 30.5673C106.08 27.4664 106.08 24.3655 106.08 21.2645C106.08 19.2291 104.824 17.9109 102.911 17.9177C100.992 17.9246 99.6395 19.3043 99.6326 21.285C99.6258 24.5704 99.6326 27.8557 99.6326 31.1957H96.0673V14.9807H99.619V14.9739Z"
          fill="black"
        />
        <path
          d="M133.039 6.81152H136.564V31.1954H133.039V6.81152Z"
          fill="black"
        />
        <path
          d="M182.012 14.9808H185.564V17.344C185.789 17.023 185.926 16.8386 186.055 16.6542C187.162 15.0696 188.808 14.3797 190.556 14.7417C191 14.8305 191.198 14.9808 191.198 14.9808C191.198 15.8755 191.185 17.3645 191.185 18.1432C190.413 18.1432 189.668 18.0475 188.958 18.1637C186.971 18.4915 185.625 20.2878 185.591 22.6101C185.557 25.2329 185.577 27.8557 185.577 30.4785C185.577 30.7039 185.577 30.9293 185.577 31.2025H182.005V14.9808H182.012Z"
          fill="black"
        />
        <path
          d="M174.922 14.9805H178.487V31.209H174.922V14.9805Z"
          fill="black"
        />
        <path
          d="M178.897 8.66283C178.897 9.87178 177.92 10.8622 176.705 10.869C175.509 10.8826 174.485 9.87861 174.471 8.69015C174.458 7.47437 175.489 6.43618 176.712 6.44984C177.92 6.4635 178.897 7.44705 178.904 8.66283H178.897Z"
          fill="black"
        />
        <path
          d="M68.9036 14.9736H72.4689V31.2022H68.9036V14.9736Z"
          fill="black"
        />
        <path
          d="M68.4733 8.63541C68.4869 7.42646 69.4842 6.44291 70.6931 6.44974C71.9157 6.45657 72.9403 7.50843 72.8993 8.7242C72.8651 9.91949 71.8269 10.903 70.6385 10.8757C69.4159 10.8416 68.4596 9.85119 68.4733 8.63541Z"
          fill="black"
        />
        <path
          d="M39.4926 28.6754C39.4926 29.9527 39.1443 30.7928 38.4544 31.4827C34.3153 35.6149 30.1762 39.7472 26.0235 43.8727C24.5823 45.307 22.4786 45.3753 21.0511 44.0639C19.5484 42.6842 19.487 40.4166 20.9555 38.9208C22.8201 37.0151 24.7189 35.1368 26.6109 33.2585C28.8853 30.9977 31.1598 28.7506 33.4411 26.5034C34.5476 25.4174 36.0297 25.1306 37.3821 25.7453C38.755 26.3737 39.438 27.4733 39.4858 28.6754H39.4926Z"
          fill="#044EF4"
        />
        <path
          d="M0.000381624 15.65C0.0208723 15.0011 0.362386 14.1815 1.03858 13.5121C5.16402 9.39353 9.2963 5.28174 13.4218 1.16313C14.2824 0.309351 15.2932 -0.134612 16.5022 0.036143C17.9365 0.241049 18.9337 1.04701 19.405 2.41989C19.8695 3.79959 19.5484 5.04269 18.5376 6.07405C16.5636 8.0753 14.5692 10.0561 12.5748 12.0436C10.4096 14.202 8.23761 16.3535 6.05195 18.4914C4.94545 19.5774 3.4633 19.8437 2.10409 19.2495C0.785857 18.6758 -0.020109 17.4054 0.000381624 15.6432V15.65Z"
          fill="#044EF4"
        />
        <path
          d="M2.83495 20.3357C3.30624 20.3152 3.69556 20.322 4.07122 20.2742C5.06843 20.1444 5.91538 19.7141 6.61889 18.9901C7.41119 18.1842 8.2035 17.3782 9.01629 16.5518C9.16656 16.6884 9.26218 16.7635 9.34414 16.8455C12.3972 19.8986 15.4503 22.9448 18.5034 26.0048C19.5075 27.0088 19.8558 28.2178 19.4529 29.5838C19.0635 30.8952 18.1619 31.7353 16.8232 32.0358C15.6689 32.2954 14.5624 32.0768 13.7155 31.2299C10.0955 27.6372 6.50278 24.0172 2.82812 20.3289L2.83495 20.3357Z"
          fill="#F72A72"
        />
        <path
          d="M36.6923 24.7137C34.8277 24.5224 33.5026 25.2396 32.3825 26.4759C31.7746 27.1452 31.112 27.7668 30.4563 28.4225C30.3197 28.2995 30.2241 28.2244 30.1421 28.1424C27.089 25.0893 24.0291 22.0362 20.9828 18.9763C19.9788 17.9722 19.6305 16.7565 20.0471 15.3904C20.4433 14.079 21.3448 13.2457 22.6904 12.952C23.8788 12.6925 24.9785 12.952 25.8459 13.8126C29.3976 17.3302 32.9289 20.8819 36.4669 24.4131C36.5216 24.4678 36.5625 24.5361 36.6992 24.7137H36.6923Z"
          fill="#F72A72"
        />
      </svg>
    )
  },
)
