import type { ControlledTooltipProps } from '@/components/CustomTooltip'
import { ControlledTooltip } from '@/components/CustomTooltip'
import { Button } from '@/components/base/button'
import { Icon } from '@/components/common/icons/Icon'
import { cn } from '@/helpers/utils'
import { useClipboard } from '@/hooks/useClipboard'
import type { ReactElement, ReactNode } from 'react'
import { useCallback, useLayoutEffect, useState, useEffect } from 'react'

const TIMEOUT_CLEAR_MS = 3000 as const

export interface CopyButtonProps
  extends Pick<ControlledTooltipProps, 'withArrow'> {
  children?: (props: { copied: boolean; trigger: () => void }) => ReactElement
  clickedContent: ReactNode
  elementIdToCopy?: string
  elementPropertyToCopy?: string
  initialContent: ReactNode
  textToCopy?: string
}

export const CopyButton = ({
  children,
  clickedContent,
  elementIdToCopy,
  elementPropertyToCopy,
  initialContent,
  textToCopy,
  withArrow = true,
}: CopyButtonProps) => {
  const { copiedValue, clearValue, copyToClipboard } = useClipboard()

  // TODO: move this control logic to inside the ControlledTooltip component?
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const [copyableText, setCopyableText] = useState<string>('')

  const determineCopyableText = useCallback(() => {
    let textForCopying = textToCopy
    if (!textToCopy && elementIdToCopy) {
      const element = document.getElementById(
        elementIdToCopy,
      ) as HTMLAnchorElement | null

      if (!element) {
        console.error('element was not found by id')
        return
      } else if (!elementPropertyToCopy) {
        console.error('elementPropertyToCopy is missing')
        return
      }

      if (elementPropertyToCopy === 'href') {
        // NOTE: Using getAttribute doesn't return the full path when accessing `href`
        // so this conditional is here to circumvent that
        textForCopying = element.href
      } else {
        textForCopying = element.getAttribute(elementPropertyToCopy) || ''
      }
    }

    if (textForCopying) {
      setCopyableText(textForCopying)
    }
  }, [textToCopy, elementIdToCopy, elementPropertyToCopy])

  useEffect(() => {
    determineCopyableText()
  }, [determineCopyableText])

  const closeTooltip = useCallback(() => {
    setShowTooltip(false)
  }, [])

  const openTooltip = useCallback(() => {
    setShowTooltip(true)
  }, [])

  const handleTriggerClick = async () => {
    if (!copyableText) return
    try {
      await copyToClipboard(copyableText)
      openTooltip()
    } catch (error) {
      console.error(error)
    }
  }

  const handleTooltipOpenChange = (open: boolean) => {
    // NOTE: workaround for the fact that clicking the trigger causes the tooltip to open and close
    if (!copiedValue && !showTooltip) {
      // only control the tooltip if the value is not copied
      setShowTooltip(open)
    }
  }

  const handleOnPointerEnter = () => {
    // NOTE: workaround for re-opening when leaving the tooltip and re-entering
    if (copiedValue) {
      openTooltip()
    }
  }

  const handleOnPointerDownOutside = () => {
    // NOTE: workaround for the fact that clicking the trigger causes the tooltip to open and close
    if (!copiedValue && !showTooltip) {
      closeTooltip()
    }
  }

  useLayoutEffect(() => {
    let timeout: number | undefined = undefined
    if (copiedValue) {
      timeout = window.setTimeout(() => {
        clearValue()
        closeTooltip()
      }, TIMEOUT_CLEAR_MS)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [copiedValue, clearValue, closeTooltip])

  const renderButton = children
    ? children({ copied: Boolean(copiedValue), trigger: handleTriggerClick })
    : DefaultCopyButton({
        copied: Boolean(copiedValue),
        trigger: handleTriggerClick,
      })

  return (
    <ControlledTooltip
      onEscapeKeyDown={closeTooltip}
      onOpenChange={handleTooltipOpenChange}
      onPointerDownOutside={handleOnPointerDownOutside}
      onPointerEnter={handleOnPointerEnter}
      onPointerLeave={closeTooltip}
      open={showTooltip}
      trigger={renderButton}
      withArrow={withArrow}
    >
      {copiedValue === copyableText ? clickedContent : initialContent}
    </ControlledTooltip>
  )
}

const DefaultCopyButton = (props: { copied: boolean; trigger: () => void }) => {
  return (
    <Button
      className={cn(props.copied && 'gradient border-none')}
      onClick={props.trigger}
      variant="icon-animated"
      size="icon"
    >
      {props.copied ? (
        <>
          <span className="sr-only">Copied</span>
          <Icon size="xl" tag="check" variant="neutral" />
        </>
      ) : (
        <>
          <span className="sr-only">Copy to clipboard</span>
          <Icon size="xl" tag="copy-content" />
        </>
      )}
    </Button>
  )
}
