import React from 'react'
import { ENV_VARS } from '@/helpers/constants'

export const TanStackRouterDevtools = ENV_VARS.IS_DEVELOPMENT
  ? React.lazy(() =>
      // Lazy load in development
      import('@tanstack/router-devtools').then(res => ({
        default: res.TanStackRouterDevtools,
        // For Embedded Mode
        // default: res.TanStackRouterDevtoolsPanel
      })),
    )
  : () => null // Render nothing in production
