import { LOADING_STATE } from '@/helpers/constants'
import type {
  ChatMessage,
  ChatRecord,
  GetChatCursor,
  LoadingState,
  Resource,
} from '@/helpers/types'
import { dedupePrimitiveArray, sortBy, userToColor } from '@/helpers/utils'
import { useMainStore } from '@/stores/main'
import type {
  ConversationChatMessage,
  ConversationChatMessagesSubscribeParams,
  ConversationChatMessagesSubscribeResult,
  ConversationSubscribeResult,
  GetConversationChatMessageParams,
  GetConversationChatMessageResult,
  GetConversationMessagesParams,
  SendConversationMessageParams,
  SendConversationMessageResult,
} from '@signalwire/js'
import { create, type StateCreator } from 'zustand'

const CHATS_PER_PAGE = 15 as const

interface UserMetaDataRecord {
  color: string
  id: string
  initial: string
  name: string
}

type AddressId = Resource['id']
interface PaginationParams {
  addressId: AddressId
  nextPage: GetConversationChatMessageResult['nextPage'] | undefined
  prevPage: GetConversationChatMessageResult['prevPage'] | undefined
}

interface UpdateChatStoreFromResponseParams {
  addressId: AddressId
  conversationChatMessages: ConversationChatMessage[]
  nextPage: GetConversationChatMessageResult['nextPage'] | undefined
  prevPage: GetConversationChatMessageResult['prevPage'] | undefined
}

interface Actions {
  actions: {
    _addChatIdsToAddressMap: (
      addressId: AddressId,
      chatIds: ChatRecord['id'][],
    ) => void
    _addChatMessagesToChatMessageMap: (chatMessages: ChatRecord[]) => void
    _addNewUserMetaDataToMap: (chatMessages: ChatRecord[]) => void
    _addSubscriptionToMap: (
      addressId: AddressId,
      subscription: ConversationSubscribeResult,
    ) => void
    _fetchAndUpdateChatMessages: (params: {
      addressId: AddressId
      getChatCursor: GetChatCursor
    }) => Promise<void>
    _setPaginationCursors: (paginationParams: PaginationParams) => void
    _updateChatStoreFromResponse: (
      params: UpdateChatStoreFromResponseParams,
    ) => void
    getChatMessages: (params: GetConversationChatMessageParams) => Promise<void>
    getNextChatPage: (params: { addressId: AddressId }) => Promise<void>
    getPrevChatPage: (params: { addressId: AddressId }) => Promise<void>
    getSortedChatMessages: (params: {
      addressId: AddressId
      chatMessageMap: ChatMessageMap
    }) => ChatMessage[]
    joinChatConversation: (params: { addressId: AddressId }) => Promise<void>
    sendChatMessage: (
      params: SendConversationMessageParams,
    ) => Promise<SendConversationMessageResult>
    subscribeToChatMessages: (
      params: ConversationChatMessagesSubscribeParams,
    ) => Promise<ConversationChatMessagesSubscribeResult>
    unsubscribeChatMessages: (addressId: AddressId) => void
  }
}

type ResourceChatIdMap = Map<AddressId, ChatRecord['id'][]>
type ChatMessageMap = Map<ChatRecord['id'], ChatRecord>
type CursorMap = Map<
  AddressId,
  {
    nextPage: GetConversationChatMessageResult['nextPage'] | undefined
    prevPage: GetConversationChatMessageResult['prevPage'] | undefined
  }
>
type UserMetaDataMap = Map<AddressId, UserMetaDataRecord>

// TODO: Include user meta-data for the UI as the message list is initialized and updated
// Colors should be assigned to users from a color list provided by the designer
// The color assignments (and possibly all meta-data) should persist after a refresh
interface State {
  addressIdChatIdMap: ResourceChatIdMap
  chatHasNextPage: boolean
  chatMessageMap: ChatMessageMap
  cursorMap: CursorMap
  loadingState: LoadingState
  subscriptionsMap: Map<AddressId, ConversationSubscribeResult>
  userMetaDataMap: UserMetaDataMap
}

type Store = Actions & State

const initialState: State = {
  addressIdChatIdMap: new Map(),
  chatHasNextPage: false,
  chatMessageMap: new Map(),
  cursorMap: new Map(),
  loadingState: LOADING_STATE.IDLE,
  subscriptionsMap: new Map(),
  userMetaDataMap: new Map(),
}

export const mapChatMessageToRecord = (
  conversationChatMessage: ConversationChatMessage,
) => {
  // use camelCase for keys
  return {
    addressId: conversationChatMessage.address_id,
    conversationId: conversationChatMessage.conversation_id,
    details: conversationChatMessage.details,
    fromAddressId: conversationChatMessage.from_address_id,
    id: conversationChatMessage.id,
    subtype: conversationChatMessage.subtype,
    text: conversationChatMessage.text,
    ts: conversationChatMessage.ts,
    type: conversationChatMessage.type,
    userId: conversationChatMessage.user_id,
    userName: (conversationChatMessage.user_name as string) ?? '',
  } satisfies ChatRecord
}

const stateCreatorFn: StateCreator<Store> = (set, get) => ({
  ...initialState,
  actions: {
    _addChatIdsToAddressMap: (addressId, chatIds) => {
      const { addressIdChatIdMap } = get()

      const map = new Map(addressIdChatIdMap)

      const currentChatIds = map.get(addressId) ?? []

      // remove duplicates and add new chat ids
      const mergedChatIds = dedupePrimitiveArray([
        ...currentChatIds,
        ...chatIds,
      ])

      // clone the map and
      const updatedMap = new Map(map)

      // update the chat ids for the address
      updatedMap.set(addressId, mergedChatIds)

      set({ addressIdChatIdMap: updatedMap })
    },
    _addChatMessagesToChatMessageMap: chatMessages => {
      const { chatMessageMap } = get()

      // clone the map
      const updatedMap = new Map(chatMessageMap)

      // update the chat message map with new chat messages
      chatMessages.forEach(chatMessage => {
        updatedMap.set(chatMessage.id, chatMessage)
      })
      set({ chatMessageMap: updatedMap })
    },
    _addNewUserMetaDataToMap: chatMessages => {
      const { userMetaDataMap } = get()
      const currentUserId = useMainStore.getState().memberId

      const userRecord = chatMessages.map(chatMessage => {
        const { userId, userName } = chatMessage
        return {
          id: userId,
          initial: userName.trim().charAt(0).toUpperCase() || 'U',
          name: userName.trim() || 'Unknown',
        }
      })

      // map only the new or updated user meta-data
      const newRecords = userRecord
        .map(userRecord => {
          const userMetaData = userMetaDataMap.get(userRecord.id)

          const isCurrentUser = userRecord.id === currentUserId
          const getColor = isCurrentUser
            ? userToColor.getCurrentUserColor
            : () => userToColor.getUserColor(userRecord.id)

          if (!userMetaData) {
            // new user , create a new record
            return {
              ...userRecord,
              color: getColor(),
            }
          }
          // compare with existing user meta-data
          if (
            userRecord.initial !== userMetaData.initial ||
            userRecord.name !== userMetaData.name
          ) {
            // get a color to assign to the user if not already assigned
            const userColor = userMetaData.color || getColor()
            return {
              ...userRecord,
              color: userColor,
            }
          } else {
            return
          }
        })
        .filter(v => v) as UserMetaDataRecord[]

      // if no new records, no need to update
      if (newRecords.length === 0) {
        return
      }

      // clone and apply the new user meta-data
      const updatedMap = new Map(userMetaDataMap)

      newRecords.forEach(userRecord => {
        updatedMap.set(userRecord.id, userRecord)
      })

      set({ userMetaDataMap: updatedMap })
    },
    _addSubscriptionToMap: (addressId, subscription) => {
      const { subscriptionsMap } = get()
      const updatedMap = new Map(subscriptionsMap)

      // if current subscription exists, unsubscribe first
      const currentSubscription = updatedMap.get(addressId)
      if (currentSubscription) {
        currentSubscription.unsubscribe()
      }
      // add the new subscription
      updatedMap.set(addressId, subscription)
      set({ subscriptionsMap: updatedMap })
    },
    _fetchAndUpdateChatMessages: async ({ addressId, getChatCursor }) => {
      const { _updateChatStoreFromResponse } = get().actions
      set({ loadingState: LOADING_STATE.LOADING })

      try {
        // fetch chat messages
        const getMessagesResponse = await getChatCursor()
        console.log('XXXX fetch chat messages response', getMessagesResponse)
        if (!getMessagesResponse) {
          set({ loadingState: LOADING_STATE.ERROR })
          return
        }

        // eslint-disable-next-line @typescript-eslint/unbound-method
        const { data, hasNext, hasPrev, nextPage, prevPage } =
          getMessagesResponse

        _updateChatStoreFromResponse({
          addressId: addressId,
          conversationChatMessages: data,
          nextPage: hasNext ? nextPage : undefined,
          prevPage: hasPrev ? prevPage : undefined,
        })
        set({ loadingState: LOADING_STATE.IDLE })
      } catch (error) {
        set({ loadingState: LOADING_STATE.ERROR })
        console.error('Error fetching chat cursor', error)
        // rethrow
        throw error
      }
    },
    _setPaginationCursors: ({ addressId, nextPage, prevPage }) => {
      const { cursorMap } = get()
      const updatedMap = new Map(cursorMap)

      updatedMap.set(addressId, { nextPage, prevPage })
      set({ cursorMap: updatedMap })
    },
    _updateChatStoreFromResponse: ({
      addressId,
      conversationChatMessages,
      nextPage,
      prevPage,
    }) => {
      const {
        _addChatIdsToAddressMap,
        _addChatMessagesToChatMessageMap,
        _addNewUserMetaDataToMap,
        _setPaginationCursors,
      } = get().actions

      // map chat messages
      const chatMessages = conversationChatMessages.map(mapChatMessageToRecord)

      // add chat ids to address map for given address id (if provided)
      if (addressId && Array.isArray(chatMessages)) {
        _addChatIdsToAddressMap(
          addressId,
          chatMessages.map(chatMessage => chatMessage.id),
        )
      }

      // add new chat messages to chat message map
      _addChatMessagesToChatMessageMap(chatMessages)

      // update cursors (if provided)
      if (addressId && (nextPage || prevPage)) {
        // update hasNext flag
        set({ chatHasNextPage: Boolean(nextPage) })
        _setPaginationCursors({ addressId, nextPage, prevPage })
      }

      // update user meta-data map
      _addNewUserMetaDataToMap(chatMessages)
    },

    getChatMessages: async ({ addressId, pageSize = CHATS_PER_PAGE }) => {
      const { client } = useMainStore.getState()
      if (!client) {
        throw new Error('Client not initialized')
      }

      const { loadingState } = get()
      const { _fetchAndUpdateChatMessages } = get().actions

      // reset cursors, and hasNextPage
      set({
        chatHasNextPage: false,
        cursorMap: new Map(),
      })

      // address id is required
      const getOptions: GetConversationMessagesParams = {
        addressId,
      }

      // optional fields
      if (pageSize) {
        getOptions.pageSize = pageSize
      }

      if (loadingState !== LOADING_STATE.LOADING) {
        const getChatMessagesFromClient = async () =>
          await client.chat.getMessages(getOptions)

        await _fetchAndUpdateChatMessages({
          addressId: addressId,
          getChatCursor: getChatMessagesFromClient,
        })
      }
    },
    getNextChatPage: async ({ addressId }) => {
      const { actions, cursorMap, loadingState } = get()
      const { _fetchAndUpdateChatMessages } = actions

      // get the next page cursor
      const nextPage = cursorMap.get(addressId)?.nextPage
      if (!addressId || !nextPage) {
        return
      }

      if (loadingState !== LOADING_STATE.LOADING) {
        await _fetchAndUpdateChatMessages({
          addressId: addressId,
          getChatCursor: nextPage,
        })
      }
    },
    getPrevChatPage: async ({ addressId }) => {
      const { actions, cursorMap, loadingState } = get()
      const { _fetchAndUpdateChatMessages } = actions

      // get the previous page cursor
      const prevPage = cursorMap.get(addressId)?.prevPage
      if (!addressId || !prevPage) {
        return
      }

      if (loadingState !== LOADING_STATE.LOADING) {
        await _fetchAndUpdateChatMessages({
          addressId: addressId,
          getChatCursor: prevPage,
        })
      }
    },
    getSortedChatMessages: ({ addressId, chatMessageMap }) => {
      const { addressIdChatIdMap } = get()

      const chatIds = addressIdChatIdMap.get(addressId ?? '')

      if (!chatIds) {
        return []
      }

      const chatMessages = chatIds
        .map(chatId => chatMessageMap.get(chatId))
        // FIXME: In TS version >5.5 filtering is automatically inferred, remove casting
        .filter(v => v) as ChatMessage[]

      // sort by timestamp
      const sortedChatMessagesAsc = sortBy(chatMessages, 'asc', 'ts')

      return sortedChatMessagesAsc
    },
    joinChatConversation: async ({ addressId }) => {
      const { client } = useMainStore.getState()
      if (!client) {
        throw new Error('Client not initialized')
      }
      await client.chat.join({ addressId })
    },
    sendChatMessage: async ({ addressId, details, metadata, text }) => {
      const { client } = useMainStore.getState()
      if (!client) {
        throw new Error('Client not initialized')
      }
      const messageToSend: SendConversationMessageParams = {
        addressId,
        text,
      }

      // optional fields
      if (details) {
        messageToSend.details ??= details
      }
      if (metadata) {
        messageToSend.metadata ??= metadata
      }

      const sendMessageResponse = await client.chat.sendMessage(messageToSend)

      console.log('XXXX send message response', sendMessageResponse)
      // TODO: update the store with the response?

      return sendMessageResponse
    },
    // FIXME: onMessage callback param should be optional
    subscribeToChatMessages: async ({ addressId, onMessage }) => {
      const { client } = useMainStore.getState()
      if (!client) {
        throw new Error('Client not initialized')
      }

      // Join the Chat Conversation
      // FIXME: Do we need to call join each time we subscribe?
      const { joinChatConversation, _addSubscriptionToMap } = get().actions
      await joinChatConversation({ addressId })

      // Subscribe to incoming Chat messages
      const unsubscribeToChatMessages = await client.chat.subscribe({
        addressId,
        onMessage: event => {
          const { _updateChatStoreFromResponse } = get().actions
          console.log('XXXX onMessage event', event)

          const newChatRecord = {
            ...event,
            text: event.text ?? '',
          } satisfies ConversationChatMessage

          _updateChatStoreFromResponse({
            addressId,
            conversationChatMessages: [newChatRecord],
            nextPage: undefined,
            prevPage: undefined,
          })

          // call the onMessage callback
          onMessage(event)
        },
      })

      // add the subscription to the map
      _addSubscriptionToMap(addressId, unsubscribeToChatMessages)

      // return the unsubscribe function
      return unsubscribeToChatMessages
    },
    unsubscribeChatMessages: addressId => {
      const { subscriptionsMap } = get()
      const subscription = subscriptionsMap.get(addressId)
      if (!subscription) {
        // no-op if subscription does not exist
        return
      }
      // unsubscribe
      subscription.unsubscribe()
      console.log(
        'XXXX: Unsubscribed from chat messages with addressId',
        addressId,
      )
      // remove the subscription and update the map state
      const updatedMap = new Map(subscriptionsMap)
      updatedMap.delete(addressId)

      // update the store
      set({ subscriptionsMap: updatedMap })
    },
  },
})

export const useChatStore = create<Store>()(stateCreatorFn)
export const useChatStoreActions = () => useChatStore.getState().actions

export type { Store as ChatStoreState }

// Expose the store to be used from the console
window.__chatStore = useChatStore
