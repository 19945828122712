import { ButtonDnD } from '@/components/ButtonDnD'
import { ButtonInCallOptions } from '@/components/ButtonInCallOptions'
import { ButtonMicrophone } from '@/components/ButtonMicrophone'
import { ButtonRaiseHand } from '@/components/ButtonRaiseHand'
import { ButtonShareScreen } from '@/components/ButtonShareScreen'
import { ButtonVideo } from '@/components/ButtonVideo'
import { DialogInCallOptions } from '@/components/DialogInCallOptions'
import { LinkLeave } from '@/components/LinkLeave'
import type { RedirectPath } from '@/helpers/types'
import { useDNDButtonState } from '@/hooks/useDNDButtonState'
import { useMicrophoneButtonState } from '@/hooks/useMicrophoneButtonState'
import { useHandRaiseButtonState } from '@/hooks/useRaiseHandButtonState'
import { useScreenShareButtonState } from '@/hooks/useScreenShareButtonState'
import { useVideoButtonState } from '@/hooks/useVideoButtonState'
import { useRoomStore } from '@/stores/room'

interface RoomFooterInlineProps {
  redirect: RedirectPath | undefined
  totalFooterButtonCount: number
}

export const RoomFooterInline = ({
  redirect,
  totalFooterButtonCount,
}: RoomFooterInlineProps) => {
  const memberState = useRoomStore(state => state.memberState)

  const { isDNDButtonEnabled, isDNDButtonVisible } = useDNDButtonState()

  const { isRaiseHandButtonEnabled, isRaiseHandButtonVisible } =
    useHandRaiseButtonState()

  const { isMicrophoneButtonVisible, isMicrophoneEnabled } =
    useMicrophoneButtonState()

  const { isScreenShareButtonEnabled, isScreenShareButtonVisible } =
    useScreenShareButtonState()

  const { isVideoButtonEnabled, isVideoButtonVisible } = useVideoButtonState()

  const memberJoined = memberState === 'joined'

  /* eslint-disable sort-keys */
  const showButtonsByPriorityMap = {
    shareScreen: isScreenShareButtonVisible && totalFooterButtonCount >= 5,
    raiseHand: isRaiseHandButtonVisible && totalFooterButtonCount >= 4,
    dnd: isDNDButtonVisible && totalFooterButtonCount >= 3,
    video: isVideoButtonVisible && totalFooterButtonCount >= 2,
    microphone: isMicrophoneButtonVisible && totalFooterButtonCount >= 1,
  } as const
  /* eslint-enable sort-keys */

  // TODO: use css container query to show options button
  return (
    <div
      aria-label="In call controls"
      className="flex items-center justify-end gap-x-2"
      role="group"
    >
      <DialogInCallOptions
        // NOTE: show a button in dialog if it is not visible in the footer
        showDND={!showButtonsByPriorityMap.dnd}
        showMicrophone={!showButtonsByPriorityMap.microphone}
        showRaiseHand={!showButtonsByPriorityMap.raiseHand}
        showScreenShare={!showButtonsByPriorityMap.shareScreen}
        showVideo={!showButtonsByPriorityMap.video}
      >
        <ButtonInCallOptions disabled={!memberJoined} />
      </DialogInCallOptions>

      <ButtonShareScreen
        disabled={!memberJoined || !isScreenShareButtonEnabled}
        isVisible={showButtonsByPriorityMap.shareScreen}
      />
      <ButtonRaiseHand
        disabled={!memberJoined || !isRaiseHandButtonEnabled}
        isVisible={showButtonsByPriorityMap.raiseHand}
      />
      <ButtonDnD
        disabled={true || !isDNDButtonEnabled}
        isVisible={showButtonsByPriorityMap.dnd}
      />
      <ButtonVideo
        disabled={!memberJoined || !isVideoButtonEnabled}
        isVisible={showButtonsByPriorityMap.video}
      />
      <ButtonMicrophone
        disabled={!memberJoined || !isMicrophoneEnabled}
        isVisible={showButtonsByPriorityMap.microphone}
      />
      <LinkLeave to={redirect}>Leave</LinkLeave>
    </div>
  )
}
