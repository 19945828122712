import * as React from 'react'

import { cn } from '@/helpers/utils'

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        className={cn(
          // Match <Textarea />
          'rounded-[1.75rem] border-2 border-input bg-background py-2 text-base ring-offset-background transition-colors placeholder:mr-5 placeholder:text-muted-foreground',
          'focus-visible:border-transparent focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-negative-light focus-visible:ring-offset-0',
          'disabled:cursor-not-allowed disabled:opacity-50',
          type === 'search' &&
            '[&::-webkit-search-cancel-button]:hidden [&::-webkit-search-decoration]:hidden [&::-webkit-search-results-button]:hidden [&::-webkit-search-results-decoration]:hidden',
          className,
        )}
        ref={ref}
        type={type}
        {...props}
      />
    )
  },
)
Input.displayName = 'Input'

export { Input }
