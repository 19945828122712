import { useFeaturesStoreActions, type FeatureName } from '@/stores/features'
import type { PropsWithChildren } from 'react'

type FeatureProps = PropsWithChildren<{ name: FeatureName }>

export const Feature = ({ children, name }: FeatureProps) => {
  const { getFeatureFlagIsDisabled } = useFeaturesStoreActions()

  const isDisabled = getFeatureFlagIsDisabled(name)

  if (isDisabled) return

  return children
}
