import { create, type StateCreator } from 'zustand'
import { ENV_VARS } from '@/helpers/constants'
const { VITE_DISABLED_FEATURES } = ENV_VARS

export const FEATURES = {
  ADD_ROOM: 'ADD_ROOM',
  CALLS_ROUTE: 'CALLS_ROUTE',
  CHANGE_ROOMS: 'CHANGE_ROOMS',
  CHATS_ROUTE: 'CHATS_ROUTE',
  CREATE_SIDEBAR: 'CREATE_SIDEBAR',
  DEVELOPER_TOASTER_DIALOG: 'DEVELOPER_TOASTER_DIALOG', // Can be removed, for demonstrating toasts
  DEVELOPER_TOOLS: 'DEVELOPER_TOOLS',
  ERROR_DIALOG: 'ERROR_DIALOG',
  GLOBAL_ERROR_LISTENERS: 'GLOBAL_ERROR_LISTENERS',
  GLOBAL_TOASTER: 'GLOBAL_TOASTER',
  PROFILE_SETTINGS: 'PROFILE_SETTINGS',
  RECENT_CONTACTS_MESSAGE_OPTIONS: 'RECENT_CONTACTS_MESSAGE_OPTIONS',
  RECENT_MORE_OPTIONS: 'RECENT_MORE_OPTIONS',
  ROOMS_MORE_OPTIONS: 'ROOMS_MORE_OPTIONS',
  ROOMS_SORT: 'ROOMS_SORT',
  ROOM_CHAT: 'ROOM_CHAT',
  ROOM_DO_NOT_DISTURB: 'ROOM_DO_NOT_DISTURB',
  ROOM_LAYOUT: 'ROOM_LAYOUT',
  ROOM_LOADING_UI: 'ROOM_LOADING_UI',
  ROOM_LOCK: 'ROOM_LOCK',
  ROOM_RAISE_HAND: 'ROOM_RAISE_HAND',
  ROOM_SHARE_SCREEN: 'ROOM_SHARE_SCREEN',
  SENTRY: 'SENTRY',
  USER_VARIABLES: 'USER_VARIABLES',
} as const

export type FeatureName = keyof typeof FEATURES

const initialDisabledEnvFeatures = (): Record<string, FeatureName> => {
  const disabledEnvFeatures = VITE_DISABLED_FEATURES?.split(',')
  const disabledFeatures: Record<string, FeatureName> = {}
  disabledEnvFeatures.forEach(feat => {
    const feature = feat.trim() as FeatureName
    disabledFeatures[feature] = feature
  })
  return disabledFeatures
}

interface Actions {
  actions: {
    getFeatureFlagIsDisabled: (featureName: FeatureName) => boolean
  }
}

interface State {
  disabled: Record<string, FeatureName>
}

const initialState: State = {
  disabled: initialDisabledEnvFeatures(),
}

type Store = Actions & State

const stateCreatorFn: StateCreator<Store> = (_set, get) => ({
  ...initialState,
  actions: {
    getFeatureFlagIsDisabled: (featureName: FeatureName): boolean => {
      return get().disabled[featureName] === FEATURES[featureName]
    },
  },
})

export const useFeaturesStore = create<Store>()(stateCreatorFn)
export const useFeaturesStoreActions = () => useFeaturesStore.getState().actions

// Expose the store to be used from the console
window.__featuresStore = useFeaturesStore

// for convenience to use when Feature is not a component
export const isFeatureDisabled = (featureName: FeatureName) =>
  useFeaturesStore.getState().actions.getFeatureFlagIsDisabled(featureName)

// for convenience to use when Feature is not a component and avoiding double negation
export const isFeatureEnabled = (featureName: FeatureName) =>
  !useFeaturesStore.getState().actions.getFeatureFlagIsDisabled(featureName)
