import { PageTitle } from '@/components/PageTitle'
import { TanStackRouterDevtools } from '@/components/TanStackRouterDevTools.lazy'
import { Outlet, createRootRouteWithContext } from '@tanstack/react-router'

export const Route = createRootRouteWithContext<{
  title: string
}>()({
  component: () => {
    console.log('XXXX: Rendering _root route')
    return (
      <>
        <PageTitle />
        <Outlet />
        <TanStackRouterDevtools />
      </>
    )
  },
})
