import { useMatches } from '@tanstack/react-router'

const createTitle = (breadcrumbs: string[]) => {
  const defaultTitle = 'PUC Web App | SignalWire' as const
  return breadcrumbs.length
    ? `${breadcrumbs.join(' ')} - ${defaultTitle}`
    : defaultTitle
}

/**
 * Updates the document title based on the current route
 * - does not render anything
 * - should be placed at the top of the route component tree
 */
// TODO: Eventually the router should have first-class support for updating the document title
export const PageTitle = () => {
  // Get titles from all matching routes
  const breadcrumbs = useMatches()
    .map(match => {
      return typeof match.context?.title === 'string' ? match.context.title : ''
    })
    .filter(Boolean)

  // update the document title
  document.title = createTitle(breadcrumbs)

  // no need to render anything
  return null
}
