import { create, type StateCreator } from 'zustand'
import { useMainStore } from '@/stores/main'
import { useDevicesStore } from '@/stores/devices'
import UAParser from 'ua-parser-js'

// Types
import type { UAParserInstance, IResult } from 'ua-parser-js'
import type { ChannelType } from '@/helpers/types'
import { CHANNEL_TYPE } from '@/helpers/constants'

interface GetUserVariablesParams {
  channel?: ChannelType | undefined
}

interface Actions {
  actions: {
    getUserVariables: (params?: GetUserVariablesParams) => Promise<State>
    refreshCameraLabel: () => Promise<void>
    refreshMicrophoneLabel: () => Promise<void>
    refreshSubscriberState: () => Promise<void>
    refreshUserDeviceState: () => void
  }
}

interface State {
  cameraLabel: string
  company: string
  email: string
  fullBrowserVersion: string
  gmtOffset: number
  hostname: string
  isAndroid: boolean
  isChrome: boolean
  isChromium: boolean
  isEdge: boolean
  isFirefox: boolean
  isIE: boolean
  isIOS: boolean
  isMobile: boolean
  isOpera: boolean
  isSafari: boolean
  isTablet: boolean
  isWinPhone: boolean
  isYandex: boolean
  microphoneLabel: string
  name: string
  osName: string
  osVersion: string
  timezone: string
  tzString: string
  userAgent: string
}

type Store = Actions & State

const initialState: State = {
  cameraLabel: '',
  company: '',
  email: '',
  fullBrowserVersion: '',
  gmtOffset: 0,
  hostname: window.location.hostname,
  isAndroid: false,
  isChrome: false,
  isChromium: false,
  isEdge: false,
  isFirefox: false,
  isIE: false,
  isIOS: false,
  isMobile: false,
  isOpera: false,
  isSafari: false,
  isTablet: false,
  isWinPhone: false,
  isYandex: false,
  microphoneLabel: '',
  name: '',
  osName: '',
  osVersion: '',
  timezone: '',
  tzString: '',
  userAgent: window.navigator.userAgent,
}

const stateCreatorFn: StateCreator<Store> = (set, get) => ({
  ...initialState,
  actions: {
    getUserVariables: async ({ channel } = {}) => {
      const {
        refreshUserDeviceState,
        refreshSubscriberState,
        refreshCameraLabel,
        refreshMicrophoneLabel,
      } = get().actions

      refreshUserDeviceState()
      await refreshSubscriberState()

      if (channel !== CHANNEL_TYPE.AUDIO) {
        await refreshCameraLabel()
      }
      await refreshMicrophoneLabel()

      const variables = {
        cameraLabel: get().cameraLabel,
        company: get().company,
        email: get().email,
        fullBrowserVersion: get().fullBrowserVersion,
        gmtOffset: get().gmtOffset,
        hostname: get().hostname,
        isAndroid: get().isAndroid,
        isChrome: get().isChrome,
        isChromium: get().isChromium,
        isEdge: get().isEdge,
        isFirefox: get().isFirefox,
        isIE: get().isIE,
        isIOS: get().isIOS,
        isMobile: get().isMobile,
        isOpera: get().isOpera,
        isSafari: get().isSafari,
        isTablet: get().isTablet,
        isWinPhone: get().isWinPhone,
        isYandex: get().isYandex,
        microphoneLabel: get().microphoneLabel,
        name: get().name,
        osName: get().osName,
        osVersion: get().osVersion,
        timezone: get().timezone,
        tzString: get().tzString,
        userAgent: get().userAgent,
      }
      return variables
    },
    refreshCameraLabel: async () => {
      const { getSelectedCamera } = useDevicesStore.getState().actions
      const cameraLabel: string = (await getSelectedCamera())?.label || ''
      set({ cameraLabel })
    },
    refreshMicrophoneLabel: async () => {
      const { getSelectedMicrophone } = useDevicesStore.getState().actions
      const microphoneLabel: string =
        (await getSelectedMicrophone())?.label || ''
      set({ microphoneLabel })
    },
    refreshSubscriberState: async (): Promise<void> => {
      const { client } = useMainStore.getState()
      const subscriber = await client?.getSubscriberInfo()
      if (subscriber) {
        set({
          company: subscriber.company_name || '',
          email: subscriber.email || '',
          name: `${subscriber.first_name} ${subscriber.last_name}`,
        })
      }
    },
    refreshUserDeviceState: () => {
      const parser: UAParserInstance = new UAParser(window.navigator.userAgent)
      const {
        ua: userAgent,
        browser: { name: browserName, version: browserVersion },
        os: { name: osName, version: osVersion },
        device: { type: deviceType },
      }: IResult = parser.getResult()

      set({
        fullBrowserVersion: browserVersion || '',
        gmtOffset: (-1.0 * new Date().getTimezoneOffset()) / 60,
        hostname: window.location.hostname,
        isAndroid: osName === 'Android',
        isChrome: browserName === 'Chrome',
        isChromium: browserName === 'Chromium',
        isEdge: browserName === 'Edge',
        isFirefox: browserName === 'Firefox',
        isIE: browserName === 'IE',
        isIOS: osName === 'iOS',
        isMobile: deviceType === 'mobile',
        isOpera: browserName === 'Opera',
        isSafari: browserName === 'Safari',
        isTablet: deviceType === 'tablet',
        isWinPhone: osName === 'Windows Mobile',
        isYandex: browserName === 'Yandex',
        osName: osName || '',
        osVersion: osVersion || '',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        tzString: new Date().toTimeString().split(' ').splice(1).join(' '),
        userAgent: userAgent,
      })
    },
  },
})

export const useUserStore = create<Store>()(stateCreatorFn)
export const useUserStoreActions = () => useUserStore.getState().actions

export type { Store as UserStoreState }

// Expose the store to be used from the console
window.__userStore = useUserStore
