import { buildRequestInit, buildUrlWithParams } from '@/http/httpUtils'
import { AuthError, HttpError } from './errors'

const noop = () => undefined

interface InternalHttpResponse<T> extends Response {
  parsedBody?: T
}

async function http<T>(
  input: string,
  init: RequestInit | undefined,
): Promise<InternalHttpResponse<T>> {
  const response: InternalHttpResponse<T> = await fetch(input, init)

  if (!response.ok) {
    if (response.status === 401) {
      throw new AuthError(response.status, 'Unauthorized')
    }

    let errorResponse: Record<string, unknown> | undefined
    try {
      errorResponse = (await response.json()) as Record<string, unknown>
    } catch (_error) {
      noop()
    }

    const errorMessage = errorResponse?.['errors']
      ? JSON.stringify(errorResponse['errors'])
      : `Error Code: ${response.status}`

    throw new HttpError(response.status, errorMessage, errorResponse)
  }

  try {
    // might throw if body is empty
    response.parsedBody ??= await response.json()
  } catch (_error) {
    noop()
  }

  return response
}

export interface CreateHttpClientParams extends RequestInit {
  // Request timeout in ms
  timeout?: number
}

interface HttpClientRequestInit extends Omit<RequestInit, 'body'> {
  body?: Record<string, unknown>
  searchParams?: Record<string, string>
}

export const createHttpClient = (
  { timeout = 30000, ...globalOptions }: CreateHttpClientParams,
  fetcher = http,
) => {
  const apiClient = async <T>(
    url: string,
    options?: HttpClientRequestInit,
  ): Promise<{ body: T; status: Response['status'] }> => {
    const headers = {
      ...(options?.body ? { 'Content-Type': 'application/json' } : {}),
      ...globalOptions.headers,
      ...options?.headers,
    } satisfies HeadersInit

    const reqInit = buildRequestInit({
      ...globalOptions,
      ...(options?.body && { body: JSON.stringify(options.body) }),
      ...(options?.method && { method: options.method ?? 'GET' }),
      headers,
    })
    // reqInit.mode = 'no-cors'
    reqInit.credentials = 'same-origin'

    let timerId
    if (timeout) {
      const controller = new AbortController()
      const signal = controller.signal

      reqInit.signal = signal

      timerId = setTimeout(() => {
        controller.abort()
      }, timeout)
    }

    try {
      const response = await fetcher<T>(
        buildUrlWithParams({
          searchParams: options?.searchParams,
          url,
        }),
        reqInit,
      )

      return { body: response.parsedBody as T, status: response.status }
    } finally {
      if (timerId) {
        clearTimeout(timerId)
      }
    }
  }

  return apiClient
}
