import * as React from 'react'
import { cn, isTextAreaOverflowing } from '@/helpers/utils'

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    const [isOverflowing, setIsOverflowing] = React.useState<boolean>(false)
    const textAreaRef = React.useRef<HTMLTextAreaElement | null>(null)

    React.useEffect(() => {
      if (!textAreaRef.current) return

      const resizeObserver = new ResizeObserver(() => {
        // Check for overflow
        setIsOverflowing(isTextAreaOverflowing(textAreaRef.current))
      })
      resizeObserver.observe(textAreaRef.current)
      return () => resizeObserver.disconnect()
    }, [])

    return (
      <textarea
        className={cn(
          // Match <Input />
          'rounded-[1.75rem] border-2 border-input bg-background text-base ring-offset-background transition-colors placeholder:mr-5 placeholder:text-muted-foreground',
          'focus-visible:border-transparent focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-negative-light focus-visible:ring-offset-0',
          'disabled:cursor-not-allowed disabled:opacity-50',
          isOverflowing && 'rounded-r-none',
          className,
        )}
        ref={element => {
          // ref setup needed for react-hook-form
          // @ts-expect-error Possible non-callable ref
          ref(element)
          textAreaRef.current = element
        }}
        {...props}
      />
    )
  },
)
Textarea.displayName = 'Textarea'

export { Textarea }
