import { useState, useEffect } from 'react'

export const useIsVisible = <T extends HTMLElement>(
  targetRef: React.RefObject<T>,
  options?: IntersectionObserverInit,
) => {
  // NOTE: It's important to remember that the
  // targetRef is the observable element in the
  // context of the options.root element, otherwise
  // this will not work as expected
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (!entry) return
      const { isIntersecting } = entry
      setIsVisible(isIntersecting)
    }, options)

    const targetElement = targetRef.current

    if (targetElement) {
      observer.observe(targetElement)
    }

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement)
      }
    }
  }, [options, targetRef])

  return isVisible
}
