// Store for conversations (Recent/History)
import type { Conversation, Message } from '@/helpers/types'
import { useResourcesStore } from '@/stores/resources'
import type { ConversationMessage, ConversationContract } from '@signalwire/js'
import { create, type StateCreator } from 'zustand'
import { useMainStore } from './main'

interface Actions {
  actions: {
    fetchConversations: () => Promise<Conversation[]>
    fetchMessagesByResourceId: (resourceId: string) => Promise<void>
    fetchRecents: () => Promise<void>
    getMessageByResourceId: (resourceId: string) => Message[] | undefined
  }
}

interface State {
  // nextPage: Promise<FetchAddresses> | Promise<void>
  // prevPage: Promise<FetchAddresses> | Promise<void>
  conversations: Conversation[]
  messagesByResource: Map<string, Message[]>
}

type Store = Actions & State

const initialState: State = {
  // nextPage: Promise.resolve(),
  // prevPage: Promise.resolve(),
  conversations: [],
  messagesByResource: new Map(),
}

const mapConversationsToResources = (
  conversations: ConversationContract[],
): Conversation[] => {
  if (!conversations) return []
  return conversations.map(conversation => {
    return {
      addressId: conversation.addressId,
      createdAt: conversation.createdAt,
      id: conversation.id,
      lastMessageAt: conversation.lastMessageAt,
      metadata: conversation.metadata,
      name: conversation.name,
    } satisfies Conversation
  })
}

const mapConversationMessage = (
  conversationMessage: ConversationMessage[],
): Message[] => {
  return conversationMessage.map(message => {
    return {
      addressId: message.address_id,
      conversationId: message.conversation_id,
      details: message.details,
      fromAddressId: message.from_address_id,
      id: message.id,
      subtype: message.subtype,
      ts: message.ts,
      type: message.type,
      userId: message.user_id,
      // TODO: how to handle optional properties in TS? use spread operator?
      ...(message.kind && { kind: message.kind }),
      ...(message.text && { text: message.text }),
    } satisfies Message
  })
}

const stateCreatorFn: StateCreator<Store> = (set, get) => ({
  ...initialState,
  actions: {
    fetchConversations: async () => {
      try {
        const client = useMainStore.getState().client
        if (!client) {
          throw new Error('Client not initialized')
        }
        // TODO: implement pagination when needed
        const conversationsData = await client.conversation.getConversations()
        console.log('XXXX: conversationsData', conversationsData)

        const conversations = mapConversationsToResources(
          conversationsData.data,
        )

        set({
          // nextPage: conversationsData.nextPage,
          // prevPage: conversationsData.prevPage,
          conversations,
        })

        return conversations
      } catch (error) {
        console.error('Unable to fetch addresses', error)
        throw error
      }
    },
    fetchMessagesByResourceId: async (resourceId: string) => {
      try {
        const client = useMainStore.getState().client
        if (!client) {
          throw new Error('Client not initialized')
        }
        const conversationsData =
          await client.conversation.getConversationMessages({
            addressId: resourceId,
          })
        console.log('XXXX: conversationsData', conversationsData)

        const messages = mapConversationMessage(conversationsData.data)

        // TODO: implement pagination and append new messages instead of replacing
        const messagesByResource = get().messagesByResource
        messagesByResource.set(resourceId, messages)

        set({
          messagesByResource: new Map(messagesByResource),
        })
      } catch (error) {
        console.error('Unable to fetch fetchMessagesByResourceId', error)
        throw error
      }
    },
    fetchRecents: async () => {
      try {
        const client = useMainStore.getState().client
        if (!client) {
          throw new Error('Client not initialized')
        }
        const { actions } = get()
        const conversations = await actions.fetchConversations()

        // resources are fetched to show resource the necessary resource data
        const promises = conversations.map(conversation => {
          return useResourcesStore.getState().actions.fetchResource({
            id: conversation.addressId,
          })
        })

        // best effort instead of fast fail on first reject
        await Promise.allSettled(promises)
      } catch (error) {
        console.error('Unable to fetch recents', error)
        throw error
      }
    },
    getMessageByResourceId: (resourceId: string) => {
      const { messagesByResource } = get()
      return messagesByResource.get(resourceId)
    },
  },
})

export const useConversationsStore = create<Store>()(stateCreatorFn)
export const useConversationsStoreActions = () =>
  useConversationsStore.getState().actions

export type ConversationsStoreType = typeof useConversationsStore

// Expose the store to be used from the console
window.__conversationsStore = useConversationsStore
