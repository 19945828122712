import { Outlet, createFileRoute, redirect } from '@tanstack/react-router'

export const Route = createFileRoute('/_auth/')({
  beforeLoad: ({ location }) => {
    // TODO: handle the case where landing on the index route
    if (location.pathname === '/') {
      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw redirect({
        to: '/recent',
      })
    }
  },
  component: Outlet,
})
