// eslint-disable-next-line @eslint-community/eslint-comments/disable-enable-pair
/* eslint-disable react-refresh/only-export-components */
export interface ErrorViewProps {
  error?: Error
  errorType?: 'not-found' | 'unknown'
}

export const TYPE_TO_MESSAGE = {
  'not-found': {
    message: 'Please ensure that the page exists and the URL is correct.',
    status: '404',
    title: 'Page not found',
  },
  'unknown': {
    message: 'An unknown error occurred. Please try again.',
    status: '',
    title: 'Unknown error',
  },
}

export const ErrorView = (props: ErrorViewProps) => {
  const { error, errorType = 'unknown' } = props

  const { status } = TYPE_TO_MESSAGE[errorType]
  let { title, message } = TYPE_TO_MESSAGE[errorType]

  if (error) {
    title = error.name
    message = error.message
  }

  return (
    <section className="flex min-h-screen w-full items-center justify-center border bg-background px-6">
      <div className="text-center">
        {status && (
          <p className="text-2xl font-semibold text-foreground/50">{status}</p>
        )}
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-foreground">
          {title}
        </h1>
        <p className="mt-6 leading-7 text-foreground">{message}</p>
        <div className="mt-8 flex items-center justify-center gap-x-6">
          <a
            href="/"
            className="rounded-md bg-foreground px-3.5 py-2.5 text-sm font-semibold text-background shadow-sm transition-colors hover:bg-foreground/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-foreground"
          >
            Home
          </a>
        </div>
      </div>
    </section>
  )
}
