import { useEffect, useCallback, useState, useRef } from 'react'
import { WebRTC } from '@signalwire/js'
import audioTestSound from '@/assets/audio-test.mp3'
import { Button } from '@/components/base/button'
import { Icon } from '@/components/common/icons/Icon'

interface ButtonAudioProps {
  className?: string
  deviceId: string
  disabled?: boolean
}

export const ButtonAudioTest = ({
  className = '',
  deviceId,
  disabled = false,
}: ButtonAudioProps) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const audioElement = useRef<HTMLMediaElement>(new Audio(audioTestSound))

  const playAudio = async () => {
    if (audioElement.current) {
      try {
        await audioElement.current.play()
        setIsPlaying(true)
      } catch (_error) {
        setIsPlaying(false)
      }
    }
  }

  const stopAudio = useCallback(() => {
    if (audioElement.current && isPlaying) {
      audioElement.current.pause()
      setIsPlaying(false)

      // Reset to the beginning of the audio track
      audioElement.current.load()
    }
  }, [audioElement, isPlaying])

  const clickHandler = () => {
    if (!isPlaying) {
      void playAudio()
    }
  }

  const audioClipEnded = () => {
    setIsPlaying(false)
  }

  useEffect(() => {
    const element = audioElement.current
    element.addEventListener('ended', audioClipEnded)
    return () => {
      stopAudio()
      element.removeEventListener('ended', audioClipEnded)
    }
  }, [stopAudio])

  useEffect(() => {
    if (disabled) {
      stopAudio()
    } else {
      WebRTC.setMediaElementSinkId(audioElement.current, deviceId).catch(
        error => console.error('setMediaElementSinkId', error),
      )
    }
  }, [deviceId, disabled, stopAudio])

  return (
    <Button
      className={className}
      disabled={disabled}
      onClick={clickHandler}
      variant="icon"
    >
      <Icon tag="play-circle-filled" size="xl" variant="foreground" />
      <span className="sr-only">Test Speakers</span>
    </Button>
  )
}
