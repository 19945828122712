import { forwardRef } from 'react'

// Stores
import { useRoomStore, useRoomStoreActions } from '@/stores/room'
import { useUiStoreActions } from '@/stores/ui'
import { FEATURES } from '@/stores/features'
import { CopyRoomLink } from './CopyRoomLink'
import { TEST_IDS } from '@/helpers/constants'

// Components
import { Icon } from '@/components/common/icons/Icon'
import { ScrollArea } from '@/components/base/scroll-area'
import { Button } from '@/components/base/button'
import { DialogDevices } from '@/components/DialogDevices'
import { Feature } from '@/components/Feature'

// Types
import type { RoomPanelType } from '@/stores/ui'
import type { ButtonProps } from '@/components/base/button'
import type { IconTagName, Variant } from '@/components/common/icons/Icon'

export const PanelRoomOptions = () => {
  const path = useRoomStore(state => state.path)
  const roomLocked = useRoomStore(state => state.roomLocked)
  const memberState = useRoomStore(state => state.memberState)

  const { lockRoomHandler } = useRoomStoreActions()
  const { setRoomPanelType } = useUiStoreActions()

  const changePanelContentHandler = (panelType: RoomPanelType) => {
    setRoomPanelType(panelType)
  }

  const memberJoined = memberState === 'joined'

  return (
    <ScrollArea
      className="m-0 mx-3 p-4 pl-0 pt-0 text-sm text-foreground"
      data-testid={TEST_IDS.ROOM_SETTINGS_PANEL}
    >
      <div className="flex w-full flex-col space-y-3">
        <OptionLink
          iconTag={roomLocked ? 'unlock' : 'lock'}
          text={roomLocked ? 'Unlock Room' : 'Lock Room'}
          onClick={lockRoomHandler}
          disabled={!memberJoined}
        />
        <Feature name={FEATURES.CREATE_SIDEBAR}>
          <OptionLink disabled iconTag="view-sidebar" text="Create Sidebar" />
        </Feature>
        <CopyRoomLink
          address={path}
          clickedContent="Copied!"
          initialContent="Copy Room Link"
          withArrow={false}
        >
          {({ trigger }) => (
            <OptionLink iconTag="share" text="Share Room" onClick={trigger} />
          )}
        </CopyRoomLink>
        <Feature name={FEATURES.CHANGE_ROOMS}>
          <OptionLink
            iconTag="sofa-outline-1"
            text="Change Rooms"
            onClick={() => changePanelContentHandler('navigator')}
            disabled={!memberJoined}
          />
        </Feature>
        <DialogDevices>
          <OptionLink iconTag="headphones" text="Change Devices" />
        </DialogDevices>
        <OptionLink
          iconTag="grid-on"
          text="Layout Options"
          onClick={() => changePanelContentHandler('layout')}
          disabled={!memberJoined}
        />
      </div>
    </ScrollArea>
  )
}

const iconVariant = 'foreground' as Variant

interface OptionLinkProps extends ButtonProps {
  iconTag: IconTagName
  text: string
}

const OptionLink = forwardRef<HTMLButtonElement, OptionLinkProps>(
  ({ iconTag, text, ...props }, ref) => {
    return (
      <Button
        className="justify-start gap-2 p-0"
        variant="icon"
        ref={ref}
        {...props}
      >
        <Icon size="xl" tag={iconTag} variant={iconVariant} />
        <span className="truncate">{text}</span>
      </Button>
    )
  },
)
OptionLink.displayName = 'OptionLink'
