import { debounce, simpleSearchAndFilter } from '@/helpers/utils'
import { useCallback, useMemo, useState } from 'react'

interface UseSearchParams<TItem> {
  delay?: number
  items: TItem[]
  key: keyof TItem
  keyMinLength?: number
}

export type UseSimpleSearchReturn = ReturnType<typeof useSimpleSearch>

/**
 * A hook to perform a simple search on a list of items based on a key.
 * @param delay The delay in milliseconds to wait before updating the search results.
 * @param keyMinLength The minimum length of the search key before performing the search.
 */
export const useSimpleSearch = <T extends Record<string, unknown>>({
  delay = 150,
  items,
  key,
  keyMinLength = 2,
}: UseSearchParams<T>) => {
  const [searchTerm, setSearchTerm] = useState<string>('')

  const handleSetSearchTerm = useCallback(({ search }: { search: string }) => {
    const trimmedSearch = search.trim()
    setSearchTerm(trimmedSearch)
  }, [])

  const handleSearchReset = useCallback(() => {
    setSearchTerm('')
  }, [])

  const debouncedSetSearchTerm = debounce(handleSetSearchTerm, delay)

  const memoizedSearchResults = useMemo(() => {
    // sync search results with the search term or items when they change
    if (searchTerm && searchTerm.length >= keyMinLength) {
      console.log('XXXX: Search Term:', searchTerm)
      const results = simpleSearchAndFilter({
        items: items,
        key: key,
        search: searchTerm,
      })
      return results
    } else {
      // Reset the search results if the search key is too short
      return items
    }
  }, [items, key, keyMinLength, searchTerm])

  return {
    handleSearch: debouncedSetSearchTerm,
    handleSearchReset,
    searchResults: memoizedSearchResults,
    searchTerm,
  }
}
