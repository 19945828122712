import { create } from 'zustand'

// Stores
import { navigateRoute } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import { useDevicesStoreActions } from '@/stores/devices'
import { useMainStore } from '@/stores/main'
import { useFeaturesStore, FEATURES } from '@/stores/features'
import { useUserSettingsStore } from '@/stores/userSettings'
import { useUiStoreActions } from '@/stores/ui'
import { useRoomSessionStore } from '@/stores/roomSession'

// Utils
import { buildRoomPath } from '@/helpers/utils'
import { LAYOUT_MAP, mapLayoutPosition } from '@/helpers/layouts'

// Types
import type {
  CallFabricRoomSession,
  Fabric,
  Video,
  VideoLayoutChangedEventParams,
  VideoPosition,
  VideoRoomSubscribedEventParams,
} from '@signalwire/js'
import type { StateCreator } from 'zustand'
import type { ChannelType, RedirectPath } from '@/helpers/types'
import type { Layout, LayoutName, LayoutPosition } from '@/helpers/layouts'

interface CallJoinOptions {
  channel?: ChannelType | undefined
  context: string
  name: string
  redirect?: RedirectPath | undefined
  rootElement?: HTMLElement
}

interface Actions {
  actions: {
    _cleanUpRoom: () => boolean
    _onDestroy: (
      roomSession: Video.RoomSession,
      redirect?: RedirectPath,
    ) => void
    _onJoined: (event: VideoRoomSubscribedEventParams) => void
    _onLayoutChanged: (event: VideoLayoutChangedEventParams) => void
    _onStart: () => void
    audioMuteHandler: () => boolean
    getLayouts: () => Promise<string[]>
    handRaiseHandler: () => void
    join: (options: CallJoinOptions) => Promise<boolean>
    leave: () => void
    lockRoomHandler: () => Promise<void>
    screenShareHandler: () => Promise<void>
    setAddressId: (addressId: string) => void
    setLayout: (layout: LayoutName) => void
    setLayoutPosition: (positions: VideoPosition) => void
    setMemberState: (memberState: MemberState) => void
    setRoomName: (roomName: string) => void
    setRootElementById: (elementId: string) => HTMLElement | null
    startSession: (roomSession: CallFabricRoomSession) => Promise<void>
    updateCameraHandler: (deviceId: string) => Promise<void>
    updateMicrophoneHandler: (deviceId: string) => Promise<void>
    updateSpeakerHandler: (deviceId: string) => Promise<void>
    videoMuteHandler: () => boolean
  }
}
type MemberState = 'default' | 'joined' | 'joining'

interface State {
  addressId: string
  audioMuted: boolean
  cameraId: string | null
  handRaised: boolean
  hasCamera: boolean
  hasMicrophone: boolean
  hasSpeaker: boolean
  layout: LayoutName | ''
  layoutPosition: string
  layoutPositions: LayoutPosition[]
  layouts: Layout[]
  memberId: string
  memberState: MemberState
  microphoneId: string | null
  path: string
  roomLocked: boolean
  roomName: string
  rootElement: HTMLElement | null
  screenShareStream: Video.RoomSessionScreenShare | null
  sharingScreen: boolean
  speakerId: string | null
  videoMuted: boolean
}

type Store = Actions & State

const initialState: State = {
  addressId: '',
  audioMuted: false,
  cameraId: '',
  handRaised: false,
  hasCamera: true,
  hasMicrophone: true,
  hasSpeaker: true,
  layout: '',
  layoutPosition: '',
  layoutPositions: [],
  layouts: [],
  memberId: '',
  memberState: 'default',
  microphoneId: '',
  path: '',
  roomLocked: false,
  roomName: '',
  rootElement: null,
  screenShareStream: null,
  sharingScreen: false,
  speakerId: '',
  videoMuted: false,
}

const stateCreatorFn: StateCreator<Store> = (set, get) => ({
  ...initialState,
  actions: {
    _cleanUpRoom: () => {
      const { releaseDevices } = useDevicesStoreActions()
      const { hideRoomPanel } = useUiStoreActions()
      releaseDevices()
      hideRoomPanel()
      set(initialState)
      return true
    },
    _onDestroy: (roomSession, redirect) => {
      const { _cleanUpRoom } = get().actions

      // FIXME: The SDK emits the room object on `destroy` event that contains properties including `cause` and `leaveReason`
      // The `leaveReason` is undefined at the moment and `cause` does not exist on the TS interface.
      // The SDK is missing the `room.left` event as well. This needs to be discussed with the team and fixed.
      console.debug('>> destroy', roomSession.leaveReason)

      // TODO: Inform user about the room left reason

      _cleanUpRoom()
      navigateRoute({ to: redirect || '/recent' }).catch(console.error)
    },
    _onJoined: (event: VideoRoomSubscribedEventParams) => {
      const roomState = {
        memberId: event.member_id,
        roomLocked: event.room_session.locked,
      }

      let memberState: Pick<State, 'audioMuted' | 'handRaised' | 'videoMuted'> =
        {
          audioMuted: get().audioMuted,
          handRaised: get().handRaised,
          videoMuted: get().videoMuted,
        }

      const selfMember = event.room_session.members.find(
        member => member.member_id === event.member_id,
      )
      if (selfMember) {
        memberState = {
          audioMuted: selfMember.audio_muted,
          handRaised: selfMember.handraised,
          videoMuted: selfMember.video_muted,
        }
      }

      set({ ...roomState, ...memberState })
    },
    _onLayoutChanged: (event: VideoLayoutChangedEventParams) => {
      const { memberId } = get()
      set({
        layout: event.layout.id as LayoutName,
        layoutPosition:
          event.layout.layers.find(layer => layer.member_id === memberId)
            ?.position || '',
        layoutPositions: event.layout.layers.map(mapLayoutPosition),
      })
    },
    _onStart: () => {
      const { getPreferredSpeakerDeviceId } =
        useUserSettingsStore.getState().actions
      const { roomSession } = useRoomSessionStore.getState()

      console.log('XXXX: room.started')
      const deviceId = getPreferredSpeakerDeviceId()
      if (deviceId && roomSession) {
        roomSession
          .updateSpeaker({ deviceId })
          .then(() => console.log('speaker updated'))
          .catch(console.error)
      }
    },
    audioMuteHandler: () => {
      const { audioMuted } = get()
      const { roomSession } = useRoomSessionStore.getState()

      if (roomSession) {
        if (audioMuted) {
          void roomSession.audioUnmute()
        } else {
          void roomSession.audioMute()
        }
        set({ audioMuted: !audioMuted })
      }
      return !audioMuted
    },
    getLayouts: async () => {
      const { roomSession } = useRoomSessionStore.getState()
      if (!roomSession) return []

      const { layouts } = await roomSession.getLayouts()
      set({
        layouts: layouts
          .map(layout => {
            return LAYOUT_MAP[layout as LayoutName]
          })
          .filter(Boolean),
      })
      return layouts
    },
    handRaiseHandler: () => {
      const { handRaised } = get()
      const { roomSession } = useRoomSessionStore.getState()

      if (!roomSession) return
      if (handRaised) {
        void roomSession.setRaisedHand({ raised: false })
      } else {
        void roomSession.setRaisedHand({ raised: true })
      }
      set({ handRaised: !handRaised })
    },
    join: async (options: CallJoinOptions) => {
      const { context, name, channel, redirect, rootElement } = options
      const { client } = useMainStore.getState()
      const { join } = useRoomSessionStore.getState().actions
      const { getLayouts } = get().actions

      const {
        getPreferredMicrophoneDeviceForDialing,
        getPreferredVideoDeviceForDialing,
      } = useUserSettingsStore.getState().actions
      const path = buildRoomPath(context, name, channel, redirect)

      console.log('XXXX: Joining')
      set({ memberState: 'joining', path })

      if (!path) {
        console.error('Cannot join using an empty address')
        return false
      }

      if (!client) {
        throw new Error('Client is not defined')
      }

      // TODO: Set a node_id for steering?
      const steeringId = ''
      const { getUserVariables } = useUserStore.getState().actions

      const joinParams: Fabric.DialParams = {
        audio: getPreferredMicrophoneDeviceForDialing(path),
        nodeId: steeringId,
        rootElement: rootElement!,
        to: path,
        video: getPreferredVideoDeviceForDialing(path),
      }

      const { getFeatureFlagIsDisabled } = useFeaturesStore.getState().actions
      const userVariablesIsDisabled = getFeatureFlagIsDisabled(
        FEATURES.USER_VARIABLES,
      )
      if (!userVariablesIsDisabled) {
        joinParams.userVariables = await getUserVariables({ channel })
      }

      const call = await join(
        { ...joinParams },
        {
          onDestroy: room => {
            const { _onDestroy } = get().actions
            _onDestroy(room, redirect)
          },
          onJoined: event => {
            const { _onJoined } = get().actions
            _onJoined(event)
          },
          onLayoutChanged: event => {
            const { _onLayoutChanged } = get().actions
            _onLayoutChanged(event)
          },
          onStart: () => {
            const { _onStart } = get().actions
            _onStart()
          },
        },
      )
      if (!call) {
        throw new Error('Call is not defined')
      }

      set({ memberState: 'joined' })

      // Fetch and store room layouts
      void getLayouts()

      // Expose the roomSession to be used from the console
      window.__roomSession = call

      return true
    },
    leave: () => {
      console.log('XXXX: leave()')
      const { memberState } = get()
      const { _cleanUpRoom } = get().actions
      const { leave } = useRoomSessionStore.getState().actions

      if (memberState === 'default') {
        return true
      }

      leave()
        .then(() => {
          console.log('XXXX: hangup success')
          _cleanUpRoom()
          return true
        })
        .catch(console.error)

      return false
    },
    lockRoomHandler: async () => {
      const { roomLocked } = get()
      const { roomSession } = useRoomSessionStore.getState()

      if (!roomSession) return
      if (roomLocked) {
        await roomSession.unlock()
      } else {
        await roomSession.lock()
      }
      set({ roomLocked: !roomLocked })
    },
    screenShareHandler: async (): Promise<void> => {
      const { sharingScreen, screenShareStream } = get()
      const { roomSession } = useRoomSessionStore.getState()

      if (!roomSession) return

      try {
        if (sharingScreen) {
          // If a local screenshare exists, then stop it
          set({ sharingScreen: false })
          if (screenShareStream) {
            await screenShareStream.leave()
          }
          set({ screenShareStream: null })
        } else {
          // Initialize a screenshare media stream
          set({ sharingScreen: true })
          const screenShare = await roomSession.startScreenShare()
          set({ screenShareStream: screenShare })

          screenShare.once('destroy', () => {
            set({ screenShareStream: null, sharingScreen: false })
          })
        }
      } catch (error) {
        console.error('Screenshare Error: ', error)
        set({ screenShareStream: null, sharingScreen: false })
      }
    },
    setAddressId: (addressId: string) => {
      set({
        addressId,
      })
    },
    setLayout: (layout: LayoutName) => {
      const { roomSession } = useRoomSessionStore.getState()
      if (!roomSession) return

      void roomSession.setLayout({ name: layout })
      set({ layout })
    },
    setLayoutPosition: position => {
      const { roomSession } = useRoomSessionStore.getState()
      if (!roomSession) return

      void roomSession.setPositions({ positions: { self: position } })
      set({ layoutPosition: position })
    },
    setMemberState: (memberState: MemberState) => {
      set({ memberState })
    },
    setRoomName: (roomName: string) => {
      set({
        roomName,
      })
    },
    setRootElementById: (elementId: string) => {
      let rootElement: HTMLElement | null = null

      if (elementId) {
        rootElement = document.getElementById(elementId)
      }
      set({ rootElement })
      return rootElement
    },
    startSession: async (roomSession: CallFabricRoomSession) => {
      const { startSession } = useRoomSessionStore.getState().actions
      await startSession(roomSession, {
        onDestroy: room => {
          const { _onDestroy } = get().actions
          _onDestroy(room)
        },
        onStart: () => {
          const { _onStart } = get().actions
          _onStart()
        },
      })
      set({ memberState: 'joined' })
    },
    updateCameraHandler: async (deviceId: string) => {
      // TODO: Handle errors?
      const { videoMuted } = get()
      const { roomSession } = useRoomSessionStore.getState()

      if (!roomSession) return
      await roomSession.updateCamera({ deviceId })
      if (videoMuted) {
        roomSession.stopOutboundVideo()
      }
    },
    updateMicrophoneHandler: async (deviceId: string) => {
      const { audioMuted } = get()
      const { roomSession } = useRoomSessionStore.getState()

      if (!roomSession) return
      await roomSession.updateMicrophone({ deviceId })
      if (audioMuted) {
        roomSession.stopOutboundAudio()
      }
    },
    updateSpeakerHandler: async (deviceId: string) => {
      const { roomSession } = useRoomSessionStore.getState()

      if (!roomSession) return
      await roomSession.updateSpeaker({ deviceId })
    },
    videoMuteHandler: () => {
      const { videoMuted } = get()
      const { roomSession } = useRoomSessionStore.getState()

      if (roomSession) {
        if (videoMuted) {
          void roomSession.videoUnmute()
        } else {
          void roomSession.videoMute()
        }
        set({ videoMuted: !videoMuted })
      }
      return !videoMuted
    },
  },
})

export const useRoomStore = create<Store>()(stateCreatorFn)
export const useRoomStoreActions = () => useRoomStore.getState().actions

// Expose the store to be used from the console
window.__roomStore = useRoomStore
