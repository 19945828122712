// NOTE: recommended to import sentry first
import { sentryInit } from '@/sentry/instrument'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { router } from './router'
import { i18nInit } from '@/i18n/index'
import { useAuthStore } from '@/stores/auth'
import { useMainStore } from '@/stores/main'
import { FEATURES, isFeatureEnabled } from '@/stores/features'
import { App } from '@/components/App'
import { globalErrorListeners } from '@/helpers/error'
import { ENV_VARS, ROOT_ELEMENT_ID } from '@/helpers/constants'
import { dispatchUnhandledErrorToast } from '@/helpers/toastMessages'
import { logger } from '@/logger/createLogger'
import './index.css'

// initial global error listeners
if (isFeatureEnabled(FEATURES.GLOBAL_ERROR_LISTENERS)) {
  const { start } = globalErrorListeners({
    onUncaughtException: dispatchUnhandledErrorToast,
    onUnhandledRejection: dispatchUnhandledErrorToast,
  })
  start()
}

if (isFeatureEnabled(FEATURES.SENTRY)) {
  // Initialize Sentry monitoring
  const { sentryTransport } = sentryInit({
    dsn: ENV_VARS.VITE_SENTRY_DSN,
    environment: ENV_VARS.VITE_MODE,
    router,
  })
  logger.attachTransport(logObj => sentryTransport.handleTransport(logObj))
}

i18nInit().catch(error => {
  console.error('Error initializing i18n', error)
  throw error
})

export const main = () => {
  // Save the router in the state and so we can call functions (not hooks)
  const { init } = useAuthStore.getState().actions
  const { setHost } = useMainStore.getState().actions
  useAuthStore.setState({ router })

  // NOTE: these are parameters needed for the OAuth flow, some could be passed as params
  init({
    authUri: ENV_VARS.VITE_OAUTH_AUTH_URI,
    clientId: ENV_VARS.VITE_OAUTH_CLIENT_ID,
    codeChallengeMethod: 'S256',
    redirectUri: `${window.location.origin}/callback`,
    responseType: 'code',
    tokenUri: ENV_VARS.VITE_OAUTH_TOKEN_URI,
  })

  // NOTE: The prod/staging environments have different host endpoints
  setHost(ENV_VARS.VITE_RELAY_HOST)

  const root = document.getElementById(ROOT_ELEMENT_ID)
  createRoot(root!).render(
    <StrictMode>
      <App router={router} />
    </StrictMode>,
  )
}
