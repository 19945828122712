import type { LoggerConfigOptions } from '@/logger/createLogger'
import type { AppRouter, ViteMode } from '@/helpers/types'
import * as Sentry from '@sentry/react'
import { LOG_LEVEL_TO_VALUE_MAP } from '@/logger/loggerConstants'
import { SentryTransport } from '@/sentry/sentryTransport'
import { ENV_VARS } from '@/helpers/constants'

/* eslint-disable sort-keys */
// configure log levels for Sentry based on the environment/* eslint-disable sort-keys */
export const SENTRY_LOG_CONFIG = {
  development: {
    sentry: {
      minLogLevel: LOG_LEVEL_TO_VALUE_MAP.DEBUG,
    },
  },
  testing: {
    sentry: {
      minLogLevel: LOG_LEVEL_TO_VALUE_MAP.DEBUG,
    },
  },
  staging: {
    sentry: {
      minLogLevel: LOG_LEVEL_TO_VALUE_MAP.DEBUG,
    },
  },
  production: {
    sentry: {
      minLogLevel: LOG_LEVEL_TO_VALUE_MAP.ERROR,
    },
  },
} as const satisfies LoggerConfigOptions<'sentry'>
/* eslint-enable sort-keys */

const sentrySentryReplayOptions = {
  integrations: [
    Sentry.replayIntegration({
      blockAllMedia: true, // blocks video
      maskAllText: true,
    }),
  ],
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: ENV_VARS.IS_PRODUCTION ? 0.1 : 1.0,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
} as const satisfies Sentry.BrowserOptions

interface SentryInitParams extends Sentry.BrowserOptions {
  environment: ViteMode
  router?: AppRouter
}

export const sentryInit = ({
  router: router,
  ...sentryOptions
}: SentryInitParams) => {
  // initialize Sentry SDK
  Sentry.init({
    ...sentryOptions,
    ...sentrySentryReplayOptions,
    integrations: [
      Sentry.tanstackRouterBrowserTracingIntegration(router),
      ...sentrySentryReplayOptions.integrations,
    ],

    // Setting a sample rate is required for sending performance data (needed for router integration)
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control.
    tracesSampleRate: ENV_VARS.IS_PRODUCTION ? 0.1 : 1.0,
  })

  // create a Sentry transport for the logger
  const sentryTransport = new SentryTransport(
    Sentry,
    SENTRY_LOG_CONFIG[sentryOptions.environment]?.sentry,
  )
  return { sentryTransport }
}
