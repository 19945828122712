import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from '@/components/base/toast'
import { useToast } from '@/hooks/use-toast'
import { Icon } from '@/components/common/icons/Icon'
import { cn } from '@/helpers/utils'
import { subscribeToRootElementResize, useUiStore } from '@/stores/ui'
import { useEffect, useRef } from 'react'

const translateTo = (left: number, top: number) =>
  `translate(${left}px, ${top}px)` as const

const TOAST_VIEWPORT_STYLES = {
  RIGHT: 16, // 16px
  TOP: 8, // 8px
  TOP_MARGIN: 16, // 16px
  TRANSFORM: translateTo(0, 0),
} as const

export function Toaster() {
  const displayErrorDialog = useUiStore(state => state.displayErrorDialog)
  const errorDialogElementRef = useUiStore(state => state.errorDialogElementRef)
  const toastViewPortRef = useRef<HTMLOListElement | null>(null)
  const { toasts } = useToast()

  useEffect(() => {
    // if the error dialog is displayed, we want to position the toasts underneath it in a vertical stack
    const toastViewport = toastViewPortRef.current
    if (!displayErrorDialog || !errorDialogElementRef || !toastViewport) {
      return
    }

    // requestAnimationFrame id
    let raf: number | null = null

    // the current transform of the toast viewport (x, y) px
    let currentTransform = {
      xPos: 0,
      yPos: 0,
    }

    const readDimensions = () => {
      const errorDialogRect = errorDialogElementRef.getBoundingClientRect()
      const toastViewportRect = toastViewport.getBoundingClientRect()

      // // the distance the toast viewport needs to move to the left
      const leftDiff = errorDialogRect.left - toastViewportRect.left

      // space between the top of the toast viewport and the top of the error dialog
      const desiredTopMarginPx = TOAST_VIEWPORT_STYLES.TOP_MARGIN

      // the distance the toast viewport needs to move down
      const topDiff =
        errorDialogRect.bottom -
        toastViewportRect.top -
        TOAST_VIEWPORT_STYLES.TOP +
        desiredTopMarginPx

      return { leftDiff, topDiff }
    }

    const callRaf = (transformStyles: string) => {
      if (raf) {
        cancelAnimationFrame(raf)
      }
      raf = requestAnimationFrame(() => {
        // move the toast viewport underneath the error dialog
        toastViewport.style.transform = transformStyles
      })
    }

    const resetStyles = () => {
      // reset the transform
      callRaf(TOAST_VIEWPORT_STYLES.TRANSFORM)
    }

    const animate = () => {
      const { leftDiff, topDiff } = readDimensions()

      const currentXPos =
        currentTransform.xPos === 0
          ? // center the toast viewport underneath the error dialog
            currentTransform.xPos - TOAST_VIEWPORT_STYLES.RIGHT / 2
          : currentTransform.xPos

      // update the current transform
      currentTransform = {
        xPos: currentXPos + leftDiff,
        yPos: currentTransform.yPos + topDiff,
      }

      // update the toast viewport position
      callRaf(translateTo(currentTransform.xPos, currentTransform.yPos))
    }

    // move the toast viewport to the correct position when the error dialog is displayed
    animate()

    // update position when the root element is resized
    const unsubscribe = subscribeToRootElementResize({
      onResized: () => {
        animate()
      },
    })

    return () => {
      // clean up the animation
      resetStyles()
      unsubscribe()
    }
  }, [displayErrorDialog, errorDialogElementRef])

  return (
    <ToastProvider>
      {toasts.map(function ({
        action,
        description,
        dismissLabel = 'Dismiss',
        id,
        onDismiss,
        titleLabel,
        variant,
        ...props
      }) {
        const isDestructive = variant === 'destructive'
        return (
          <Toast key={id} {...props}>
            {isDestructive && (
              <Icon tag="warning" variant="destructive" size="xl" />
            )}
            <div className="flex w-full flex-col gap-y-1">
              {titleLabel && (
                <ToastTitle className={cn(isDestructive && 'text-destructive')}>
                  {titleLabel}
                </ToastTitle>
              )}
              {description && (
                <ToastDescription>{description}</ToastDescription>
              )}
            </div>
            {action}
            <ToastClose dismissLabel={dismissLabel} onClick={onDismiss} />
          </Toast>
        )
      })}
      <ToastViewport
        ref={toastViewPortRef}
        className="transition-transform duration-300"
        position="top-right"
        positioning="fixed"
        style={{
          right: TOAST_VIEWPORT_STYLES.RIGHT,
          top: TOAST_VIEWPORT_STYLES.TOP,
          transform: TOAST_VIEWPORT_STYLES.TRANSFORM,
        }}
      />
    </ToastProvider>
  )
}
