import { forwardRef, useEffect, useRef, useState } from 'react'
import type { TextareaHTMLAttributes } from 'react'
import { Textarea } from '@/components/base/textarea'
import { cn, isTextAreaOverflowing } from '@/helpers/utils'

export interface TextareaResizableProps
  extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> {
  maxRows?: number
  minRows?: number
  onEnter?: () => void
  onValueChange: (value: string) => void
}

export const TextareaResizable = forwardRef<
  HTMLTextAreaElement,
  TextareaResizableProps
>(function TextareaResizable(
  {
    className = '',
    maxRows = 5,
    minRows = 1,
    onEnter,
    rows = 1,
    value = '',
    onValueChange,
    ...props
  },
  ref,
) {
  // Grow or shrink the textarea's height by changing the number of rows
  const [currentRows, setCurrentRows] = useState(rows)
  const textAreaResizableRef = useRef<HTMLTextAreaElement | null>(null)

  const lastValue = useRef('')
  const shrinking = useRef(false)

  useEffect(() => {
    const element = textAreaResizableRef?.current

    if (value !== lastValue.current) {
      shrinking.current = value.toString().length < lastValue.current.length
      lastValue.current = value.toString()
    }

    if (
      currentRows > minRows &&
      shrinking.current &&
      !isTextAreaOverflowing(element)
    ) {
      setCurrentRows(currentRows - 1)
    } else if (currentRows < maxRows && isTextAreaOverflowing(element)) {
      shrinking.current = false
      setCurrentRows(currentRows + 1)
    }
  }, [currentRows, minRows, maxRows, value])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const isEnterKey = event.key === 'Enter'

    // handle inserting new line to textarea at cursor position when mod keys are pressed
    if (isEnterKey) {
      event.preventDefault()

      const hasModifierKey = event.ctrlKey || event.metaKey || event.shiftKey

      if (hasModifierKey && textAreaResizableRef.current) {
        // add a new line at the current cursor position
        textAreaResizableRef.current.setRangeText(
          '\n',
          textAreaResizableRef.current.selectionStart,
          textAreaResizableRef.current.selectionEnd,
          'end', // end will set the cursor after the new line
        )

        onValueChange(textAreaResizableRef.current.value)
        return
      }
    }

    if (isEnterKey && onEnter) {
      event.preventDefault()
      return onEnter()
    }
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onValueChange(event.target.value)
  }

  return (
    <Textarea
      className={cn('resize-none', className)}
      placeholder={'Message'}
      ref={element => {
        // ref setup needed for react-hook-form
        // @ts-expect-error Possible non-callable ref
        ref(element)
        textAreaResizableRef.current = element
      }}
      onChange={handleOnChange}
      onKeyDown={handleKeyDown}
      rows={currentRows}
      value={value}
      {...props}
    />
  )
})
