import { forwardRef } from 'react'
import type { PropsWithChildren } from 'react'
import { Route } from '@/routes/_auth.room.$context.$name'
import { cn } from '@/helpers/utils'
import { useRoomStore } from '@/stores/room'
import { useUiStore } from '@/stores/ui'
import { PanelRoom } from '@/components/PanelRoom'
import { RoomFooter } from '@/components/RoomFooter'
import { RoomHeader } from '@/components/RoomHeader'

type LayoutRoomProps = PropsWithChildren

export const LayoutRoom = forwardRef<
  HTMLDivElement,
  { children?: React.ReactNode }
>(({ children }: LayoutRoomProps, ref) => {
  const { redirect } = Route.useSearch()
  const isAudioOnly = useRoomStore(state => state.isAudioOnly)
  const memberState = useRoomStore(state => state.memberState)
  const roomPanelOpen = useUiStore(state => state.roomPanelOpen)

  return (
    <div ref={ref}>
      <div className="w-svh flex h-svh flex-col justify-between overflow-hidden bg-primary">
        <div className="h-room-header w-full shrink-0 px-9">
          <RoomHeader />
        </div>
        <div className="relative flex w-full flex-1 overflow-hidden">
          <div
            className={cn(
              'w-auto grow overflow-hidden transition-[width] duration-300 ease-in-out',
              'motion-reduce:transition-none',
              memberState === 'joined' && 'bg-black',
            )}
            data-state={roomPanelOpen ? 'open' : 'closed'}
          >
            {children}
          </div>
          <PanelRoom />
        </div>
        {!isAudioOnly && memberState === 'joined' && (
          <RoomFooter redirect={redirect} />
        )}
      </div>
    </div>
  )
})

LayoutRoom.displayName = 'LayoutRoom'
