// Store for UI element state (open drawers/modals, etc.)
import { createResizeObserver } from '@/helpers/utils'
import type { MutableRefObject } from 'react'
import { create, type StateCreator } from 'zustand'

type SetErrorDialogParams =
  | {
      cancelLabel: string
      confirmLabel: string
      description: string
      onCancel?: () => void
      onConfirm: () => void
      title: string
    }
  | {
      cancelLabel?: never
      confirmLabel: string
      description: string
      onCancel?: never
      onConfirm: () => void
      title: string
    }

export type RoomPanelType =
  | 'chat'
  | 'layout'
  | 'navigator'
  | 'options'
  | 'participants'

interface Actions {
  actions: {
    createRootElementResizeObserver: (element: HTMLElement) => {
      start: () => void
      stop: () => void
    }
    hideContact: () => void
    hideErrorDialog: () => void
    hideRoomPanel: () => void
    setErrorDialogContent: ({
      cancelLabel,
      confirmLabel,
      description,
      onCancel,
      onConfirm,
      title,
    }: SetErrorDialogParams) => void
    setErrorDialogRef: (ref: HTMLDivElement | null) => void
    setRoomPanelTitle: (panelTitle: string) => void
    setRoomPanelType: (panelType: RoomPanelType) => void
    setRootElementRef: (element: HTMLElement | null) => void
    setViewElementRef: (ref: MutableRefObject<HTMLElement | null>) => void
    showContact: () => void
    showErrorDialog: () => void
    showRoomPanel: () => void
    toggleRoomPanel: () => void
  }
}

interface State {
  displayContact: boolean
  displayErrorDialog: boolean
  displayRoomPanel: boolean
  errorCancelLabel: string
  errorConfirmLabel: string
  errorDescription: string
  errorDialogElementRef: HTMLDivElement | null
  errorOnClose: (() => void) | undefined
  errorOnConfirm: (() => void) | undefined
  errorTitle: string
  roomPanelTitle: string
  roomPanelType: RoomPanelType | null
  rootElementHeight: number
  rootElementRef: HTMLElement | null
  rootElementWidth: number
  viewElementRef: MutableRefObject<HTMLElement | null> | null
}

type Store = Actions & State

const initialState: State = {
  displayContact: true,
  displayErrorDialog: false,
  displayRoomPanel: false,
  errorCancelLabel: '',
  errorConfirmLabel: '',
  errorDescription: '',
  errorDialogElementRef: null,
  errorOnClose: undefined,
  errorOnConfirm: undefined,
  errorTitle: '',
  roomPanelTitle: '',
  roomPanelType: 'options',
  rootElementHeight: 0,
  rootElementRef: null,
  rootElementWidth: 0,
  viewElementRef: null,
}

const stateCreatorFn: StateCreator<Store> = (set, get) => ({
  ...initialState,
  actions: {
    createRootElementResizeObserver: element => {
      set({
        rootElementHeight: element.offsetHeight,
        rootElementWidth: element.offsetWidth,
      })

      const { disconnect, start, stop } = createResizeObserver(element, () => {
        set({
          rootElementHeight: element.offsetHeight,
          rootElementWidth: element.offsetWidth,
        })
      })

      return { disconnect, start, stop }
    },
    hideContact: () => {
      set({ displayContact: false })
    },
    hideErrorDialog: () => {
      set({ displayErrorDialog: false })
    },
    hideRoomPanel: () => {
      set({ displayRoomPanel: false, roomPanelTitle: '', roomPanelType: null })
    },
    setErrorDialogContent: ({
      cancelLabel = '',
      confirmLabel,
      description,
      onCancel,
      onConfirm,
      title,
    }) => {
      set({
        errorCancelLabel: cancelLabel,
        errorConfirmLabel: confirmLabel,
        errorDescription: description,
        errorOnClose: onCancel,
        errorOnConfirm: onConfirm,
        errorTitle: title,
      })
    },
    setErrorDialogRef: ref => {
      set({ errorDialogElementRef: ref })
    },
    setRoomPanelTitle: panelTitle => {
      set({
        roomPanelTitle: panelTitle,
      })
    },
    setRoomPanelType: panelType => {
      set({ roomPanelTitle: '', roomPanelType: panelType })
    },
    setRootElementRef: element => {
      set({
        rootElementRef: element,
      })
    },
    setViewElementRef: (ref: MutableRefObject<HTMLElement | null>) => {
      set({ viewElementRef: ref })
    },
    showContact: () => {
      set({ displayContact: true })
    },
    showErrorDialog: () => {
      set({ displayErrorDialog: true })
    },
    showRoomPanel: () => {
      set({ displayRoomPanel: true })
    },
    toggleRoomPanel: () => {
      const { displayRoomPanel } = get()
      const { hideRoomPanel, showRoomPanel } = get().actions
      if (displayRoomPanel) {
        hideRoomPanel()
      } else {
        showRoomPanel()
      }
    },
  },
})

export const useUiStore = create<Store>()(stateCreatorFn)
export const useUiStoreActions = () => useUiStore.getState().actions

// Expose the store to be used from the console
window.__uiStore = useUiStore

// store convenience functions
interface SubscribeToRootElementResizedParams {
  onResized?: () => void
  onUnsubscribe?: () => void
}

export const subscribeToRootElementResize = (
  params: SubscribeToRootElementResizedParams,
) => {
  const { onResized, onUnsubscribe } = params
  const unsubscribe = useUiStore.subscribe((state, prevState) => {
    if (!state.rootElementRef) {
      return // no root element
    }

    // fire callback when the root element's height or width changes
    const heightChanged =
      state.rootElementHeight !== prevState.rootElementHeight
    const widthChanged = state.rootElementWidth !== prevState.rootElementWidth

    if (heightChanged || widthChanged) {
      onResized?.()
    }
  })

  return () => {
    unsubscribe()
    onUnsubscribe?.()
  }
}
