import { ENV_VARS } from '@/helpers/constants'
import { Icon } from './common/icons/Icon'

export const DevIndicator = () => {
  if (!ENV_VARS.IS_DEVELOPMENT) return null

  return (
    <div className="fixed bottom-2 right-2 flex gap-1 rounded-sm border border-border bg-warning p-1 text-xs">
      <Icon tag="auto-awesome" size="sm" variant="foreground" />
      Development mode
    </div>
  )
}
