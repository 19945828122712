import type { CallCapabilitiesContract } from '@signalwire/js'
import type { StateCreator } from 'zustand'
import { create } from 'zustand'

interface Actions {
  actions: {
    setCapabilities: (capabilities: CallCapabilitiesContract) => void
  }
}

interface CapabilitiesState {
  hasDNDCapabilityOff: boolean
  hasDNDCapabilityOn: boolean
  hasHandRaiseCapabilityOff: boolean
  hasHandRaiseCapabilityOn: boolean
  hasMicrophoneCapabilityOff: boolean
  hasMicrophoneCapabilityOn: boolean
  hasPositionCapability: boolean
  hasRoomLockCapabilityOff: boolean
  hasRoomLockCapabilityOn: boolean
  hasScreenShareCapability: boolean
  hasSetLayoutCapability: boolean
  hasVideoCapabilityOff: boolean
  hasVideoCapabilityOn: boolean
}

interface State extends CapabilitiesState {
  capabilities: CallCapabilitiesContract | undefined
}

type Store = Actions & State

const initialState: State = {
  capabilities: undefined,
  hasDNDCapabilityOff: false,
  hasDNDCapabilityOn: false,
  hasHandRaiseCapabilityOff: false,
  hasHandRaiseCapabilityOn: false,
  hasMicrophoneCapabilityOff: false,
  hasMicrophoneCapabilityOn: false,
  hasPositionCapability: false,
  hasRoomLockCapabilityOff: false,
  hasRoomLockCapabilityOn: false,
  hasScreenShareCapability: false,
  hasSetLayoutCapability: false,
  hasVideoCapabilityOff: false,
  hasVideoCapabilityOn: false,
}

const mapCapabilitiesToState = (capabilities: CallCapabilitiesContract) => {
  const { lock, self, screenshare, setLayout } = capabilities

  return {
    hasDNDCapabilityOff: self.deaf.off,
    hasDNDCapabilityOn: self.deaf.on,
    hasHandRaiseCapabilityOff: self.raisehand.off,
    hasHandRaiseCapabilityOn: self.raisehand.on,
    hasMicrophoneCapabilityOff: self.muteAudio.off,
    hasMicrophoneCapabilityOn: self.muteAudio.on,
    hasPositionCapability: self.position,
    hasRoomLockCapabilityOff: lock.off,
    hasRoomLockCapabilityOn: lock.on,
    hasScreenShareCapability: screenshare,
    hasSetLayoutCapability: setLayout,
    hasVideoCapabilityOff: self.muteVideo.off,
    hasVideoCapabilityOn: self.muteVideo.on,
  } satisfies CapabilitiesState
}

const stateCreatorFn: StateCreator<Store> = (set, _get) => ({
  ...initialState,
  actions: {
    setCapabilities: (capabilities: CallCapabilitiesContract) => {
      // TODO: do we need to save all the capabilities? or just the ones we use in the app?
      set({
        capabilities,
        ...mapCapabilitiesToState(capabilities),
      })
    },
  },
})

export const useCapabilitiesStore = create<Store>()(stateCreatorFn)
export const useCapabilitiesStoreActions = () =>
  useCapabilitiesStore.getState().actions

// Expose the store to be used from the console
window.__capabilitiesStore = useCapabilitiesStore
