import { useAuthStore } from '@/stores/auth'
import { RouterProvider, createRouter } from '@tanstack/react-router'
import { routeTree } from '../routeTree.gen'
import { DevIndicator } from './DevIndicator'
import { ErrorView } from './ErrorView'
import { LoadingWrapper } from '@/components/LoadingWrapper'
import { useEffect } from 'react'
import { useUiStoreActions } from '@/stores/ui'
import { ROOT_ELEMENT_ID } from '@/helpers/constants'

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

const router = createRouter({
  context: { title: '' },
  defaultErrorComponent: ErrorView,
  defaultNotFoundComponent: () => <ErrorView errorType="not-found" />,
  defaultPendingComponent: LoadingWrapper,
  // TODO: revisit where we want/need preloading
  // defaultPreload: 'intent',
  routeTree: routeTree,
})

// Save the router in the state and so we can call functions (not hooks)
useAuthStore.setState({ router })

export const App = () => {
  const { createRootElementResizeObserver, setRootElementRef } =
    useUiStoreActions()

  useEffect(() => {
    // Create a resize observer for the root element
    const rootElement = document.getElementById(ROOT_ELEMENT_ID)
    if (!rootElement) {
      console.error(`Element with id ${ROOT_ELEMENT_ID} not found`)

      // this is probably a critical error
      setRootElementRef(null)
      return
    }

    // Set the root element ref in the store
    setRootElementRef(rootElement)
    const { start, stop } = createRootElementResizeObserver(rootElement)

    // Start the resize observer
    start()

    return () => {
      // clean up
      stop()
    }
  }, [createRootElementResizeObserver, setRootElementRef])

  return (
    <>
      <RouterProvider
        router={router}
        // Preloading may be an issue with our app
        // defaultPreload="intent"
      />

      <DevIndicator />
    </>
  )
}
