import { ScrollArea } from '@/components/base/scroll-area'

// type PanelRoomParticipantsProps = { }

export const PanelRoomParticipants = () => {
  return (
    <ScrollArea className="m-0 p-4 text-sm text-foreground">
      Room Participants
    </ScrollArea>
  )
}
