import type { ButtonPanelOptionProps } from '@/components/ButtonPanelOption'
import { ButtonPanelOption } from '@/components/ButtonPanelOption'
import { useRoomStore, useRoomStoreActions } from '@/stores/room'

type OmittedProps = Omit<ButtonPanelOptionProps, 'isActive' | 'onClick' | 'tag'>

type ButtonRoomLockProps = OmittedProps

const ButtonRoomLock = ({
  disabled = false,
  isVisible = true,
  ...props
}: ButtonRoomLockProps) => {
  const roomLocked = useRoomStore(state => state.roomLocked)

  // TODO: should the lock room handler be debounced/throttled to prevent spamming?
  const { lockRoomHandler } = useRoomStoreActions()

  return (
    <ButtonPanelOption
      isActive={roomLocked}
      disabled={disabled}
      isVisible={isVisible}
      onClick={lockRoomHandler}
      // TODO: add disabled tag if needed
      tag={roomLocked ? 'unlock' : 'lock'}
      {...props}
    >
      {roomLocked ? 'Unlock Room' : 'Lock Room'}
    </ButtonPanelOption>
  )
}

export { ButtonRoomLock }
