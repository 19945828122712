import { BREAKPOINT_KEYS, BREAKPOINT_VALUES_PX } from '@/helpers/constants'
import { useUiStore } from '@/stores/ui'
import { useMemo } from 'react'

/**
 * Hook to get the current breakpoint key based on the root element width
 * - SM: 0px to MD breakpoint
 * - MD: MD breakpoint and above
 */
export const useBreakPoint = () => {
  const rootElementWidth = useUiStore(state => state.rootElementWidth)

  const breakPointKey = useMemo(() => {
    if (rootElementWidth >= BREAKPOINT_VALUES_PX.MD) {
      return BREAKPOINT_KEYS.MD
    } else {
      // NOTE: SM breakpoint is 0px to MD breakpoint
      return BREAKPOINT_KEYS.SM
    }
  }, [rootElementWidth])

  return breakPointKey
}
