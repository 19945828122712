import type { ResourceType } from '@signalwire/js'
import type {
  CombinedIconProps,
  IconTagName,
} from '@/components/common/icons/Icon'
import { Icon } from '@/components/common/icons/Icon'

type ResourceTypeIconProps = Omit<CombinedIconProps, 'tag' | 'type'> & {
  resourceType: ResourceType
}

const mapResourceTypeToIcon = {
  app: 'auto-awesome',
  call: 'call',
  room: 'rooms',
  subscriber: 'person-pin',
} as const satisfies Record<ResourceType, IconTagName>

// TODO: If possible, map the "type/kind" to appropriate icons, example missed-call vs outgoing-call?

export const ResourceTypeIcon = ({
  resourceType,
  ...props
}: ResourceTypeIconProps) => {
  return <Icon tag={mapResourceTypeToIcon[resourceType]} {...props} />
}
