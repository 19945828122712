import { Icon } from '@/components/common/icons/Icon'

interface SearchEmptyProps {
  searchTerm?: string | undefined
  showIcon?: boolean | undefined
}

export const SearchEmpty = ({
  searchTerm,
  showIcon = true,
}: SearchEmptyProps) => {
  return (
    <div className="items-top flex max-w-full justify-center p-4">
      {showIcon && <Icon size="2xl" tag="search" className="shrink-0" />}
      <div className="max-w-full shrink break-words font-montserrat text-xl">
        {searchTerm ? (
          <>
            No results for:{' '}
            <span className="font-semibold italic">{searchTerm}</span>
          </>
        ) : (
          'No results'
        )}
      </div>
    </div>
  )
}
