import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '@/helpers/utils'

/* eslint-disable sort-keys */

// `variant` should follow `size` so that size classes can be overridden by a variant
const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
      },
      variant: {
        'default': 'bg-primary text-primary-foreground hover:bg-primary/90',
        'light':
          'bg-neutral-light text-primary-light hover:text-primary-light/75',
        'destructive':
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        'gradient':
          'text-neutral-light gradient w-full rounded-[6.25rem] border-none bg-current hover:border-2 hover:border-solid hover:border-negative-light hover:bg-background hover:bg-none hover:text-negative-light',
        'outline':
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        'link':
          'text-primary-light py-2.5 px-4 disabled:text-accent disabled:opacity-100 hover:bg-neutral-light rounded-[6.25rem]',
        'icon': 'hover:opacity-75',
        'icon-animated':
          'group p-0.5 rounded-full border border-transparent hover:fill-negative-light hover:text-negative-light transition-colors hover:border-negative-light',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)
/* eslint-enable sort-keys */

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(buttonVariants({ className, size, variant }))}
        ref={ref}
        {...props}
      />
    )
  },
)
Button.displayName = 'Button'

// eslint-disable-next-line react-refresh/only-export-components
export { Button, buttonVariants }
