import { useConversationsStore } from '@/stores/conversations'
import { createFileRoute } from '@tanstack/react-router'
import { RecentView } from '../components/views/RecentView'

export const Route = createFileRoute('/_auth/recent')({
  beforeLoad: () => ({ title: 'Recent' }),
  component: RecentView,
  loader: () =>
    useConversationsStore
      .getState()
      .actions.fetchRecents()
      .catch(console.error),
})
