import { generatePKCE } from '@/helpers/pkceChallenge'
import { getCurrentTimeSec, typeSafeFilterObject } from '@/helpers/utils'
import {
  httpService,
  type PostAccessTokenResponseCamelCase,
} from '@/http/createHttpService'
import { buildUrlWithParams } from '@/http/httpUtils'
import { useMainStore } from '@/stores/main'
import { create, type StateCreator } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import type { AppRouter, NavigateRouteParams } from '@/helpers/types'
import { logger } from '@/logger/createLogger'

const REFRESH_TOKEN_DIFF_SEC = 120 // Trigger token refresh when 120 seconds or less remain

interface Actions {
  actions: {
    _clearTokens: () => void
    _expiresAt: () => number | undefined
    _isExpired: () => boolean
    _refreshTokenAndUpdateSDK: () => Promise<void>
    _scheduleTokenRefresh: () => void
    fetchAccessToken: (code: string) => Promise<void>
    init: (initParams: InitParams) => void
    isAuthenticated: () => Promise<boolean>
    logout: () => boolean
    refreshAccessToken: () => Promise<PostAccessTokenResponseCamelCase>
    startOAuthFlow: () => Promise<void>
  }
}

interface OAuthParams {
  clientId: string
  // codeChallenge: string
  codeChallengeMethod: '' | 'S256'
  redirectUri: string
  responseType: '' | 'code'
}

interface AuthToken {
  accessToken: string | undefined
  refreshToken: string | undefined
}

interface AuthTokenAttributes {
  createdAt: number | undefined // The UNIX timestamp (in seconds) when the token was created
  expiresIn: number | undefined // The number of seconds after the createdAt time when the token will expire
  scope: string
  tokenType: string
}

interface AuthUri {
  authUri: string
  tokenUri: string
}

type InitParams = AuthUri & Omit<OAuthParams, 'codeChallenge'>

type State = AuthToken &
  AuthTokenAttributes &
  AuthUri &
  OAuthParams & {
    codeVerifier: string
    router: AppRouter | undefined
    tokenExpirationTimer: NodeJS.Timeout | undefined
  }

type Store = Actions & State

const initialState: State = {
  accessToken: undefined,
  authUri: '',
  clientId: '',
  codeChallengeMethod: '',
  codeVerifier: '', // needs to be persisted for the token request
  createdAt: undefined,
  expiresIn: undefined,
  redirectUri: '',
  refreshToken: undefined,
  responseType: '',
  router: undefined,
  scope: '',
  tokenExpirationTimer: undefined,
  tokenType: '',
  tokenUri: '',
}

const stateCreatorFn: StateCreator<Store> = (set, get) => ({
  ...initialState,
  actions: {
    _clearTokens: () => {
      const { tokenExpirationTimer } = get()
      if (tokenExpirationTimer) {
        clearTimeout(tokenExpirationTimer)
      }
      set({ accessToken: undefined, refreshToken: undefined })
    },
    _expiresAt: () => {
      const { accessToken, createdAt, expiresIn } = get()
      if (!accessToken || !createdAt || !expiresIn) {
        return undefined
      }
      return createdAt + expiresIn
    },
    _isExpired: () => {
      const { _expiresAt } = get().actions
      const expirationTime = _expiresAt()
      if (!expirationTime) {
        return true
      }
      return getCurrentTimeSec() >= expirationTime - REFRESH_TOKEN_DIFF_SEC
    },
    _refreshTokenAndUpdateSDK: async () => {
      const {
        actions: { refreshAccessToken, logout },
      } = get()

      try {
        const newToken = await refreshAccessToken()

        // Update the SignalWire SDK with the new token
        const { client } = useMainStore.getState()
        if (client) {
          logger.debug('Updating the SignalWire SDK with the new token', {
            newToken,
          })
          await client.updateToken(newToken.accessToken)
        }
      } catch (error) {
        logger.error('Unable to refresh the token', error)
        logout()
      }
    },
    _scheduleTokenRefresh: () => {
      const {
        tokenExpirationTimer,
        accessToken,
        refreshToken,
        actions: { _expiresAt, _isExpired, _refreshTokenAndUpdateSDK },
      } = get()

      const expirationTime = _expiresAt()

      if (!accessToken || !refreshToken || !expirationTime || _isExpired()) {
        logger.warn('Missing required parameters for scheduling token')
        return
      }

      const timeUntilExpirationMs =
        (expirationTime - getCurrentTimeSec() - REFRESH_TOKEN_DIFF_SEC) * 1000

      // Clear any existing timer
      clearTimeout(tokenExpirationTimer)

      // Set a timeout to refresh the token 2 minutes before it expires
      logger.debug(
        `Schedule token refresh after ${timeUntilExpirationMs / 1000} seconds`,
      )
      const timeout = setTimeout(
        _refreshTokenAndUpdateSDK,
        timeUntilExpirationMs,
      )

      set({ tokenExpirationTimer: timeout })
    },
    fetchAccessToken: async (code: string) => {
      const {
        clientId,
        codeVerifier,
        redirectUri,
        tokenUri,
        actions: { _scheduleTokenRefresh },
      } = get()

      if (!clientId || !codeVerifier || !redirectUri || !tokenUri) {
        throw new Error('Missing required OAuth parameters for fetching token')
      }

      /* eslint-disable camelcase */
      // fetch the SignalWire Access Token (SAT)
      const resTokenSAT = await httpService.postAccessToken({
        client_id: clientId,
        code: code,
        code_verifier: codeVerifier,
        grant_type: 'authorization_code',
        redirect_uri: redirectUri,
      })

      set({
        accessToken: resTokenSAT.access_token,
        createdAt: resTokenSAT.created_at,
        expiresIn: resTokenSAT.expires_in,
        refreshToken: resTokenSAT.refresh_token,
        scope: resTokenSAT.scope,
        tokenType: resTokenSAT.token_type,
      })

      // Set up the token expiry monitoring
      _scheduleTokenRefresh()
    },
    init: (params: InitParams) => set(params),
    isAuthenticated: async () => {
      const {
        accessToken,
        actions: { _isExpired, _refreshTokenAndUpdateSDK },
      } = get()

      if (!accessToken) {
        return false
      }

      if (_isExpired()) {
        await _refreshTokenAndUpdateSDK()
      }

      return !_isExpired()
    },
    logout: () => {
      const {
        router,
        actions: { _clearTokens },
      } = get()

      // Clear tokens in this store
      _clearTokens()

      if (router) {
        void router.navigate({ to: '/login' })
        return true
      }
      return false
    },
    refreshAccessToken: async () => {
      const {
        clientId,
        refreshToken,
        tokenUri,
        actions: { _scheduleTokenRefresh },
      } = get()

      if (!clientId || !refreshToken || !tokenUri) {
        throw new Error(
          'Missing required OAuth parameters for refreshing token',
        )
      }

      // fetch the SignalWire Access Token (SAT)
      const resTokenSAT = await httpService.postRefreshToken({
        client_id: clientId,
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      })

      const refreshResponse = {
        accessToken: resTokenSAT.access_token,
        createdAt: resTokenSAT.created_at,
        expiresIn: resTokenSAT.expires_in,
        refreshToken: resTokenSAT.refresh_token,
        scope: resTokenSAT.scope,
        tokenType: resTokenSAT.token_type,
      } satisfies PostAccessTokenResponseCamelCase
      /* eslint-enable camelcase */
      set(refreshResponse)

      // Set up the token expiry monitoring
      _scheduleTokenRefresh()

      return refreshResponse
    },
    startOAuthFlow: async () => {
      const {
        authUri,
        clientId,
        codeChallengeMethod,
        redirectUri,
        responseType,
      } = get()

      // validate required params
      if (
        !authUri ||
        !clientId ||
        !codeChallengeMethod ||
        !redirectUri ||
        !responseType
      ) {
        throw new Error('Missing required OAuth parameters')
      }

      // generate the code_challenge and save it to sessionStorage
      const { codeChallenge, codeVerifier } = await generatePKCE()

      // the codeVerifier is persisted in localStorage
      set({ codeVerifier: codeVerifier })

      /* eslint-disable camelcase */
      const authorizationUrl = buildUrlWithParams({
        searchParams: {
          client_id: clientId,
          code_challenge: codeChallenge,
          code_challenge_method: codeChallengeMethod,
          redirect_uri: redirectUri,
          response_type: responseType,
        },
        url: authUri,
      })
      /* eslint-enable camelcase */
      // redirect to the authorization url
      logger.debug(`starting oauth at: ${authorizationUrl}`)
      window.location.assign(authorizationUrl)
    },
  },
})

// some keys we may want to persist in localStorage for auth and allow refresh of the token
const partialize = (state: State) => {
  type KeysToPersist = keyof Pick<
    State,
    | 'accessToken'
    | 'authUri'
    | 'clientId'
    | 'codeChallengeMethod'
    | 'codeVerifier'
    | 'createdAt'
    | 'expiresIn'
    | 'redirectUri'
    | 'refreshToken'
    | 'tokenUri'
  >
  return typeSafeFilterObject({ ...state }, key => {
    const persistKeys = [
      'accessToken',
      'authUri',
      'clientId',
      'codeChallengeMethod',
      'codeVerifier',
      'createdAt',
      'expiresIn',
      'redirectUri',
      'refreshToken',
      'tokenUri',
    ] satisfies KeysToPersist[]
    return persistKeys.includes(key as KeysToPersist)
  }) as Store
}

// export const useAuthStore = create<Store>()(stateCreatorFn)
export const useAuthStore = create<Store>()(
  persist<Store>(stateCreatorFn, {
    name: 'sw_puc_sat',
    onRehydrateStorage: () => state => {
      if (state) {
        void state.actions._scheduleTokenRefresh()
      }
    },
    partialize: state => partialize(state),
    storage: createJSONStorage(() => localStorage),
  }),
)
export const useAuthStoreActions = () => useAuthStore.getState().actions

// utility for navigating to a route
// TODO: move this to a separate file or elsewhere?
export const navigateRoute = (params: NavigateRouteParams) => {
  const { router } = useAuthStore.getState()
  if (!router) {
    throw new Error('Router not found in the store')
  }
  return router.navigate(params)
}

// Expose the store to be used from the console
window.__authStore = useAuthStore
