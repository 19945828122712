import type { ResourceType } from '@signalwire/js'
import { Contact } from '@/components/Contact'
import { RecentContacts } from '@/components/RecentContacts'
import { Search } from '@/components/Search'
import {
  UnorderedList,
  UnorderedListBottom,
  UnorderedListItem,
} from '@/components/UnorderedList'
import { Button } from '@/components/base/button'
import { ScrollArea } from '@/components/base/scroll-area'
import { Icon } from '@/components/common/icons/Icon'
import { useSimpleSearch } from '@/hooks/useSimpleSearch'
import { useResourcesStore, useResourcesStoreActions } from '@/stores/resources'
import { useUiStoreActions } from '@/stores/ui'
import { useMemo, useState } from 'react'
import { useAuthStore } from '@/stores/auth'
import { PSTN_PREFIX, SIP_PREFIX, DEFAULT_CONTEXT } from '@/helpers/constants'

// TODO: Centralize supported types. Same for other pages?
const supportedTypes = ['app', 'room', 'subscriber'] as const satisfies Exclude<
  ResourceType,
  'call'
>[]
type SupportedTypes = (typeof supportedTypes)[number]

const isAudioOnlyTerm = (searchTerm?: string) =>
  searchTerm?.startsWith(PSTN_PREFIX)

const isKnownPrefix = (searchTerm?: string) =>
  searchTerm?.startsWith(PSTN_PREFIX) || searchTerm?.startsWith(SIP_PREFIX)

const searchTermToDestinationUri = (searchTerm?: string) => {
  if (isKnownPrefix(searchTerm)) return `/${DEFAULT_CONTEXT}/${searchTerm}`
  return searchTerm
}

const navigateToSearch = (searchTerm?: string) => {
  const router = useAuthStore.getState().router

  const toURI = searchTermToDestinationUri(searchTerm)

  // TODO log with a logger a warning
  if (!toURI) return

  void router?.navigate({ to: `/room/${toURI}` })
}

export const DirectoryView = () => {
  const [contactId, setContactId] = useState('')
  const resourcesByIdMap = useResourcesStore(state => state.resourcesByIdMap)
  const { getResourcesFromMap } = useResourcesStoreActions()
  const { showContact } = useUiStoreActions()

  const resources = getResourcesFromMap(resourcesByIdMap)

  // TODO: use localeCompare {sensitivity: 'base'} to check and move to util if needed
  const sortedResources = useMemo(() => {
    const resourcesSortable = resources
      .filter(room => supportedTypes.includes(room.type as SupportedTypes))
      .map(room => {
        return { ...room, displayNameSortable: room.displayName.toLowerCase() }
      })

    // Initial sort is by displayName
    const resourcesSorted = resourcesSortable.sort((a, b) =>
      a.displayNameSortable.localeCompare(b.displayName),
    )

    return resourcesSorted
  }, [resources])

  const { searchResults, handleSearch, handleSearchReset, searchTerm } =
    useSimpleSearch({
      items: sortedResources,
      key: 'displayName',
    })

  const handleClick = (id: string) => {
    setContactId(id)
    showContact()
  }

  const resetContactId = () => {
    setContactId('')
  }

  return (
    <div className="flex h-full">
      <section className="flex flex-col">
        <div className="flex flex-col gap-y-1 pb-3.5 pl-6 pr-5 pt-2">
          <div className="flex items-center justify-between">
            <h2 className="text-4xl font-semibold leading-normal">Directory</h2>
            <Button
              className="bg-background p-0 text-foreground"
              disabled
              variant="icon"
            >
              <span className="sr-only">Sort by Alphabetical Order</span>
              <Icon tag="sort-by-alpha" size="xl" />
            </Button>
          </div>
          <Search
            formDescription="Search for a directory item."
            onChange={handleSearch}
            onReset={handleSearchReset}
            onSubmit={handleSearch}
            placeholder="Search Directory"
          />
        </div>
        <div className="flex h-full overflow-y-hidden">
          <ScrollArea>
            <UnorderedList className="w-80">
              {searchResults.map(({ displayName, id }) => (
                <UnorderedListItem
                  className={`relative min-h-10
              before:absolute before:inset-0 before:w-2 before:transition-colors
              hover:bg-neutral-light hover:text-primary-light
              ${contactId === id ? 'before:bg-primary-light' : 'before:bg-transparent'}`}
                  key={id}
                >
                  {/* TODO: resource type needed or no?
                <ResourceTypeIcon
                  className="shrink-0"
                  resourceType={type}
                  size="xl"
                  variant="foreground"
                /> */}
                  <p className="line-clamp-2 flex-grow">{displayName}</p>
                  {/* TODO: click to call the resource with the correct video/audio channel */}
                  <Button
                    className="shrink p-0"
                    onClick={() => handleClick(id)}
                    variant="icon"
                  >
                    <Icon
                      tag="keyboard-right-arrow"
                      size="lg"
                      variant="foreground"
                    />
                    <span className="sr-only">Show Resource</span>
                  </Button>
                </UnorderedListItem>
              ))}
              {!searchResults.length && (
                <>
                  <div className="flex basis-full flex-col items-center justify-center">
                    <h2>Address not found</h2>
                    <p className="pl-5 pr-5 pt-5 text-sm">
                      No results found for
                    </p>
                    <p className="pl-5 pr-5 text-sm">{searchTerm}</p>
                    <p className="pl-5 pr-5 pt-5 text-sm">
                      in the directory list.
                    </p>
                    <p className="p-5 text-sm">Do you want to dial anyway?</p>
                    {!isAudioOnlyTerm(searchTerm) && (
                      <div className="pb-5">
                        <Button
                          variant="gradient"
                          className="shrink"
                          onClick={() =>
                            navigateToSearch(`${searchTerm}?channel=video`)
                          }
                        >
                          Video
                        </Button>
                      </div>
                    )}
                    <div className="pb-5">
                      <Button
                        variant="gradient"
                        className="shrink"
                        onClick={() =>
                          navigateToSearch(`${searchTerm}?channel=audio`)
                        }
                      >
                        Audio
                      </Button>
                    </div>
                  </div>
                </>
              )}
              <UnorderedListBottom>End of List</UnorderedListBottom>
            </UnorderedList>
          </ScrollArea>
        </div>
      </section>
      <div className="flex basis-full flex-col items-center justify-center bg-neutral-light">
        {contactId ? (
          <>
            {/* TODO: use router to update the path or param based on selected contact? */}
            <Contact onClose={resetContactId} resourceId={contactId} />
            <RecentContacts resourceId={contactId} />
          </>
        ) : (
          <p className="text-center text-sm font-normal text-secondary">
            Select an entry
          </p>
        )}
      </div>
    </div>
  )
}
