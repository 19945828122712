import { createRouter } from '@tanstack/react-router'
import { ErrorView } from '@/components/ErrorView'
import { Loading } from '@/components/Loading'
import { ERROR_TYPE } from '@/helpers/constants'
import { routeTree } from './routeTree.gen'

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

export const router = createRouter({
  context: { title: '' },
  defaultErrorComponent: ({ error }) => (
    <ErrorView error={error} errorType={ERROR_TYPE.UNKNOWN} />
  ),
  defaultNotFoundComponent: () => (
    <ErrorView errorType={ERROR_TYPE.NOT_FOUND} />
  ),
  defaultPendingComponent: () => <Loading open />,
  routeTree: routeTree,
})
