import { ButtonDnD } from '@/components/ButtonDnD'
import { ButtonRaiseHand } from '@/components/ButtonRaiseHand'
import { ButtonShareScreen } from '@/components/ButtonShareScreen'
import { LinkLeave } from '@/components/LinkLeave'
import { TEST_IDS } from '@/helpers/constants'
import type { RedirectPath } from '@/helpers/types'
import { useRoomStore } from '@/stores/room'
import { ButtonMicrophone } from './ButtonMicrophone'
import { ButtonVideo } from './ButtonVideo'
import { useFooterButtonCapacity } from '@/hooks/useFooterButtonCapacity'
import { useDNDButtonState } from '@/hooks/useDNDButtonState'
import { useMicrophoneButtonState } from '@/hooks/useMicrophoneButtonState'
import { useHandRaiseButtonState } from '@/hooks/useRaiseHandButtonState'
import { useScreenShareButtonState } from '@/hooks/useScreenShareButtonState'
import { useVideoButtonState } from '@/hooks/useVideoButtonState'
import { RoomFooterInline } from '@/components/RoomFooterInline'

export interface RoomFooterProps {
  redirect?: RedirectPath | undefined
}

export const RoomFooter = ({ redirect }: RoomFooterProps) => {
  const memberState = useRoomStore(state => state.memberState)

  const { isDNDButtonEnabled, isDNDButtonVisible } = useDNDButtonState()

  const { isRaiseHandButtonEnabled, isRaiseHandButtonVisible } =
    useHandRaiseButtonState()

  const { isMicrophoneButtonVisible, isMicrophoneEnabled } =
    useMicrophoneButtonState()

  const { isScreenShareButtonEnabled, isScreenShareButtonVisible } =
    useScreenShareButtonState()

  const { isVideoButtonEnabled, isVideoButtonVisible } = useVideoButtonState()

  // how many buttons can fit in the footer at once
  const totalFooterButtonCount = useFooterButtonCapacity()

  //  check if only audio and video visible to show 2 btn audio/video layout
  const isOnlyAudioAndVideoVisible =
    isMicrophoneButtonVisible &&
    isVideoButtonVisible &&
    !isScreenShareButtonVisible &&
    !isDNDButtonVisible &&
    !isRaiseHandButtonVisible

  // to count the number of visible buttons
  const visibleButtonsCount = [
    isDNDButtonVisible,
    isMicrophoneButtonVisible,
    isRaiseHandButtonVisible,
    isScreenShareButtonVisible,
    isVideoButtonVisible,
  ].filter(Boolean).length

  // display options if more than 2 buttons, or if more buttons than available space
  const showOptionsButton =
    visibleButtonsCount > 2 && visibleButtonsCount > totalFooterButtonCount

  const showVideoButtonLeft =
    !isOnlyAudioAndVideoVisible && isVideoButtonVisible

  // TODO: conditionally render a separate audio/video only React component?
  const showVideoButtonRight =
    isOnlyAudioAndVideoVisible && isVideoButtonVisible

  const memberJoined = memberState === 'joined'

  return (
    <div
      aria-label="Room toolbar"
      className="relative flex justify-center gap-x-24 bg-primary pt-1.5"
      data-testid={TEST_IDS.ROOM_CONTROLS}
      role="toolbar"
    >
      {showOptionsButton ? (
        <RoomFooterInline
          redirect={redirect}
          totalFooterButtonCount={totalFooterButtonCount}
        />
      ) : (
        <>
          <div
            aria-label="Call controls"
            className="flex w-2/5 items-center justify-end gap-x-2"
            role="group"
          >
            <ButtonMicrophone
              disabled={!memberJoined || !isMicrophoneEnabled}
              isVisible={!showOptionsButton && isMicrophoneButtonVisible}
            />
            <ButtonVideo
              disabled={!memberJoined || !isVideoButtonEnabled}
              isVisible={!showOptionsButton && showVideoButtonLeft}
            />
          </div>
          <LinkLeave
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-full"
            to={redirect}
            variant="rounded"
          >
            Leave
          </LinkLeave>
          <div
            aria-label="Additional call controls"
            className="flex w-2/5 items-center gap-x-2"
            role="group"
          >
            <ButtonVideo
              disabled={!memberJoined || !isVideoButtonEnabled}
              isVisible={!showOptionsButton && showVideoButtonRight}
            />
            <ButtonRaiseHand
              disabled={!memberJoined || !isRaiseHandButtonEnabled}
              isVisible={!showOptionsButton && isRaiseHandButtonVisible}
            />
            <ButtonShareScreen
              disabled={!memberJoined || !isScreenShareButtonEnabled}
              isVisible={!showOptionsButton && isScreenShareButtonVisible}
            />
            <ButtonDnD
              // TODO: implement DND button functionality
              disabled={true || !isDNDButtonEnabled}
              isVisible={!showOptionsButton && isDNDButtonVisible}
            />
          </div>
        </>
      )}
    </div>
  )
}
