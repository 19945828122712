import type { ComponentPropsWithRef } from 'react'
import { forwardRef, type FunctionComponent } from 'react'
import { SWLogoTypeIcon } from './SWLogoTypeIcon'

type SvgProps = ComponentPropsWithRef<'svg'> & {
  tag: 'sw-logotype'
}

const svgMap = {
  'sw-logotype': SWLogoTypeIcon,
} as const satisfies Record<'sw-logotype', FunctionComponent>

export const Svg = forwardRef<SVGSVGElement, SvgProps>(function inner(
  { className = '', tag, ...rest },
  ref,
) {
  const SvgIcon = svgMap[tag]

  if (!SvgIcon) {
    console.debug(`Unknown SVG: ${tag}`)
    return null
  }

  return (
    <SvgIcon
      aria-hidden="true"
      className={className}
      focusable="false"
      ref={ref}
      role="img"
      {...rest}
    />
  )
})
