import { useLayoutEffect, useState } from 'react'

interface UseScrollToBottomProps {
  scrollContainerElement: HTMLElement | null
}
/**
 * @description - scroll to the bottom of the `scrollContainerElement`
 * when `forceScrollToBottom` is `true`
 * - useful for scrolling to the bottom of the chat window when sending a new message
 */
export const useScrollToBottom = ({
  scrollContainerElement,
}: UseScrollToBottomProps) => {
  const [forceScrollToBottom, setForceScrollToBottom] = useState(true)

  useLayoutEffect(() => {
    // NOTE: Scrolls to the bottom of the container when sending a new message
    if (forceScrollToBottom && scrollContainerElement) {
      scrollContainerElement?.scrollTo({
        behavior: 'auto',
        top: scrollContainerElement.scrollHeight,
      })

      setForceScrollToBottom(false)
    }
  }, [forceScrollToBottom, scrollContainerElement])

  return { forceScrollToBottom, setForceScrollToBottom }
}
