import { useCallback, useEffect, useRef } from 'react'
import { useParams, useRouter, useSearch } from '@tanstack/react-router'
import { useCallStore } from '@/stores/call'
import { FEATURES } from '@/stores/features'
import { useRoomStore, useRoomStoreActions } from '@/stores/room'
import { useUserSettingsStoreActions } from '@/stores/userSettings'
import { logger } from '@/logger/createLogger'
import { AudioCallControls } from '@/components/AudioCallControls'
import { CardLoading } from '@/components/CardLoading'
import { Feature } from '@/components/Feature'
import { MemberOverlays } from '@/components/MemberOverlays'
import { RoomSessionTimer } from '@/components/RoomSessionTimer'
import {
  ENV_VARS,
  INCOMING_CALL_CONTEXT,
  ROOT_ELEMENT_ID_MCU,
} from '@/helpers/constants'
import {
  dispatchHandleRoomJoinErrorDialog,
  dispatchMissingContextErrorDialog,
} from '@/helpers/errorDialogMessages'
import type { ChannelType, RedirectPath } from '@/helpers/types'
import { cn } from '@/helpers/utils'

interface PathParams {
  context: string
  name: string
}
interface SearchParams {
  channel?: ChannelType
  redirect: RedirectPath
}

export const RoomView = () => {
  const rootMcuRef = useRef<HTMLDivElement>(null)

  // TODO: fix typecasting caused by the C2C router not using the same path
  const { context, name } = useParams({ strict: false }) as PathParams
  const { channel, redirect } = useSearch({ strict: false }) as SearchParams

  const initialized = useRef<boolean>(false)

  const currentNotification = useCallStore(state => state.currentNotification)

  const memberState = useRoomStore(state => state.memberState)
  const isAudioOnly = useRoomStore(state => state.isAudioOnly)

  const { joinRoom, startRoomSession } = useRoomStoreActions()

  const {
    getPreferredMicrophoneDeviceForDialing,
    getPreferredVideoDeviceForDialing,
  } = useUserSettingsStoreActions()

  const { navigate } = useRouter()

  const joinThisRoom = useCallback(
    async (context: string) => {
      if (rootMcuRef.current === null) {
        return
      }
      if (context === INCOMING_CALL_CONTEXT) {
        currentNotification?.invite
          .accept({
            audio: getPreferredMicrophoneDeviceForDialing(),
            rootElement: rootMcuRef.current,
            video: getPreferredVideoDeviceForDialing(),
          })
          .then(roomSession => {
            void startRoomSession(roomSession)
          })
          .catch(error => {
            dispatchHandleRoomJoinErrorDialog({
              onConfirm: () => {
                void navigate({ to: redirect || ENV_VARS.DEFAULT_NAV_ROUTE })
              },
            })
            logger.error('Error joining room with reattach', error)
          })
      } else {
        await joinRoom({
          channel: channel,
          context: context,
          name: name,
          redirect: redirect,
          rootElement: rootMcuRef.current,
        }).catch(error => {
          dispatchHandleRoomJoinErrorDialog({
            onConfirm: () => {
              void navigate({ to: redirect || ENV_VARS.DEFAULT_NAV_ROUTE })
            },
          })
          logger.error('Error joining room', error)
        })
      }
    },
    [
      channel,
      currentNotification,
      getPreferredMicrophoneDeviceForDialing,
      getPreferredVideoDeviceForDialing,
      joinRoom,
      name,
      redirect,
      rootMcuRef,
      startRoomSession,
      navigate,
    ],
  )

  const viewElementRef = useRef(null)

  useEffect(() => {
    // Run only once so that the room is not rejoined
    if (initialized.current || !rootMcuRef.current || memberState !== 'ready') {
      return
    }
    initialized.current = true

    // TODO: the router schema should prevent this from happening and reject loading the view
    // maybe it can be handled in the beforeLoad or loader functions
    if (!context || !name) {
      logger.error('A parameter was missing when entering the room')

      const missingParam = !context ? 'context' : 'name'
      dispatchMissingContextErrorDialog({
        onConfirm: () => {
          void navigate({ to: redirect || ENV_VARS.DEFAULT_NAV_ROUTE })
        },
        type: missingParam,
      })
      return
    }

    void joinThisRoom(context)
  }, [context, joinThisRoom, memberState, name, redirect, navigate])

  const handleCancel = async () => {
    // FIXME: leave and ensure the room is cleaned up and the room session is ended
  }

  // TODO: is this still necessary? or can it be removed?,
  // maybe it can be handled in the beforeLoad or loader functions
  if (!context?.trim() || !name?.trim()) {
    // TODO: error message displayed in video area?
    return null
  }

  return (
    <>
      <Feature name={FEATURES.ROOM_LOADING_UI}>
        {memberState !== 'joined' && (
          // NOTE: translate the loading card in the same position as router's pendingComponent to avoid layout shift
          <div className="absolute left-0 top-0 z-10 flex h-full w-full -translate-y-7 flex-col items-center justify-center overflow-hidden">
            <CardLoading
              cardTitle="Connecting"
              cardDescription={`Joining ${context}/${name}`}
              // TODO: add cancel label after implementing handleCancel
              confirmLabel=""
              onConfirm={handleCancel}
              open
            />
          </div>
        )}
      </Feature>

      {isAudioOnly && memberState === 'joined' && (
        <div className="flex h-full w-full flex-col items-center justify-center gap-y-20 bg-primary">
          <RoomSessionTimer startTimer />
          <AudioCallControls redirect={redirect} />
        </div>
      )}

      <div
        className={cn(
          'flex h-full flex-col overflow-hidden',
          (isAudioOnly || memberState !== 'joined') && 'hidden',
        )}
      >
        <RoomSessionTimer
          className="gradient flex w-full shrink-0 justify-center text-sm font-medium"
          startTimer
        />
        <div className="h-full overflow-hidden" ref={viewElementRef}>
          <MemberOverlays />
          <div id={ROOT_ELEMENT_ID_MCU} ref={rootMcuRef} />
        </div>
      </div>
    </>
  )
}
