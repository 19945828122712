import type { InternalVideoLayout, VideoPosition } from '@signalwire/js'
import { sortBy, startCase } from './utils'

export const LAYOUT_MAP = {
  '1+1x2zoom': {
    displayName: '1+1x2zoom',
    layoutName: '1+1x2zoom',
    sortKey: 10,
    tags: ['grid'],
  },
  '10x10': {
    displayName: '10x10',
    layoutName: '10x10',
    sortKey: 9,
    tags: ['grid'],
  },
  '1x1': {
    displayName: '1x1',
    layoutName: '1x1',
    sortKey: 1,
    tags: ['grid'],
  },
  '2x1': {
    displayName: '2x1',
    layoutName: '2x1',
    sortKey: 2,
    tags: ['grid'],
  },
  '2x1zoom': {
    displayName: '2x1zoom',
    layoutName: '2x1zoom',
    sortKey: 11,
    tags: ['grid'],
  },
  '2x2': {
    displayName: '2x2',
    layoutName: '2x2',
    sortKey: 3,
    tags: ['grid', 'speaker'],
  },
  '3x3': {
    displayName: '3x3',
    layoutName: '3x3',
    sortKey: 4,
    tags: ['grid'],
  },
  '4x4': {
    displayName: '4x4',
    layoutName: '4x4',
    sortKey: 5,
    tags: ['grid'],
  },
  '5up': {
    displayName: '5up',
    layoutName: '5up',
    sortKey: 12,
    tags: ['special-grid'],
  },
  '5x5': {
    displayName: '5x5',
    layoutName: '5x5',
    sortKey: 6,
    tags: ['grid'],
  },
  '6x6': {
    displayName: '6x6',
    layoutName: '6x6',
    sortKey: 7,
    tags: ['grid'],
  },
  '8x8': {
    displayName: '8x8',
    layoutName: '8x8',
    sortKey: 8,
    tags: ['grid'],
  },
  'full-screen': {
    displayName: 'Full-Screen',
    layoutName: 'full-screen',
    sortKey: 13,
    tags: ['special-grid'],
  },
  'grid-responsive': {
    displayName: 'Responsive',
    layoutName: 'grid-responsive',
    sortKey: 14,
    tags: ['grid'],
  },
  'grid-responsive-mobile': {
    displayName: 'Responsive Mobile',
    layoutName: 'grid-responsive-mobile',
    sortKey: 15,
    tags: ['grid'],
  },
  'highlight-1-responsive': {
    displayName: 'Highlight 1',
    layoutName: 'highlight-1-responsive',
    sortKey: 16,
    tags: ['speaker', 'audience', 'special-grid'],
  },
  'screen-share': {
    displayName: 'Screenshare 1',
    layoutName: 'screen-share',
    sortKey: 17,
    tags: ['party', 'screenshare'],
  },
  'screen-share-2': {
    displayName: 'Screenshare 2',
    layoutName: 'screen-share-2',
    sortKey: 18,
    tags: ['party', 'screenshare', 'audience'],
  },
} as const satisfies LayoutMap

export type LayoutTag =
  | 'audience'
  | 'grid'
  | 'party'
  | 'screenshare'
  | 'speaker'
  | 'special-grid'

export const LayoutNames = [
  '1+1x2zoom',
  '10x10',
  '1x1',
  '2x1',
  '2x1zoom',
  '2x2',
  '3x3',
  '4x4',
  '5up',
  '5x5',
  '6x6',
  '8x8',
  'full-screen',
  'grid-responsive',
  'grid-responsive-mobile',
  'highlight-1-responsive',
  'screen-share',
  'screen-share-2',
] as const

export type LayoutName = (typeof LayoutNames)[number]

export interface LayoutRecord<TName> {
  displayName: string
  layoutName: TName
  sortKey: number
  tags: LayoutTag[]
}

type LayoutMap = {
  [key in LayoutName]: LayoutRecord<key>
}

export type Layout = LayoutRecord<LayoutName>

export interface LayoutPosition {
  displayName: string
  positionName: VideoPosition
}

export const sortLayouts = (layouts: Layout[]) => {
  return sortBy(layouts, 'asc', 'sortKey')
}

export const mapLayoutPosition = (
  layer: InternalVideoLayout['layers'][number],
) => {
  return {
    displayName: startCase(layer.position),
    positionName: layer.position,
  }
}
