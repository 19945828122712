import { AudioMeter } from '@/components/AudioMeter'
import { WebRTC } from '@signalwire/js'
import { useEffect, useState } from 'react'

const MAX_ANALYZER_VOLUME = 100

type DestroyedReason = 'disconnected' | 'error' | null

interface MicrophoneMeterProps {
  microphoneId?: string | undefined
  size?: number
}

export const MicrophoneMeter = ({
  size = 10,
  microphoneId,
}: MicrophoneMeterProps) => {
  const [meterValue, setMeterValue] = useState<number | undefined>()

  useEffect(() => {
    const analyzerPromises: Promise<WebRTC.MicrophoneAnalyzer>[] = []

    const createAnalyzer = async (deviceId: string | undefined) => {
      try {
        // The analyzer promise takes to time resolve. Store this in the ref for proper cleanup
        const analyzerPromise = WebRTC.createMicrophoneAnalyzer(deviceId ?? '')

        analyzerPromises.push(analyzerPromise)

        const analyzer = await analyzerPromise
        // Set up analyzer events
        analyzer.on('volumeChanged', (vol: number) => {
          setMeterValue(Math.ceil((vol * size) / MAX_ANALYZER_VOLUME))
        })
        analyzer.on('destroyed', (reason: DestroyedReason) => {
          if (reason !== null) {
            console.error(`Microphone analyzer ${reason}`)
          }
        })
      } catch (error) {
        console.error('Microphone Analyzer', error)
        throw error
      }
    }

    createAnalyzer(microphoneId).catch(error => {
      console.error('Error creating analyzer:', error)
    })

    return () => {
      // Clean up all analyzers
      analyzerPromises.forEach(async analyzerPromise => {
        try {
          const analyzer = await analyzerPromise
          console.log('Destroying analyzer')
          analyzer?.destroy()
        } catch (error) {
          console.error('Error destroying analyzer:', error)
        }
      })
    }
  }, [microphoneId, size])

  return <AudioMeter maxValue={size} type="microphone" value={meterValue} />
}
