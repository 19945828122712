import type { ReactNode } from 'react'
import { useUiStore, useUiStoreActions } from '@/stores/ui'
import { cn } from '@/helpers/utils'
import { Button } from '@/components/base/button'
import { Icon } from '@/components/common/icons/Icon'
import { PanelRoomChat } from '@/components/PanelRoomChat'
import { PanelRoomOptions } from '@/components/PanelRoomOptions'
import { PanelRoomParticipants } from '@/components/PanelRoomParticipants'
import { PanelRoomNavigator } from '@/components/PanelRoomNavigator'
import { PanelRoomLayouts } from '@/components/PanelRoomLayouts'

// Types
import type { RoomPanelType } from '@/stores/ui'

interface PanelInfo {
  component: () => ReactNode
  parentPanelType?: RoomPanelType
  title: string
}

const panels: Record<RoomPanelType, PanelInfo> = {
  chat: { component: PanelRoomChat, title: 'Chat' },
  layout: {
    component: PanelRoomLayouts,
    parentPanelType: 'options',
    title: 'Layout Options',
  },
  navigator: {
    component: PanelRoomNavigator,
    parentPanelType: 'options',
    title: 'Change Rooms',
  },
  options: { component: PanelRoomOptions, title: 'Options' },
  participants: { component: PanelRoomParticipants, title: 'Participants' },
}

export const PanelRoom = () => {
  const { closeRoomPanel, hideRoomPanel, setRoomPanelType } =
    useUiStoreActions()

  const displayRoomPanel = useUiStore(state => state.displayRoomPanel)
  const panelType = useUiStore(state => state.roomPanelType)
  const roomPanelOpen = useUiStore(state => state.roomPanelOpen)

  const panelContent = panelType ? panels[panelType] : null

  // Use the title override from the store if available
  const title = useUiStore(state => state.roomPanelTitle) || panelContent?.title

  const ContentComponent = panelContent?.component
  const displayBackButton = Boolean(panelContent?.parentPanelType)

  const handleBack = () => {
    if (panelContent?.parentPanelType) {
      setRoomPanelType(panelContent.parentPanelType)
    }
  }

  const handleAnimationEnd = () => {
    // Only take action once the animation is completed
    if (!roomPanelOpen) {
      hideRoomPanel()
    }
  }

  if (!displayRoomPanel || !ContentComponent) {
    return null
  }

  // TODO: handle key events: onEscapeKeyDown, onInteractOutside, onOpenAutoFocus?
  return (
    <div
      // At mobile width, the panel should be full width
      // At small widths, the panel should cover the video (absolute)
      // At large widths, squeeze the video
      aria-labelledby="room-panel-title"
      className={cn(
        'absolute inset-y-0 right-0 z-50 h-full max-w-[360px] overflow-hidden',
        'md:relative md:inset-y-auto md:right-auto',
        'transition-[width] duration-300 ease-in-out',
        'motion-reduce:transition-none',
        'shrink-0 grow-0',
        'data-[state=open]:ml-5 data-[state=closed]:w-0 data-[state=open]:w-[344px]',
        'rounded-tl-2xl bg-background py-4 pr-0 text-secondary shadow-lg data-[state=open]:border-l data-[state=open]:pl-4',
      )}
      data-testid="room-panel"
      data-state={roomPanelOpen ? 'open' : 'closed'}
      onAnimationEnd={handleAnimationEnd}
      onKeyDown={event => {
        if (event.key === 'Escape') {
          hideRoomPanel()
        }
      }}
      role="dialog"
    >
      <div className="relative flex h-full w-[326px] flex-col space-y-2">
        <div className="m-0 flex shrink-0 flex-row items-center pb-3">
          {displayBackButton && (
            <Button
              variant="icon"
              onClick={handleBack}
              className="m-0 me-2 h-0 p-0"
            >
              <Icon tag="arrow-back" variant="foreground" size="xl" />
              <span className="sr-only">Back</span>
            </Button>
          )}
          <h2 className="text-title text-foreground" id="room-panel-title">
            {title}
          </h2>
        </div>
        <div className="h-full overflow-hidden">
          <ContentComponent />
        </div>
        <Button
          className="absolute -top-3 right-0"
          onClick={closeRoomPanel}
          size="icon"
          variant="icon"
        >
          <Icon size="xl" tag="close" variant="foreground" />
          <span className="sr-only">Close</span>
        </Button>
      </div>
    </div>
  )
}
