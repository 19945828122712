interface BuildUrlParams {
  searchParams?: Record<string, string> | undefined
  url: string
}
export const buildUrlWithParams = ({ url, searchParams }: BuildUrlParams) => {
  const result = new URL(url)

  if (searchParams) {
    Object.entries(searchParams).forEach(([key, value]) => {
      // needs to be a string
      if (typeof value !== 'string') {
        throw Error(
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `Invalid value for search param: key ${key}, value ${value as unknown}`,
        )
      }

      if (result.searchParams.get(key) !== null) {
        throw Error(`Duplicate search param: key ${key}`)
      }

      if (value != undefined) {
        result.searchParams.append(key, value)
      }
    })
  }
  return result.toString()
}

const getBody = (body: unknown) => {
  return typeof body === 'string' ? body : JSON.stringify(body)
}

export const buildRequestInit = <T extends RequestInit>(options: T) => {
  return Object.entries(options).reduce(
    (reducer: RequestInit, [key, value]) => {
      if (key === 'body') {
        reducer.body = getBody(value)
      } else if (value != undefined) {
        // @ts-expect-error - key/value should be a valid RequestInit key value pair
        reducer[key] = value as unknown
      }
      return reducer
    },
    {} satisfies RequestInit,
  )
}
