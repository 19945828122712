// generate code_challenge as per PKCE spec https://www.rfc-editor.org/rfc/rfc7636#section-4.2
export const generateCodeChallenge = async (codeVerifier: string) => {
  if (!window.crypto.subtle.digest) {
    console.warn(`digest() not defined on window.crypto.subtle`)
  }

  const hashDigest = await window.crypto.subtle.digest(
    'SHA-256',
    new TextEncoder().encode(codeVerifier),
  )
  const base64 = btoa(String.fromCharCode(...new Uint8Array(hashDigest)))
  const base64url = base64
    .replace(/\//g, '_')
    .replace(/\+/g, '-')
    .replace(/=/g, '')
  return base64url
}

// generate cryptographically secure random string
export const createRandomString = (length: number) => {
  if (length <= 0) {
    throw new Error(
      `Invalid length: ${length} for random string, must be greater than 0`,
    )
  }

  const chars =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-._~'
  const randomArray = new Uint8Array(length)
  const randomUnits = crypto.getRandomValues(randomArray)
  const randomString = randomUnits.reduce(
    (result, number) => `${result}${chars[number % chars.length]}`,
    '',
  )
  return randomString
}

// generate a new code_verifier and code_challenge for oauth2 PKCE
export const generatePKCE = async () => {
  // the code_verifier is a cryptographically random string
  const codeVerifier = createRandomString(64)

  // the code_challenge is a base64url encoding of the SHA-256 hash of the code_verifier
  const codeChallenge = await generateCodeChallenge(codeVerifier)

  return { codeChallenge, codeVerifier }
}
