import i18n, { t } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { ENV_VARS } from '../helpers/constants'
import type { Label } from './labels'
import EN_US from './locales/en-US/translations.json'

const detectorOptions = {
  // order and from where user language should be detected
  order: [
    'querystring',
    'cookie',
    'localStorage',
    'sessionStorage',
    'navigator',
    'htmlTag',
    'path',
    'subdomain',
  ],

  // keys or params to lookup language from
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupQuerystring: 'lng',
  lookupSessionStorage: 'i18nextLng',

  // cache user language
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
  // cookieMinutes: 10
  // cookieDomain: 'myDomain'
}

const resources = {
  'en-US': {
    translation: EN_US,
  },
}

export const i18nInit = async () => {
  await i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      debug: ENV_VARS.IS_DEVELOPMENT,
      detection: detectorOptions,
      fallbackLng: 'en-US',
      // fallbackNS: 'translation',
      // defaultNS: 'translation',
      // ns: ['translation'],
      // contextSeparator:  default is '_' is this needed?
      interpolation: {
        escapeValue: false,
      },
      keySeparator: false,
      //lng: 'en-US', // required if LanguageDetector is not turned on
      resources,
    })
}

export type TrLabel = keyof typeof Label
export type PickTrLabel<U extends T, T = TrLabel> =
  Extract<T, U> extends infer O ? { [K in keyof O]: O[K] } : never

// We were formerly abstracting the call to i18n's t() as tr(), but that was limiting
// the functionality and parameters, and confusing the types
export { t as tr }

export async function changeLanguage(code: string) {
  await i18n.changeLanguage(code)
}

export type { ParseKeys } from 'i18next'
export { Trans } from 'react-i18next'
export * from './labels'
