import { createFileRoute, redirect } from '@tanstack/react-router'
import { useAuthStore } from '@/stores/auth'

type CallbackSearchParams = Partial<{ code: string }>

export const Route = createFileRoute('/callback')({
  beforeLoad: async ({ search }) => {
    if (search.code) {
      const { fetchAccessToken } = useAuthStore.getState().actions

      await fetchAccessToken(search.code)
    }
    // eslint-disable-next-line @typescript-eslint/only-throw-error
    throw redirect({ to: '/login' })
  },
  validateSearch: (search: CallbackSearchParams) => {
    return { code: search.code }
  },
})
