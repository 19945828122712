import type { SearchFormSchema } from '@/components/Search'
import { Search } from '@/components/Search'
import { Button } from '@/components/base/button'
import { Icon } from '@/components/common/icons/Icon'
import { cn } from '@/helpers/utils'
import type { UseSimpleSearchReturn } from '@/hooks/useSimpleSearch'
import type { TransitionEvent } from 'react'
import { useRef, useState } from 'react'

// NOTE: there exists presentational markup that is for animating/transitioning the search input

type SearchButtonProps = Pick<
  UseSimpleSearchReturn,
  'handleSearch' | 'handleSearchReset'
> & {
  buttonLabel: string
  placeholder: string
}

export const SearchButton = ({
  buttonLabel,
  handleSearch,
  handleSearchReset,
  placeholder,
}: SearchButtonProps) => {
  const [showInput, setShowInput] = useState(false)
  const [showCloseSearchBtn, setShowCloseSearchBtn] = useState(false)
  const searchInputRef = useRef<HTMLInputElement>(null)

  const handleButtonClick = () => {
    // show the search input
    setShowInput(true)
    // show the close search btn in search input instead of reset btn
    setShowCloseSearchBtn(true)
  }

  const handleTransitionEnd = (event: TransitionEvent) => {
    // we don't care about the ancestor transition events
    const isEventFromContainer = event.currentTarget === event.target
    if (showInput && isEventFromContainer) {
      // setting focus only works if after the transition ends
      searchInputRef?.current?.focus()
    }
  }

  // TODO: for now only close the search if no search value
  const handleCloseSearch = (search?: string) => {
    if (!search) {
      setShowInput(false)
    }
  }

  const handleEscapeKeyPress = (values: SearchFormSchema) => {
    handleCloseSearch(values.search)
  }

  const handleClickOutside = (values: SearchFormSchema) => {
    handleCloseSearch(values.search)
  }

  const handleCloseSearchButton = () => {
    handleCloseSearch()
  }

  const handleChange = (values: SearchFormSchema) => {
    // on only the close search button if no search val
    setShowCloseSearchBtn(!values.search)
    handleSearch(values)
  }

  const handleReset = () => {
    // on reset show the close search button
    setShowCloseSearchBtn(true)
    handleSearchReset()
  }

  return (
    <div className="relative flex w-full grow justify-end">
      <div
        // TODO: max-w for the search input?
        className={cn(
          'absolute right-0 top-0 w-full max-w-lg transition-[transform,visibility,opacity] ',
          showInput
            ? 'visible translate-x-0 scale-x-100 opacity-100 delay-75 duration-300'
            : 'invisible translate-x-1/4 scale-x-50 opacity-0 delay-0 duration-75',
        )}
        onTransitionEnd={handleTransitionEnd}
      >
        <Search
          formDescription="Search for a room"
          onChange={handleChange}
          onClickOutside={handleClickOutside}
          onClose={handleCloseSearchButton}
          onEscapeKey={handleEscapeKeyPress}
          onReset={handleReset}
          onSubmit={handleChange}
          placeholder={placeholder}
          ref={searchInputRef}
          showClose={showCloseSearchBtn}
        />
      </div>
      <div
        className={cn(
          'transition-[transform,visibility,opacity] ',
          showInput
            ? 'invisible opacity-0 delay-0 duration-150'
            : 'visible opacity-100 delay-75 duration-300',
        )}
      >
        <Button onClick={handleButtonClick} variant="icon-animated" size="icon">
          <span className="sr-only">{buttonLabel}</span>
          <Icon tag="search" size="2xl" />
        </Button>
      </div>
    </div>
  )
}
