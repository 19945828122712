import type { ButtonPanelOptionProps } from '@/components/ButtonPanelOption'
import { ButtonPanelOption } from '@/components/ButtonPanelOption'

type OmittedProps = Omit<ButtonPanelOptionProps, 'isActive' | 'tag'>

type ButtonRoomLayoutProps = OmittedProps

const ButtonRoomLayout = ({
  disabled = false,
  isVisible = true,
  ...props
}: ButtonRoomLayoutProps) => {
  return (
    <ButtonPanelOption
      disabled={disabled}
      isVisible={isVisible}
      // TODO: add disabled tag if needed
      tag="grid-on"
      {...props}
    >
      Layout Options
    </ButtonPanelOption>
  )
}

export { ButtonRoomLayout }
