import { useCallback, useEffect, useState } from 'react'
import { useRetry } from './useRetry'

interface UseImageWithRetryProps {
  maxRetries?: number
  retryDelayMs?: number
  retryOnFailure?: true
}

interface UseImageWithoutRetryProps {
  maxRetries?: never
  retryDelayMs?: never
  retryOnFailure: false
}

type UseImageProps = {
  fallbackSrc?: string | undefined
  src: string | undefined
} & (UseImageWithoutRetryProps | UseImageWithRetryProps)

/**
 * A hook to load an image with automatic retries and a fallback image.
 *
 * @param params - Configuration object for the hook.
 * @param params.src - Required source URL of the image to be loaded.
 * @param params.fallbackSrc - Optional URL of a fallback image to be displayed if the image fails to load.
 * @param params.maxRetries - Optional maximum number of retry attempts if the image fails to load.
 * @param params.retryDelayMs - Optional delay (in milliseconds) between retries.
 * @param params.retryOnFailure - Optional flag to enable or disable retries. If true, the hook will retry loading the image upon failure.
 *
 * @returns - The current image source that should be rendered.
 */
export const useImage = (props: UseImageProps) => {
  const {
    fallbackSrc = '',
    maxRetries,
    retryDelayMs,
    src,
    retryOnFailure = true,
  } = props
  const [imageSrc, setImageSrc] = useState(src)

  const loadImage = useCallback(
    async (attemptCount: number) => {
      if (!src) {
        setImageSrc(fallbackSrc)
        return
      }

      const addCacheBuster = (url: string) => {
        const cacheBuster = `retry=${attemptCount}&t=${Date.now()}`
        return `${url}?${cacheBuster}`
      }

      const imageUrl = attemptCount > 0 ? addCacheBuster(src) : src

      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = imageUrl
        img.onload = () => {
          setImageSrc(img.src)
          resolve(img)
        }
        img.onerror = () => {
          // For better UX: Render the fallback src on the first failure
          if (attemptCount === 0) setImageSrc(fallbackSrc)
          reject(new Error('Failed to load the image'))
        }
      })
    },
    [src, fallbackSrc],
  )

  // Update imageSrc and reset the retries when src changes
  useEffect(() => {
    setImageSrc(src)
  }, [src])

  // Use the useRetry hook to manage retries
  useRetry({
    maxRetries,
    retryDelayMs,
    retryFn: loadImage,
    shouldRetry: retryOnFailure,
  })

  return { imageSrc }
}
