import { App } from '@/components/App.tsx'
import { ENV_VARS, ROOT_ELEMENT_ID } from '@/helpers/constants'
import { useAuthStore } from '@/stores/auth.ts'
import { useMainStore } from '@/stores/main.ts'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { i18nInit } from './i18n/index.ts'
import './index.css'

i18nInit().catch(err => {
  console.error('Error initializing i18n', err)
})

export const main = () => {
  // NOTE: these are parameters needed for the OAuth flow, some could be passed as params
  useAuthStore.getState().actions.init({
    authUri: ENV_VARS.VITE_OAUTH_AUTH_URI,
    clientId: ENV_VARS.VITE_OAUTH_CLIENT_ID,
    codeChallengeMethod: 'S256',
    redirectUri: `${window.location.origin}/callback`,
    responseType: 'code',
    tokenUri: ENV_VARS.VITE_OAUTH_TOKEN_URI,
  })

  // NOTE: For the prod/staging environments, have different host endpoints
  useMainStore.getState().actions.setHost(ENV_VARS.VITE_RELAY_HOST)

  ReactDOM.createRoot(document.getElementById(ROOT_ELEMENT_ID)!).render(
    <StrictMode>{<App />}</StrictMode>,
  )
}
