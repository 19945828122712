// Stores
import { useRoomStore } from '@/stores/room'
import { useUiStoreActions } from '@/stores/ui'
import { FEATURES } from '@/stores/features'
import { CopyRoomLink } from './CopyRoomLink'
import { TEST_IDS } from '@/helpers/constants'

// Components
import { ScrollArea } from '@/components/base/scroll-area'
import { DialogDevices } from '@/components/DialogDevices'
import { Feature } from '@/components/Feature'

// Types
import type { RoomPanelType } from '@/stores/ui'
import { useRoomLockButtonState } from '@/hooks/useRoomLockButtonState'
import { ButtonPanelOption } from '@/components/ButtonPanelOption'
import { ButtonRoomLock } from '@/components/ButtonRoomLock'
import { useLayoutButtonState } from '@/hooks/useLayoutButtonState'
import { ButtonRoomLayout } from '@/components/ButtonRoomLayout'

export const PanelRoomOptions = () => {
  const isAudioOnly = useRoomStore(state => state.isAudioOnly)
  const memberState = useRoomStore(state => state.memberState)
  const path = useRoomStore(state => state.path)

  const { isRoomLockButtonEnabled, isRoomLockButtonVisible } =
    useRoomLockButtonState()
  const { isRoomLayoutButtonVisible } = useLayoutButtonState()

  const { setRoomPanelType } = useUiStoreActions()

  const changePanelContentHandler = (panelType: RoomPanelType) => {
    setRoomPanelType(panelType)
  }

  const memberJoined = memberState === 'joined'

  return (
    <ScrollArea className="h-full" data-testid={TEST_IDS.ROOM_PANEL_SETTINGS}>
      <div className="flex w-full flex-col space-y-3">
        <ButtonRoomLock
          disabled={!memberJoined && !isRoomLockButtonEnabled}
          isVisible={isRoomLockButtonVisible}
        />
        <Feature name={FEATURES.CREATE_SIDEBAR}>
          <ButtonPanelOption
            disabled
            isVisible={!isAudioOnly}
            tag="view-sidebar"
          >
            Create Sidebar
          </ButtonPanelOption>
        </Feature>
        {!isAudioOnly && (
          <CopyRoomLink
            address={path}
            clickedContent="Copied!"
            initialContent="Copy Room Link"
            withArrow={false}
          >
            {({ trigger }) => (
              <ButtonPanelOption tag="share" onClick={trigger}>
                Share Room
              </ButtonPanelOption>
            )}
          </CopyRoomLink>
        )}
        <Feature name={FEATURES.CHANGE_ROOMS}>
          <ButtonPanelOption
            disabled={!memberJoined}
            onClick={() => changePanelContentHandler('navigator')}
            isVisible={!isAudioOnly}
            tag="sofa-outline-1"
          >
            Change Rooms
          </ButtonPanelOption>
        </Feature>
        <DialogDevices showCameraPreview={!isAudioOnly}>
          <ButtonPanelOption tag="headphones">Change Devices</ButtonPanelOption>
        </DialogDevices>
        <ButtonRoomLayout
          disabled={!memberJoined}
          onClick={() => changePanelContentHandler('layout')}
          isVisible={isRoomLayoutButtonVisible}
        />
      </div>
    </ScrollArea>
  )
}
