import { useCallback, useEffect, useRef } from 'react'

export const MAX_RETRY_COUNT = 3
export const RETRY_DELAY_MS = 2000

interface UseRetryProps<T> {
  maxRetries?: number | undefined
  retryDelayMs?: number | undefined
  retryFn: (attemptCount: number) => Promise<T>
  shouldRetry?: boolean
}

/**
 * A hook that executes a function with automatic retries and delay between attempts.
 *
 * @param params - Configuration object for the hook.
 * @param params.retryFunction - Required function to be executed with retry logic. The function receives the current attempt count as an argument.
 * @param params.maxRetries - Optional maximum number of retry attempts if the function fails to succeed.
 * @param params.retryDelayMs - Optional delay (in milliseconds) between retry attempts.
 * @param params.shouldRetry - Optional flag to enable or disable retries. Default is true.
 *
 * @returns - The current retry count.
 */
export const useRetry = <T>(props: UseRetryProps<T>) => {
  const {
    retryFn,
    maxRetries = MAX_RETRY_COUNT,
    retryDelayMs = RETRY_DELAY_MS,
    shouldRetry = true,
  } = props
  const retryCountRef = useRef(0)
  const timerRef = useRef<number | null>(null)

  const executeWithRetry = useCallback(async () => {
    if (retryCountRef.current > maxRetries) return

    try {
      await retryFn(retryCountRef.current)
    } catch (_error) {
      // Do not retry if max retries reached or shouldRetry flag is false
      if (retryCountRef.current >= maxRetries || !shouldRetry) {
        console.log('Maximum retries reached!')
        return
      }

      // Retry after a delay
      timerRef.current = window.setTimeout(async () => {
        retryCountRef.current += 1
        await executeWithRetry()
      }, retryDelayMs)
    }
  }, [maxRetries, retryDelayMs, shouldRetry, retryFn])

  useEffect(() => {
    void executeWithRetry()

    return () => {
      retryCountRef.current = 0
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [executeWithRetry])

  return { retryCount: retryCountRef.current }
}
