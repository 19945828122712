import type { PropsWithChildren } from 'react'

// Stores
import { useAuthStoreActions } from '@/stores/auth'
import { useUiStore } from '@/stores/ui'
import { FEATURES } from '@/stores/features'

// Components
import { Button } from '@/components/base/button'
import { Icon, type Variant } from '@/components/common/icons/Icon'
import { Svg } from '@/components/common/icons/Svg'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/base/avatar'
import { ScrollArea } from '@/components/base/scroll-area'
import { Separator } from '@/components/base/separator'
import { DialogDeveloperCall } from '@/components/DialogDeveloperCall'
import { UserProfile } from '@/components/UserProfile'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTrigger,
} from '@/components/base/sheet'
import { Feature } from '@/components/Feature'

// Assets
import checked from '@/assets/checked.png'
import { UserSettingsDevices } from './UserSettingsDevices'

type SidebarSettingsProps = PropsWithChildren

export const SidebarSettings = ({ children }: SidebarSettingsProps) => {
  const viewElementRef = useUiStore(state => state.viewElementRef)
  const { logout } = useAuthStoreActions()
  const iconVariant = 'foreground' as Variant

  return (
    <Sheet>
      <SheetTrigger data-testid="sidebar-trigger">{children}</SheetTrigger>
      <SheetContent
        className="ml-auto flex h-full w-[305px] shrink-0 flex-col space-y-2 overflow-hidden p-6 text-secondary"
        container={viewElementRef?.current}
        side="left"
      >
        <SheetHeader>
          <div>
            <Svg tag="sw-logotype" className="-ml-5 pb-2" />
            <span className="sr-only">SignalWire</span>
          </div>
          <div className="flex items-center space-x-2 px-2 py-2 text-lg font-semibold text-foreground">
            <Avatar className="h-6 w-6">
              <AvatarImage src={checked} />
              <AvatarFallback className="bg-primary">CN</AvatarFallback>
            </Avatar>
            <UserProfile />
          </div>
          <SheetDescription className="hidden" />
        </SheetHeader>
        <ScrollArea className="m-0 p-4 text-sm text-foreground">
          <h2 className="pb-5">Settings</h2>
          <ul className="w-56">
            <UserSettingsDevices />
          </ul>
          <Feature name={FEATURES.DEVELOPER_TOOLS}>
            <Separator className="my-5 bg-primary-light" />
            <h2 className="pb-5 pt-5" data-testid="developer-tools">
              Developer
            </h2>
            <ul>
              <li className="flex items-center space-x-3 py-5">
                <Icon tag="call" size="lg" variant={iconVariant} />
                <DialogDeveloperCall>
                  <div>Make a Call</div>
                </DialogDeveloperCall>
              </li>
            </ul>
          </Feature>
          <Separator className="my-5 bg-primary-light" />
          <Button
            className="-ml-1 flex h-auto items-center space-x-1 px-0 py-5 text-destructive hover:no-underline"
            variant="icon"
            onClick={logout}
          >
            <Icon tag="keyboard-left-arrow" size="2xl" variant="destructive" />
            <div>Logout</div>
          </Button>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  )
}
