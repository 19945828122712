// Stores
import { useUiStore, useUiStoreActions } from '@/stores/ui'
// Components
import type { ReactNode } from 'react'
import { Button } from '@/components/base/button'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@/components/base/sheet'
import { Icon } from '@/components/common/icons/Icon'
import { PanelRoomChat } from '@/components/PanelRoomChat'
import { PanelRoomOptions } from '@/components/PanelRoomOptions'
import { PanelRoomParticipants } from '@/components/PanelRoomParticipants'
import { PanelRoomNavigator } from '@/components/PanelRoomNavigator'
import { PanelRoomLayouts } from '@/components/PanelRoomLayouts'

// Types
import type { RoomPanelType } from '@/stores/ui'

interface PanelInfo {
  component: () => ReactNode
  parentPanelType?: RoomPanelType
  title: string
}

const panels: Record<RoomPanelType, PanelInfo> = {
  chat: { component: PanelRoomChat, title: 'Chat' },
  layout: {
    component: PanelRoomLayouts,
    parentPanelType: 'options',
    title: 'Layout Options',
  },
  navigator: {
    component: PanelRoomNavigator,
    parentPanelType: 'options',
    title: 'Change Rooms',
  },
  options: { component: PanelRoomOptions, title: 'Options' },
  participants: { component: PanelRoomParticipants, title: 'Participants' },
}

interface PanelRoomProps {
  containerElement?: HTMLElement | null | undefined
}

export const PanelRoom = ({ containerElement }: PanelRoomProps) => {
  const { hideRoomPanel, setRoomPanelType, showRoomPanel } = useUiStoreActions()

  const rootElementRef =
    useUiStore(state => state.rootElementRef) ?? document.body // the default fallback is document.body
  const displayRoomPanel = useUiStore(state => state.displayRoomPanel)
  const panelType = useUiStore(state => state.roomPanelType)

  const panelContent = panelType ? panels[panelType] : null
  const ContentComponent = panelContent?.component || (() => <></>)

  const roomPanelBackHandler = () => {
    if (panelContent?.parentPanelType) {
      setRoomPanelType(panelContent.parentPanelType)
    }
  }
  const displayBackButton = panelContent?.parentPanelType

  // Use the title override from the store if available
  const title = useUiStore(state => state.roomPanelTitle) || panelContent?.title

  // SheetContent top and bottom margins are a temporary fix
  // until we get the MCU ratio correct
  if (!panelType) return null

  return (
    <Sheet
      modal={false}
      open={displayRoomPanel}
      onOpenChange={(open: boolean) => {
        if (open) {
          showRoomPanel()
        } else {
          hideRoomPanel()
        }
      }}
    >
      <SheetContent
        className="mb-[5.5rem] ml-auto mt-14 flex !h-auto w-[305px] flex-col space-y-2 overflow-y-hidden rounded-tl-2xl p-4 text-secondary"
        container={containerElement ?? rootElementRef}
        data-testid="room-panel"
        showClose={true}
        side="right"
        onEscapeKeyDown={event => event.preventDefault()}
        onInteractOutside={event => event.preventDefault()}
        onOpenAutoFocus={event => event.preventDefault()}
      >
        <SheetHeader className="m-0 flex shrink-0 flex-row items-center">
          {displayBackButton && (
            <Button
              variant="icon"
              onClick={roomPanelBackHandler}
              className="m-0 me-2 h-0 p-0"
            >
              <Icon tag="arrow-back" variant="foreground" size="xl" />
              <span className="sr-only">Back</span>
            </Button>
          )}
          <SheetTitle>{title}</SheetTitle>
          <SheetDescription className="hidden" />
        </SheetHeader>
        <ContentComponent />
      </SheetContent>
    </Sheet>
  )
}
