import { RouterProvider } from '@tanstack/react-router'
import { FEATURES } from '@/stores/features'
import { Toaster } from '@/components/base/toaster'
import { DevIndicator } from '@/components/DevIndicator'
import { ErrorDialog } from '@/components/ErrorDialog'
import { Feature } from '@/components/Feature'
import { ToastDevDialog } from '@/components/ToastDevDialog'
import type { AppRouter } from '@/helpers/types'

interface AppProps {
  router: AppRouter
}

export const App = ({ router }: AppProps) => {
  return (
    <>
      <Feature name={FEATURES.ERROR_DIALOG}>
        <ErrorDialog />
      </Feature>
      <Feature name={FEATURES.GLOBAL_TOASTER}>
        <Toaster />
      </Feature>
      {/* Can be removed, only used for demonstrating toasts */}
      <Feature name={FEATURES.DEVELOPER_TOASTER_DIALOG}>
        <ToastDevDialog />
      </Feature>
      <DevIndicator />
      <RouterProvider router={router} />
    </>
  )
}
