import type { MouseEvent, PropsWithChildren } from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { WebRTC } from '@signalwire/js'
import type { DeviceStatus } from '@/stores/devices'
import { useDevicesStore, useDevicesStoreActions } from '@/stores/devices'
import { useRoomStoreActions } from '@/stores/room'
import { ButtonAudioTest } from '@/components/ButtonAudioTest'
import { Icon } from '@/components/common/icons/Icon'
import { MicrophoneMeter } from '@/components/MicrophoneMeter'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/base/select'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/base/dialog'
import { useUiStore } from '@/stores/ui'
import { useUserSettingsStoreActions } from '@/stores/userSettings'
import { useBreakPoint } from '@/hooks/useBreakPoint'
import { BREAKPOINT_KEYS } from '@/helpers/constants'

type DialogDevicesProps = PropsWithChildren<{ showCameraPreview?: boolean }>

export const DialogDevices = ({
  children,
  showCameraPreview,
}: DialogDevicesProps) => {
  const cameraId = useDevicesStore(state => state.cameraId)
  const cameraIdDefault = useDevicesStore(state => state.cameraIdDefault)
  const cameraList = useDevicesStore(state => state.cameraList)
  const microphoneId = useDevicesStore(state => state.microphoneId)
  const microphoneIdDefault = useDevicesStore(
    state => state.microphoneIdDefault,
  )
  const microphoneList = useDevicesStore(state => state.microphoneList)
  const speakerId = useDevicesStore(state => state.speakerId)
  const speakerIdDefault = useDevicesStore(state => state.speakerIdDefault)
  const speakerList = useDevicesStore(state => state.speakerList)

  const rootElementRef =
    useUiStore(state => state.rootElementRef) ?? document.body // the default fallback is document.body

  const {
    setPreferredVideoDevice,
    setPreferredMicrophoneDevice,
    setPreferredSpeakerDevice,
  } = useUserSettingsStoreActions()

  const {
    getCameraStream,
    setCameraId,
    setMicrophoneId,
    setSpeakerId,
    releaseDevices,
  } = useDevicesStoreActions()
  const { updateCameraHandler, updateMicrophoneHandler, updateSpeakerHandler } =
    useRoomStoreActions()

  const breakPointKey = useBreakPoint()

  const [dialogOpen, setDialogOpen] = useState(false)
  const [cameraPreviewId, setCameraPreviewId] = useState<string>('')
  const [microphonePreviewId, setMicrophonePreviewId] = useState<string>('')
  const [speakerPreviewId, setSpeakerPreviewId] = useState<string>('')
  const [statusCamera, setStatusCamera] = useState<DeviceStatus>('loading')
  const videoElementRef = useRef<HTMLVideoElement | null>(null)

  useEffect(() => {
    if (dialogOpen && showCameraPreview) {
      setStatusCamera('loading')
      const getPreview = async () => {
        try {
          const stream = await getCameraStream(cameraPreviewId)
          if (
            videoElementRef.current &&
            videoElementRef.current.srcObject !== stream
          ) {
            // status='playing' will be set by the videoElement's onCanPlay()
            videoElementRef.current.srcObject = stream ?? null
          }
        } catch (error) {
          console.error('Could not get camera stream:', error)
          setStatusCamera('error')
        }
      }
      void getPreview()
    }

    // cleanup
    return () => {
      releaseDevices()
    }
  }, [
    cameraPreviewId,
    dialogOpen,
    getCameraStream,
    releaseDevices,
    showCameraPreview,
  ])

  const handleContinue = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    // TODO: Can handlers from the devices store be triggered by ID changes?
    // Or should the updates be called from the devices store when setting the ID?

    if (cameraId !== cameraPreviewId) {
      await updateCameraHandler(cameraPreviewId)
      setCameraId(cameraPreviewId)
      setPreferredVideoDevice({ deviceId: cameraPreviewId })
    }
    if (microphoneId !== microphonePreviewId) {
      await updateMicrophoneHandler(microphonePreviewId)
      setMicrophoneId(microphonePreviewId)
      setPreferredMicrophoneDevice({ deviceId: microphonePreviewId })
    }
    if (speakerId !== speakerPreviewId) {
      await updateSpeakerHandler(speakerPreviewId)
      setSpeakerId(speakerPreviewId)
      setPreferredSpeakerDevice({ deviceId: speakerPreviewId })
    }

    setDialogOpen(false)
  }

  // ComboBox requires label/value properties
  const cameraItems = useMemo(
    () =>
      cameraList.map(camera => ({
        disabled: false,
        label: camera.label,
        value: camera.deviceId,
      })),
    [cameraList],
  )
  const microphoneItems = useMemo(
    () =>
      microphoneList.map(microphone => ({
        disabled: false,
        label: microphone.label,
        value: microphone.deviceId,
      })),
    [microphoneList],
  )
  const speakerItems = useMemo(
    () =>
      speakerList.map(speaker => ({
        disabled: false,
        label: speaker.label,
        value: speaker.deviceId,
      })),
    [speakerList],
  )

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent
        className="block min-h-min w-full max-w-[368px] overflow-hidden p-4 md:max-w-[557px]"
        container={rootElementRef}
      >
        <DialogHeader className="space-y-1.5 pb-4 text-left">
          <DialogTitle className="tracking-tight text-foreground">
            Change Devices
          </DialogTitle>
        </DialogHeader>
        {dialogOpen ? (
          <>
            <div className="mb-3 flex w-full flex-col gap-y-4 md:flex-row md:content-between md:items-stretch md:space-x-4">
              {showCameraPreview && (
                <div className="aspect-video w-full shrink-0 overflow-hidden bg-black md:w-[240px]">
                  {statusCamera === 'error' && (
                    <div className="h-full w-full p-2 pt-4 text-center text-white">
                      <p>Error loading stream.</p>
                      <p>Please check your device.</p>
                    </div>
                  )}
                  {(statusCamera === 'loading' ||
                    statusCamera === 'disabled') && (
                    <div className="grid h-full w-full place-content-center text-white">
                      {statusCamera === 'loading' ? (
                        <Icon
                          className="animate-spin"
                          tag="refresh"
                          size="3xl"
                        />
                      ) : (
                        <Icon tag="videocam-off" size="3xl" />
                      )}
                    </div>
                  )}
                  {/* Always render the video so that we can get the videoElement */}
                  <video
                    aria-label="Camera preview"
                    autoPlay
                    className="mx-auto my-auto max-h-full max-w-full bg-skeleton"
                    id="video-preview"
                    muted
                    onCanPlay={() => setStatusCamera('playing')}
                    playsInline
                    ref={videoElementRef}
                  />
                </div>
              )}
              <div className="flex flex-1 flex-col justify-between gap-y-4 overflow-x-hidden md:px-1">
                {showCameraPreview && (
                  <Select
                    defaultValue={cameraId || cameraIdDefault}
                    onValueChange={setCameraPreviewId}
                  >
                    <SelectTrigger>
                      <Icon
                        className="mr-1 shrink-0"
                        size={'lg'}
                        tag={'videocam'}
                        variant={'foreground'}
                      />
                      <SelectValue placeholder={'Select camera'} />
                    </SelectTrigger>
                    <SelectContent container={rootElementRef}>
                      {cameraItems.map(item => (
                        <SelectItem key={item.value} value={item.value}>
                          {item.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}

                <Select
                  defaultValue={microphoneId || microphoneIdDefault}
                  onValueChange={setMicrophonePreviewId}
                >
                  <SelectTrigger>
                    <Icon
                      className="mr-1 shrink-0"
                      size={'lg'}
                      tag={'mic'}
                      variant={'foreground'}
                    />
                    <SelectValue placeholder={'Select microphone'} />
                  </SelectTrigger>
                  <SelectContent container={rootElementRef}>
                    {microphoneItems.map(item => (
                      <SelectItem key={item.value} value={item.value}>
                        {item.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                {WebRTC.supportsMediaOutput() ? (
                  <div className="flex w-full">
                    <div className="w-5/6">
                      <Select
                        defaultValue={speakerId || speakerIdDefault}
                        onValueChange={setSpeakerPreviewId}
                      >
                        <SelectTrigger>
                          <Icon
                            className="mr-1 shrink-0"
                            size={'lg'}
                            tag={'headphones'}
                            variant={'foreground'}
                          />
                          <SelectValue placeholder={'Select speakers'} />
                        </SelectTrigger>
                        <SelectContent container={rootElementRef}>
                          {speakerItems.map(item => (
                            <SelectItem key={item.value} value={item.value}>
                              {item.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>

                    <ButtonAudioTest
                      className="w-1/6 pl-2 pr-0"
                      deviceId={speakerPreviewId}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <MicrophoneMeter
              microphoneId={microphonePreviewId}
              // TODO: size should just be implicit based on the container?
              size={breakPointKey === BREAKPOINT_KEYS.MD ? 23 : 13}
            />
            <DialogFooter className="flex flex-col-reverse pt-4 md:flex-row md:justify-end md:space-x-2">
              <DialogClose
                className="m-auto md:max-w-[328px]"
                onClick={handleContinue}
                variant="gradient"
              >
                Continue
              </DialogClose>
            </DialogFooter>
          </>
        ) : null}
      </DialogContent>
    </Dialog>
  )
}
