import { useEffect, useMemo } from 'react'
import { useRoomStore, useRoomStoreActions } from '@/stores/room'
import { TEST_IDS } from '@/helpers/constants'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from '@/components/base/select'
import { ScrollArea } from '@/components/base/scroll-area'
import { Label } from '@/components/base/label'
import { SelectLabel } from '@radix-ui/react-select'
import { sortLayouts } from '@/helpers/layouts'

export const PanelRoomLayouts = () => {
  const layout = useRoomStore(state => state.layout)
  const layouts = useRoomStore(state => state.layouts)
  const layoutPosition = useRoomStore(state => state.layoutPosition)
  const layoutPositions = useRoomStore(state => state.layoutPositions)
  const { getLayouts, setLayout, setLayoutPosition } = useRoomStoreActions()

  useEffect(() => {
    if (layouts.length > 0) return
    void getLayouts()
  }, [getLayouts, layouts])

  const { gridLayouts, specialGridLayouts } = useMemo(() => {
    const sortedLayouts = sortLayouts(layouts)
    return {
      gridLayouts: sortedLayouts.filter(layout => layout.tags.includes('grid')),
      specialGridLayouts: sortedLayouts.filter(layout =>
        layout.tags.includes('special-grid'),
      ),
    }
  }, [layouts])

  return (
    <ScrollArea data-testid={TEST_IDS.ROOM_PANEL_LAYOUT}>
      <form className="space-y-4 text-foreground">
        <fieldset className="flex flex-col gap-y-1">
          <legend className="sr-only">Room layout options</legend>
          <Label htmlFor="room-layout">Room layout</Label>
          <Select
            value={layout}
            onValueChange={setLayout}
            name="Room Layout"
            disabled={!layouts.length}
          >
            <SelectTrigger id="room-layout">
              <SelectValue placeholder="Select room layout" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel className="px-2 py-1.5 text-sm text-accent">
                  Grids
                </SelectLabel>
                {gridLayouts.map(layout => (
                  <SelectItem key={layout.layoutName} value={layout.layoutName}>
                    {layout.displayName}
                  </SelectItem>
                ))}
              </SelectGroup>
              <SelectSeparator className="m-2 bg-accent" />
              <SelectGroup>
                <SelectLabel className="px-2 py-1 text-sm text-accent">
                  Specials
                </SelectLabel>
                {specialGridLayouts.map(layout => (
                  <SelectItem key={layout.layoutName} value={layout.layoutName}>
                    {layout.displayName}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </fieldset>
        <fieldset className="flex flex-col gap-y-1">
          <legend className="sr-only">Member position options</legend>
          <Label htmlFor="member-position">Member position</Label>
          <Select
            value={layoutPosition}
            onValueChange={setLayoutPosition}
            name="Member position"
            disabled={!layoutPositions.length}
          >
            <SelectTrigger id="member-position">
              <SelectValue placeholder="Select member position" />
            </SelectTrigger>
            <SelectContent>
              {layoutPositions.map(position => (
                <SelectItem
                  key={position.positionName}
                  value={position.positionName}
                >
                  {position.displayName}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </fieldset>
      </form>
    </ScrollArea>
  )
}
