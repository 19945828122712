import { Icon } from '@/components/common/icons/Icon'
import { REDIRECT_PATHS } from '@/helpers/constants'
import type { RedirectPath } from '@/helpers/types'
import { cn } from '@/helpers/utils'
import { useRouter } from '@tanstack/react-router'
import type { PropsWithChildren } from 'react'
import { Button } from './base/button'
import { cva, type VariantProps } from 'class-variance-authority'

type LinkLeaveProps = PropsWithChildren<{
  className?: string
  to?: RedirectPath | undefined
  variant?: VariantProps<typeof linkLeaveVariants>['variant']
}>

/* eslint-disable sort-keys */
const linkLeaveVariants = cva('m-0 flex flex-col items-center gap-y-1 p-0', {
  variants: {
    variant: {
      default: 'h-auto rounded-none bg-none px-2 pb-4 pt-3 text-neutral-light',
      rounded:
        'h-20 w-20 shrink-0 justify-center rounded-full bg-background text-destructive transition-colors hover:bg-destructive hover:text-background hover:delay-button',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})
/* eslint-enable sort-keys */

export const LinkLeave = ({
  className,
  variant,
  to = REDIRECT_PATHS.RECENT,
}: LinkLeaveProps) => {
  const { navigate } = useRouter()
  const leaveHandler = async () => {
    await navigate({ to })
  }

  return (
    <Button
      className={cn(linkLeaveVariants({ variant: variant }), className)}
      onClick={leaveHandler}
      // aria attributes required for "link" if not using <a> tag
      tabIndex={0}
      role="link"
    >
      <div className="px-5 py-1">
        <Icon size="xl" tag="phone-disabled" />
      </div>
      <span className="text-xs">Leave</span>
    </Button>
  )
}
