import { useId } from 'react'
import type { CopyButtonProps } from '@/components/CopyButton'
import { CopyButton } from '@/components/CopyButton'
import type { RoomLinkProps } from '@/components/RoomLink'
import { RoomLink } from '@/components/RoomLink'

type CopyRoomLinkProps = Omit<
  CopyButtonProps,
  'elementIdToCopy' | 'elementPropertyToCopy'
> &
  Pick<RoomLinkProps, 'address' | 'redirect'>

export const CopyRoomLink = ({
  address,
  redirect,
  ...otherProps
}: CopyRoomLinkProps) => {
  const roomLinkId = useId()

  return (
    <>
      <RoomLink
        address={address}
        className="sr-only"
        id={roomLinkId}
        redirect={redirect!}
      />
      <CopyButton
        elementIdToCopy={roomLinkId}
        elementPropertyToCopy={'href'}
        {...otherProps}
      />
    </>
  )
}
