import { Feature } from '@/components/Feature'
import { LoadingWrapper } from '@/components/LoadingWrapper'
import { PanelRoom } from '@/components/PanelRoom'
import { RoomFooter } from '@/components/RoomFooter'
import { RoomHeader } from '@/components/RoomHeader'
import { INCOMING_CALL_CONTEXT } from '@/helpers/constants'
import { Route } from '@/routes/_auth.room.$context.$name'
import { useCallStore } from '@/stores/call'
import { FEATURES } from '@/stores/features'
import { useRoomStore, useRoomStoreActions } from '@/stores/room'
import { useUiStore, useUiStoreActions } from '@/stores/ui'
import { useUserSettingsStoreActions } from '@/stores/userSettings'
import { useRouter } from '@tanstack/react-router'
import { useCallback, useEffect, useRef, useState } from 'react'

export const RoomView = () => {
  const { context, name } = Route.useParams()
  const { channel, redirect } = Route.useSearch()
  const router = useRouter()
  const displayRoomPanel = useUiStore(state => state.displayRoomPanel)
  const memberState = useRoomStore(state => state.memberState)

  const initialized = useRef(false)
  const rootMcuRef = useRef<HTMLDivElement>(null)
  const { join, startSession } = useRoomStoreActions()
  const { hideErrorDialog, setErrorDialogContent, showErrorDialog } =
    useUiStoreActions()
  const currentNotification = useCallStore(state => state.currentNotification)
  const {
    getPreferredMicrophoneDeviceForDialing,
    getPreferredVideoDeviceForDialing,
  } = useUserSettingsStoreActions()
  const [orientation, setOrientation] = useState('landscape')
  // for landscape the rootElement width needs to be constrained to 16/9 % of the screen minus a bleed space to fit the bar
  const landscapeConstraints = {
    margin: '0 auto',
    maxWidth: 'calc(-18rem + 177.77778vh)',
  }
  // for landscape the rootElement width needs to be constrained to 9/16 % of the screen minus a bleed space to fit the bar
  const portraitConstrains = {
    margin: '0 auto',
    maxWidth: 'calc(-6.1rem + 56vh)',
  }

  const handleErrorDialog = useCallback(() => {
    const closeAndNavigate = () => {
      hideErrorDialog()
      router?.navigate({ to: redirect || '/recent' }).catch(console.error)
    }

    // set the error dialog content
    setErrorDialogContent({
      confirmLabel: 'OK',
      description:
        'There was a problem when joining the room. Please try again.',
      onConfirm: closeAndNavigate,
      title: 'Error',
    })

    // show the error dialog
    showErrorDialog()
  }, [
    setErrorDialogContent,
    hideErrorDialog,
    showErrorDialog,
    router,
    redirect,
  ])

  useEffect(() => {
    // FIXME: test if condition will impact when orientation changes
    // if (memberState !== 'joined') {
    //   return
    // }

    const updateAspectRatio = () => {
      const videoElement = rootMcuRef.current?.querySelector('video')

      const width = videoElement?.offsetWidth ?? 1
      const height = videoElement?.offsetHeight ?? 1
      const aspectRatio = width / height
      if (aspectRatio >= 1) {
        setOrientation('landscape')
      } else {
        setOrientation('portrait')
      }
    }

    updateAspectRatio()

    const videoElement = rootMcuRef.current?.querySelector('video')

    videoElement?.addEventListener('resize', updateAspectRatio)

    return () => {
      videoElement?.removeEventListener('resize', updateAspectRatio)
    }
  }, [memberState])

  useEffect(() => {
    if (initialized.current) return
    if (!rootMcuRef.current) return

    if (!context || !name) {
      console.error('Error: A parameter was missing when entering the Room')
      handleErrorDialog()
      return
    }

    if (context !== INCOMING_CALL_CONTEXT) {
      void join({
        channel: channel,
        context: context,
        name: name,
        redirect: redirect,
        rootElement: rootMcuRef.current,
      })
        .then(res => {
          console.log('XXXX: join res', res)
        })
        .catch(error => {
          handleErrorDialog()
          console.log('XXXX: join error', error)
        })
    } else {
      currentNotification?.invite
        .accept({
          audio: getPreferredMicrophoneDeviceForDialing(),
          rootElement: rootMcuRef.current,
          video: getPreferredVideoDeviceForDialing(),
        })
        .then(roomSession => {
          console.log('XXXX: accepted res', roomSession)
          startSession(roomSession).then(console.log).catch(console.log)
        })
        .catch(error => {
          handleErrorDialog()
          console.log('XXXX: accept error', error)
        })
    }

    initialized.current = true
  }, [
    handleErrorDialog,
    join,
    startSession,
    getPreferredMicrophoneDeviceForDialing,
    getPreferredVideoDeviceForDialing,
    context,
    name,
    channel,
    redirect,
    currentNotification,
  ])

  if (!context?.trim() || !name?.trim()) {
    // TODO: error message displayed in video area?
    return null
  }

  // TODO: move panel into root if/when possible
  return (
    <>
      <Feature name={FEATURES.ROOM_LOADING_UI}>
        <LoadingWrapper
          className="rounded-xl border border-border/30 drop-shadow-md"
          loading={memberState !== 'joined'}
          loadingLabel={
            <div className="flex flex-col gap-y-1 text-center text-2xl text-secondary">
              <h2 className="font-sans text-2xl text-foreground">Joining...</h2>
              <h3 className="text-sm text-secondary">
                {context}/{name}
              </h3>
            </div>
          }
        />
      </Feature>
      {/* FIXME: Pass containerElement for SheetPortal. */}
      {displayRoomPanel ? <PanelRoom /> : null}
      <div className="relative flex h-full w-full flex-col bg-black">
        <div className="absolute inset-0 z-50 h-14 w-full shrink-0 translate-y-0 bg-primary px-9">
          <RoomHeader />
        </div>
        <div
          ref={rootMcuRef}
          className="h-full min-h-svh w-full"
          style={
            orientation === 'landscape'
              ? landscapeConstraints
              : portraitConstrains
          }
        />
        <div className="absolute bottom-0 z-50 flex h-[5.5rem] w-full shrink-0 -translate-y-0 items-center justify-center bg-primary transition-transform">
          <RoomFooter />
        </div>
      </div>
    </>
  )
}
