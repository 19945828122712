// Image component - may need to make this zoomable

import { cn } from '@/helpers/utils'
import { forwardRef, type ComponentPropsWithRef } from 'react'

type ImageProps = ComponentPropsWithRef<'img'> & {
  alt: string
}

const Image = forwardRef<HTMLImageElement, ImageProps>(
  ({ alt, className, src, ...props }, ref) => {
    return (
      <img alt={alt} className={cn(className)} ref={ref} src={src} {...props} />
    )
  },
)

Image.displayName = 'Image'

export { Image }
