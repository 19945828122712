import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from '@/components/base/toast'
import { useToast } from '@/hooks/use-toast'
import { Icon } from '@/components/common/icons/Icon'
import { cn } from '@/helpers/utils'
import { subscribeToRootElementResize, useUiStore } from '@/stores/ui'
import { useEffect, useRef } from 'react'
import { useBreakPoint } from '@/hooks/useBreakPoint'
import { BREAKPOINT_KEYS } from '@/helpers/constants'

const TOAST_VIEWPORT_STYLES = {
  RIGHT_MARGIN: 16, // 16px
} as const

export function Toaster() {
  const toastViewPortRef = useRef<HTMLOListElement | null>(null)
  const rootElementWidth = useUiStore(state => state.rootElementWidth)
  const displayErrorDialog = useUiStore(state => state.displayErrorDialog)
  const errorDialogElementRef = useUiStore(state => state.errorDialogElementRef)
  const { toasts } = useToast()

  const isBreakPointSmall = useBreakPoint() === BREAKPOINT_KEYS.SM

  const initialToastTransformRef = useRef<string>('')

  // Position toast top-center or top-right based on the screen size
  useEffect(() => {
    const toastViewPort = toastViewPortRef.current
    if (!toastViewPort) {
      return
    }

    const toastViewPortRect = toastViewPort.getBoundingClientRect()
    if (isBreakPointSmall) {
      // Calculate the horizontal translation value for centering the toast
      const xPos = (rootElementWidth - toastViewPortRect.width) / 2
      const transform = `translateX(${xPos}px)`
      initialToastTransformRef.current = transform
    } else {
      // Calculate the horizontal translation value for right aligning the toast
      const xPos =
        rootElementWidth -
        toastViewPortRect.width -
        TOAST_VIEWPORT_STYLES.RIGHT_MARGIN
      const transform = `translateX(${xPos}px)`
      initialToastTransformRef.current = transform
    }

    // Only apply the initial transform if the error dialog is not displayed
    if (!displayErrorDialog) {
      toastViewPort.style.transform = initialToastTransformRef.current
    }
  }, [isBreakPointSmall, displayErrorDialog, rootElementWidth])

  // Position the toast below error dialog when the dialog is open
  useEffect(() => {
    const toastViewport = toastViewPortRef.current
    if (!displayErrorDialog || !errorDialogElementRef || !toastViewport) {
      return
    }

    const animateWhenErrorDialogOpen = () => {
      // Read where the error dialog is positioned to calculate where the toast viewport should be positioned
      const errorDialogRect = errorDialogElementRef.getBoundingClientRect()
      const toastViewPortRect = toastViewport.getBoundingClientRect()

      const rootElementWidth = useUiStore.getState().rootElementWidth

      // Center the toast viewport horizontally which should be the same as the error dialog
      const newXPos = (rootElementWidth - toastViewPortRect.width) / 2

      // Calculate the top difference between the error dialog and the toast viewport
      const newYPos = errorDialogRect.bottom

      const transform = `translate(${newXPos}px, ${newYPos}px)`
      toastViewport.style.transform = transform
    }

    // Initial animation
    animateWhenErrorDialogOpen()

    // Run the animation when the root element is resized
    const unsubscribe = subscribeToRootElementResize({
      onResized: animateWhenErrorDialogOpen,
    })

    return () => {
      toastViewport.style.transform = initialToastTransformRef.current
      unsubscribe()
    }
  }, [displayErrorDialog, errorDialogElementRef])

  return (
    <ToastProvider>
      {toasts.map(function ({
        action,
        description,
        dismissLabel = 'Dismiss',
        id,
        onDismiss,
        titleLabel,
        variant,
        toastType: _toastType,
        ...props
      }) {
        const isDestructive = variant === 'destructive'
        return (
          <Toast key={id} {...props}>
            <div className="flex w-full flex-col gap-y-1.5">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-1.5">
                  {isDestructive && (
                    <Icon tag="warning" variant="destructive" size="xl" />
                  )}
                  {titleLabel && (
                    <ToastTitle asChild>
                      <h2 className={cn(isDestructive && 'text-destructive')}>
                        {titleLabel}
                      </h2>
                    </ToastTitle>
                  )}
                </div>
                <div className="flex items-center gap-x-1.5">
                  {action}
                  <ToastClose
                    dismissLabel={dismissLabel}
                    onClick={onDismiss}
                    className="h-auto w-auto"
                  />
                </div>
              </div>
              {description && (
                <ToastDescription asChild>
                  <p>{description}</p>
                </ToastDescription>
              )}
            </div>
          </Toast>
        )
      })}
      <ToastViewport
        ref={toastViewPortRef}
        className="transition-transform duration-300"
        positioning="fixed"
      />
    </ToastProvider>
  )
}
