import { Link } from '@tanstack/react-router'

// Stores
import { useRoomStore, useRoomStoreActions } from '@/stores/room'
import { FEATURES } from '@/stores/features'

// Routes
import { Route } from '@/routes/_auth.room.$context.$name'

// Components
import { Button } from '@/components/base/button'
import { Icon } from '@/components/common/icons/Icon'
import { Feature } from '@/components/Feature'

// Utils
import { cn } from '@/helpers/utils'

// Types
import type { ButtonProps } from '@/components/base/button'
import type { IconTagName } from '@/components/common/icons/Icon'
import type { PropsWithChildren } from 'react'
import { TEST_IDS } from '@/helpers/constants'
import { useUserStore } from '@/stores/user'

export const RoomFooter = () => {
  const { redirect } = Route.useSearch()

  const handRaised = useRoomStore(state => state.handRaised)
  const memberState = useRoomStore(state => state.memberState)
  const audioMuted = useRoomStore(state => state.audioMuted)
  const videoMuted = useRoomStore(state => state.videoMuted)
  const sharingScreen = useRoomStore(state => state.sharingScreen)
  const isMobile = useUserStore(state => state.isMobile)

  const {
    audioMuteHandler,
    handRaiseHandler,
    videoMuteHandler,
    screenShareHandler,
  } = useRoomStoreActions()

  const memberJoined: boolean = memberState === 'joined'

  return (
    <div
      className="relative flex justify-center gap-x-14"
      data-testid={TEST_IDS.ROOM_FOOTER}
      role="toolbar"
    >
      <div className="flex items-center justify-center gap-x-2 text-neutral-light">
        <RoomControlButton
          isActive={!audioMuted}
          onClick={audioMuteHandler}
          tag="mic"
          disabled={!memberJoined}
        >
          Microphone
        </RoomControlButton>
        <RoomControlButton
          isActive={!videoMuted}
          onClick={videoMuteHandler}
          tag="videocam"
          disabled={!memberJoined}
        >
          Video
        </RoomControlButton>
        <RoomControlButton isActive disabled tag="headphones">
          Audio
        </RoomControlButton>
      </div>
      <Link to={redirect?.trim() || '/recent'}>
        {/* CSS delay adds hover intent */}

        <div className="group m-0 flex h-20 w-20 -translate-y-7 flex-col items-center justify-center gap-y-1 rounded-full bg-background p-0 text-destructive transition-colors hover:bg-destructive hover:text-background hover:delay-button">
          <Icon
            className="fill-destructive group-hover:fill-background group-hover:delay-button"
            size="xl"
            tag="phone-disabled"
          />
          <span>Leave</span>
        </div>
      </Link>
      <div className="flex items-center justify-center gap-x-2 ">
        <Feature name={FEATURES.ROOM_RAISE_HAND}>
          <RoomControlButton
            disabled={!memberJoined}
            isActive={handRaised}
            onClick={handRaiseHandler}
            tag="back-hand"
          >
            Raise Hand
          </RoomControlButton>
        </Feature>
        {!isMobile && (
          <Feature name={FEATURES.ROOM_SHARE_SCREEN}>
            <RoomControlButton
              isActive={sharingScreen}
              onClick={screenShareHandler}
              tag="screen-share"
              disabled={!memberJoined}
            >
              Share Screen
            </RoomControlButton>
          </Feature>
        )}
        <Feature name={FEATURES.ROOM_DO_NOT_DISTURB}>
          <RoomControlButton disabled tag="block">
            DND
          </RoomControlButton>
        </Feature>
      </div>
    </div>
  )
}

type RoomControlButtonProps = Omit<ButtonProps, 'className'> &
  PropsWithChildren<{
    isActive?: boolean
    tag: IconTagName
  }>

const RoomControlButton = ({
  children,
  disabled = false,
  isActive = false,
  tag,
  ...props
}: RoomControlButtonProps) => {
  // aria pressed is undefined if disabled (meaning cannot be pressed)
  const isPressed = disabled ? undefined : isActive
  return (
    <Button
      aria-pressed={isPressed}
      className="m-0 flex h-auto flex-col gap-y-1 rounded-none p-0 px-2 text-xs font-semibold text-neutral-light"
      disabled={disabled}
      {...props}
    >
      <div className={cn('rounded-2xl px-5 py-1', isActive && 'gradient')}>
        <Icon tag={tag} size="xl" />
      </div>
      <span>{children}</span>{' '}
      <span className="sr-only">{isActive ? 'active' : 'inactive'}</span>
    </Button>
  )
}
