export class AuthError extends Error {
  override name = 'AuthError'

  constructor(
    public code: number,
    public override message: string,
  ) {
    super(message)
    Object.setPrototypeOf(this, AuthError.prototype)
  }
}

export class HttpError extends Error {
  override name = 'HttpError'

  constructor(
    public code: number,
    public override message: string,
    public response?: Record<string, unknown>,
  ) {
    super(message)
    Object.setPrototypeOf(this, HttpError.prototype)
  }
}
