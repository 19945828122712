import { ErrorDialog } from '@/components/ErrorDialog'
import { Feature } from '@/components/Feature'
import { useIncomingCall } from '@/hooks/useIncomingCall'
import { LayoutMain } from '@/components/LayoutMain'
import { Sidebar } from '@/components/Sidebar'
import { Toaster } from '@/components/base/toaster'
import { FEATURES } from '@/stores/features'
import { useUiStoreActions } from '@/stores/ui'
import { Outlet } from '@tanstack/react-router'
import { useEffect, useRef } from 'react'
import { ToastDevDialog } from '@/components/ToastDevDialog'

export const RootView = () => {
  const { setViewElementRef } = useUiStoreActions()

  useIncomingCall()

  const viewElementRef = useRef(null)

  useEffect(() => {
    setViewElementRef(viewElementRef)
  }, [setViewElementRef, viewElementRef])

  return (
    <>
      <LayoutMain>
        <Sidebar />
        <div ref={viewElementRef} className="relative w-full">
          <Outlet />
        </div>
      </LayoutMain>
      <Feature name={FEATURES.ERROR_DIALOG}>
        <ErrorDialog />
      </Feature>
      <Feature name={FEATURES.GLOBAL_TOASTER}>
        <Toaster />
      </Feature>

      {/* Can be removed, for demonstrating toasts */}
      <Feature name={FEATURES.DEVELOPER_TOASTER_DIALOG}>
        <ToastDevDialog />
      </Feature>
    </>
  )
}
