import { cn } from '@/helpers/utils'
import type { LinkProps } from '@tanstack/react-router'
import { Link } from '@tanstack/react-router'
import type { PropsWithChildren } from 'react'
import type { Optional } from '@/helpers/types'

// Fix 'any' issue with LinkProps - we expect a string
export type CustomLinkProps = PropsWithChildren<
  Optional<LinkProps, 'params'> & {
    className?: string
  }
>

export const CustomLink = ({
  activeProps,
  className,
  children,
  params = {},
  ...props
}: CustomLinkProps) => {
  return (
    <Link
      activeProps={{
        className: cn(
          'group active',
          activeProps && 'className' in activeProps
            ? activeProps.className
            : undefined,
        ),
      }}
      className={cn(
        'flex flex-col items-center justify-center gap-2 text-neutral-light',
        className,
      )}
      params={params}
      {...props}
    >
      {children}
    </Link>
  )
}
