import { ButtonMicrophone } from '@/components/ButtonMicrophone'
import { LinkLeave } from '@/components/LinkLeave'
import { TEST_IDS } from '@/helpers/constants'
import type { RedirectPath } from '@/helpers/types'
import { useRoomStore } from '@/stores/room'
import { ButtonAddVideo } from './ButtonAddVideo'
import { useMicrophoneButtonState } from '@/hooks/useMicrophoneButtonState'

interface AudioCallControlsProps {
  redirect?: RedirectPath | undefined
}

export const AudioCallControls = ({ redirect }: AudioCallControlsProps) => {
  const memberState = useRoomStore(state => state.memberState)
  const { isMicrophoneButtonVisible, isMicrophoneEnabled } =
    useMicrophoneButtonState()

  const memberJoined = memberState === 'joined'

  return (
    <menu
      aria-label="Room toolbar"
      className="flex flex-col"
      data-testid={TEST_IDS.ROOM_CONTROLS}
      role="toolbar"
    >
      <li>
        <ul
          aria-label="Audio and video controls"
          className="flex gap-2 p-2.5"
          role="group"
        >
          <li className="flex min-w-[170px] items-center justify-center pb-4 pt-3">
            <ButtonMicrophone
              disabled={!memberJoined || !isMicrophoneEnabled}
              isVisible={isMicrophoneButtonVisible}
            />
          </li>
          <li className="flex min-w-[170px] items-center justify-center pb-4 pt-3">
            <ButtonAddVideo disabled={!memberJoined} />
          </li>
        </ul>
      </li>
      <li className="flex items-center justify-center py-20">
        <LinkLeave to={redirect} variant="rounded" />
      </li>
    </menu>
  )
}
