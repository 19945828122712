import { LOADING_STATE } from '@/helpers/constants'
import type { LoadingState } from '@/helpers/types'
import type { Resource, SortOrder } from '@/helpers/types'
import { useResourcesStoreActions } from '@/stores/resources'
import { useLoaderData } from '@tanstack/react-router'
import { useEffect, useMemo, useRef, useState } from 'react'

interface UseRoomSortedParams {
  fetchImmediately?: boolean
  sortOrder: SortOrder
}

export const useRoomsSorted = ({
  fetchImmediately = false,
  sortOrder,
}: UseRoomSortedParams) => {
  const loaderData = useLoaderData({ from: '/_auth/rooms' })
  const { fetchRooms, getResourceById } = useResourcesStoreActions()

  const [roomIdsSorted, setRoomIdsSorted] =
    useState<Resource['id'][]>(loaderData)
  const [status, setStatus] = useState<LoadingState>(LOADING_STATE.IDLE)

  // This ref is used to determine if the resources should be fetched immediately
  const shouldFetchRef = useRef(fetchImmediately)

  const filteredVideoRooms = useMemo(() => {
    return (
      roomIdsSorted
        ?.map(roomId => getResourceById(roomId))
        // FIXME: remove casting in TS ^5.5
        .filter(room => room?.channels.video) as Resource[]
    )
  }, [roomIdsSorted, getResourceById])

  useEffect(() => {
    if (shouldFetchRef.current) {
      setStatus(LOADING_STATE.LOADING)
      fetchRooms(sortOrder)
        .then(roomsIds => {
          setStatus(LOADING_STATE.IDLE)
          setRoomIdsSorted(roomsIds)
        })
        .catch(error => {
          console.error('Error fetching resources', error)
          setStatus(LOADING_STATE.ERROR)
        })
    }

    return () => {
      // set true to ensure dependency array will trigger a fetch
      shouldFetchRef.current = true
    }
  }, [fetchRooms, sortOrder])

  return {
    error: status === LOADING_STATE.ERROR,
    loading: status === LOADING_STATE.LOADING,
    roomsSorted: filteredVideoRooms,
  }
}
