import { useCapabilitiesStore } from '@/stores/capabilities'
import { FEATURES, useFeaturesStoreActions } from '@/stores/features'
import { useRoomStore } from '@/stores/room'

/**
 * returns whether the corresponding button should be visible and enabled
 * - button `visible` if one of the `capabilities` is enabled and the FF is enabled
 * - button `enabled` if the current state matches the `capability` state
 * i.e. (currently muted and can unmute) or (currently unmute and can mute)
 */
export const useRoomLockButtonState = () => {
  const roomLocked = useRoomStore(state => state.roomLocked)
  const isAudioOnly = useRoomStore(state => state.isAudioOnly)

  const hasRoomLockCapabilityOn = useCapabilitiesStore(
    state => state.hasRoomLockCapabilityOn,
  )
  const hasRoomLockCapabilityOff = useCapabilitiesStore(
    state => state.hasRoomLockCapabilityOff,
  )

  const { getFeatureFlagIsDisabled } = useFeaturesStoreActions()

  // button visible if one of the capabilities is enabled and FF is enabled
  const isRoomLockButtonVisible =
    !isAudioOnly &&
    !getFeatureFlagIsDisabled(FEATURES.ROOM_LOCK) &&
    (hasRoomLockCapabilityOff || hasRoomLockCapabilityOn)

  const canLockRoom = !roomLocked && hasRoomLockCapabilityOn
  const canUnlockRoom = roomLocked && hasRoomLockCapabilityOff

  // check if button can perform the necessary next action
  const isRoomLockButtonEnabled = canLockRoom || canUnlockRoom

  return { isRoomLockButtonEnabled, isRoomLockButtonVisible }
}
